import React, { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsPlusSquareDotted } from "react-icons/bs";
import ReactImageUploading from "react-images-uploading";
import "./formUploadImgCard.css";
let icon = "https://img.icons8.com/ios/2x/plus-key.png";

const UploadImgCardV2 = ({
  initialImagesUrl,
  onChange,
  onDeleteImage,
  onAddNewImage,
  formikError,
  validationErr,
}) => {
  const _idGenerator = () => {
    return `${Math.floor(Math.random() * 21232122233331)}`;
  };
  const [images, setImages] = useState(initialImagesUrl);

  useEffect(() => {
    if (initialImagesUrl) {
      setImages(initialImagesUrl);
      console.log("Val Err", validationErr);
    }
  }, [initialImagesUrl]);

  // initialImagesUrl = values.images;

  const handleOnChange = (imglist, addUpdateIndex) => {
    // data for submit
    const createImgUrl = (img) => {
      return URL.createObjectURL(img);
    };
    console.log(imglist, "imglist......");
    const formattedArray = imglist.map((x) => {
      return x.file
        ? {
            _id: _idGenerator(),
            avatar: { key: "key", url: createImgUrl(x.file), raw: x.file },
            image: { key: "key", url: createImgUrl(x.file), raw: x.file },
          }
        : x;
    });
    const newImgs = formattedArray.filter((x) => x.avatar.raw);

    if (onAddNewImage) {
      onAddNewImage(newImgs);
    }
    if (onChange) {
      onChange(formattedArray);
    }
    setImages(formattedArray);
  };

  const imgTransform = (url) => {
    return url && url.replace(".jp2", ".webp");
  };

  return (
    <div className="upload-image-card ">
      <div className="flex items-center gap-5 ">
        <h1 className="upload-img-head">Upload Images</h1>

        <div className="self-start">{formikError && formikError}</div>

        <div className="self-start text-sm text-red-600">
          {validationErr && validationErr}
        </div>
      </div>

      <div className="uplaod-image-scroll-body ">
        <div>
          <ReactImageUploading
            multiple
            value={images}
            onChange={handleOnChange}
            // maxNumber={16}
            dataURLKey="data_url"

            // acceptType={["jpg", "png"]}
          >
            {({
              onImageUpload,
              // onImageRemoveAll,
              // onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
              imageList,
            }) => (
              <div className="upload__image-wrapper ">
                <div className="upload-imgs-wrapper !gap-0 ">
                  {images &&
                    images.map((image, index) => {
                      return (
                        <>
                          <div key={index} className="p-2 image-item">
                            <div className="relative ">
                              <button
                                type="button"
                                onClick={() => {
                                  onImageRemove(index);
                                  if (onDeleteImage) {
                                    onDeleteImage(image);
                                  }
                                }}
                                className="absolute z-10 rounded-full bg-white -top-[12px] -right-[12px]"
                              >
                                <AiFillCloseCircle className="text-2xl text-red-500 transition-all duration-300 hover:text-red-400" />{" "}
                              </button>
                              {image?.raw ? (
                                <div className="aspect-w-1 aspect-h-1">
                                  <img
                                    className="object-cover object-center w-full h-full rounded-md"
                                    src={image?.avatar?.url}
                                    alt="imag"
                                  />
                                </div>
                              ) : (
                                <div className="aspect-w-1 aspect-h-1">
                                  <img
                                    className="object-cover object-center w-full h-full rounded-md"
                                    src={imgTransform(image?.avatar?.url)}
                                    alt="imag"
                                  />
                                </div>
                              )}
                              {/* <img
                                className="upload-image"
                                src={image.avatar.url}
                                alt="imag"
                              /> */}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  {/* {imageList?.length < 16 && ( */}
                  <div
                    style={isDragging ? { border: "1px solid green" } : null}
                    onClick={onImageUpload}
                    {...dragProps}
                    className="relative p-2 "
                  >
                    <div className="cursor-pointer aspect-w-1 aspect-h-1 bg-gray-50 ">
                      <img
                        style={{ verticalAlign: "unset" }}
                        src={`/gray.png`}
                        alt="plus-icon"
                        className="object-cover object-center w-full h-full rounded-md "
                      />
                    </div>

                    <BsPlusSquareDotted className="absolute inset-0 m-auto text-3xl text-white" />
                  </div>
                  {/* )} */}
                </div>
              </div>
            )}
          </ReactImageUploading>
        </div>
      </div>
    </div>
  );
};

export default UploadImgCardV2;
