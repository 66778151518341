export const Tables = ({ children, className }) => {
  return (
    <div className={`overflow-x-auto ${className}`}>
      <table className="min-w-full divide-y-2 divide-gray-300">
        {children}
      </table>
    </div>
  );
};
export const TR = ({ children, key, className }) => {
  return (
    <tr className={className} key={key}>
      {children}
    </tr>
  );
};
export const TH = ({ children, key, className, ...props }) => {
  return (
    <th
      key={key}
      scope="col"
      className={`py-3 pl-4 pr-3 text-left text-[16px]  capitalize tracking-wide text-gray-500 font-bold ${className}`}
      {...props}
    >
      {children}
    </th>
  );
};
export const TD = ({ children, key, className }) => {
  return (
    <td
      key={key}
      className={`whitespace-nowrap  text-left px-3 py-2 text-sm font-semibold text-gray-500 ${className}`}
    >
      {children}
    </td>
  );
};

export const TBody = ({ children, className }) => {
  return (
    <tbody className={`divide-y divide-gray-200 bg-white ${className}`}>
      {children}
    </tbody>
  );
};

export const THead = ({ children, className }) => {
  return <thead className={`  ${className}`}>{children}</thead>;
};
