import produce from "immer";
import { TRANSACTIONS } from "../types";

const state = {
  transactions: [],
  totalReceivable: 0,
  loading: false,
};

const TransactionsReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case TRANSACTIONS.SET_ALL:
      return produce(mState, (draftState) => {
        console.log(`Setting transactions `, action.payload);
        draftState.transactions = action.payload.transactions;
        draftState.totalReceivable = action.payload.totalReceivable;
      });
    case TRANSACTIONS.SET_KEY_VALUE:
      return produce(mState, (draftState) => {
        draftState[action.payload.key] = action.payload.value;
      });
    case TRANSACTIONS.SET_STATUS:
      return produce(mState, (draftState) => {
        const { transactionId, status } = action.payload;
        const transactionIndex = draftState.transactions.findIndex(
          (t) => t.payload._id === transactionId
        );
        if (transactionIndex >= 0) {
          draftState.transactions[transactionIndex].offer.transactionStatus =
            status;
        } else {
          console.log(`invalid transaction, mark status:${transactionId}`);
        }
      });
    default:
      return { ...mState };
  }
};
export default TransactionsReducer;
