import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

const BreadCrumb = ({ pages, className }) => {
  return (
    <nav className={`flex justify-start ${className}`} aria-label="Breadcrumb">
      <ol
        role="list"
        className="flex !pl-0 space-x-2 h-10  items-center justify-start rounded-md bg-transparent "
      >
        {pages.map((page) => (
          <li key={page.name} className="">
            <div className="flex items-center gap-2">
              <MdKeyboardArrowRight className="text-lg text-[#566589]" />
              <Link
                to={page.href}
                className=" text-sm font-semibold text-[#566589] no-underline hover:text-gray-800"
                aria-current={page.current ? "page" : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default BreadCrumb;
