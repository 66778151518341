import React, { useEffect, useState } from "react";
//      Importing css file
import "./toggleButton.css";
export const ToggleButton = ({ onChange, checked, label, style }) => {
  // const [toggle, setToggle] = useState(true);

  // useEffect(() => {
  //   if(typeof(initialchecked) === "boolean"){
  //     setToggle(initialchecked);
  //   }
  // }, [])

  // useEffect(() => {
  //   if(onChange) onChange(toggle);
  // }, [toggle])

  const toggle = checked ? true : false;
  const handleToggle = () => {
    if (onChange) onChange();
  };

  return (
    <div>
      <span className="toggle-button-title whitespace-nowrap">{label}</span>
      <label className="switch" style={style}>
        <input onClick={handleToggle} type="checkbox" />
        <span
          className={
            toggle === false ? "slider round" : "slider sliderChecked round"
          }
        ></span>
      </label>
    </div>
  );
};
