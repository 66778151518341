import React from "react";
import { useSearchParams } from "react-router-dom";
//      importing components
import { VehicleTable } from "../../Components/CompositeComponents/Table/AllBuisnesses/allBuisnessesTable";
import { BuisnessButtonGroup } from "../../Components/ModuleComponents/BuisnessButtonGroup/buisnessButtonGroup";
//      importing css files
import Skeletons from "../../Components/ModuleComponents/Skeleton/businessList";
import { useBusinessWithApprovalStatus } from "../../Controllers/businessesController";
import "./allBuisnessesScreen.css";
export const AllBusinessesScreen = () => {
  const [searchParams] = useSearchParams();
  const { businesses, loading, businessCount } = useBusinessWithApprovalStatus(
    searchParams.get("filter")
  );

  // console.log("Business ", businesses)
  return (
    <div>
      <div className="all-vehicle-header">
        <h1>All Dealers</h1>
        <div style={{ margin: "auto 0px" }}>
          <BuisnessButtonGroup
            allValue={businessCount.allCount}
            pendingValue={businessCount.pendingCount}
            acceptValue={businessCount.approvedCount}
            blockValue={businessCount.blockedCount}
          />
        </div>
      </div>
      <div>
        {loading ? (
          <>
            <Skeletons />
            <Skeletons />
            <Skeletons />
            <Skeletons />
          </>
        ) : (
          <VehicleTable businesses={businesses} />
        )}
      </div>
    </div>
  );
};
