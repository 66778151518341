import React from "react";
//     importing componants
import { useLocation, Outlet, Navigate } from "react-router-dom";
import { AuthNav } from "../Components/AbstractsComponents/AuthNavBar/authNavBar";
import AuthController from "../Controllers/authController";

export const AuthRouting = (props) => {
  const location = useLocation();
  const user = AuthController.getCurrentUser();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  // console.log(splitLocation);
  if (user) {
    return <Navigate to={`/allBuisnesses`} />;
  }
  return (
    <div>
      <div
        style={
          splitLocation[1] === "/" ? { display: "none" } : { display: "block" }
        }
      >
        <AuthNav />
      </div>
      <Outlet />
    </div>
  );
};
