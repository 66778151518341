import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import BusinessesController from "../../../Controllers/businessesController";
//    importing css files
import "./buisnessButtonGroup.css";

export const BuisnessButtonGroup = (props) => {
  useLocation();
  const [searchParams] = useSearchParams();
//   const splitLocation = window.location.href.split("/");
  // console.log(searchParams.get('filter'));

  return (
    <div className="buisness-buttob-group-div">
      <Link to={`/allBuisnesses`}>
        <button
          className={
            !searchParams.get('filter')
              ? "buisenss-button buisness-all-button-active"
              : "buisenss-button"
          }
        >
          All ({props.allValue})
        </button>
      </Link>
      <Link to={`/allBuisnesses?filter=${BusinessesController.APPROVAL_STATUS.PENDING}`}>
        <button
          className={
            searchParams.get('filter') === BusinessesController.APPROVAL_STATUS.PENDING
              ? "buisenss-button buisness-all-button-active"
              : "buisenss-button"
          }
        >
          Pending ({props.pendingValue})
        </button>
      </Link>
      <Link to={`/allBuisnesses?filter=${BusinessesController.APPROVAL_STATUS.APPROVED}`}>
        <button
          className={
            searchParams.get('filter') === BusinessesController.APPROVAL_STATUS.APPROVED
              ? "buisenss-button buisness-all-button-active"
              : "buisenss-button"
          }
        >
          Approved ({props.acceptValue})
        </button>
      </Link>
      <Link to={`/allBuisnesses?filter=${BusinessesController.APPROVAL_STATUS.BLOCKED}`}>
        <button
          className={
            searchParams.get('filter') === BusinessesController.APPROVAL_STATUS.BLOCKED
              ? "buisenss-button buisness-block-button-active"
              : "buisenss-button"
          }
        >
          Block ({props.blockValue})
        </button>
      </Link>
    </div>
  );
};
