import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import AuctionController from "../../Controllers/auctionController";
import { CgDanger } from "react-icons/cg";
import { TailSpin } from "react-loader-spinner";
import BreadCrumb from "../../Components/AbstractsComponents/BreadCrumb";
import { useFormik } from "formik";
import { FiEdit } from "react-icons/fi";
import { LocationTextField } from "../../Components/AbstractsComponents/InputFields/LocationInputField/index";
import { TiInfoLargeOutline } from "react-icons/ti";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoCopySharp } from "react-icons/io5";
import { TiTick } from "react-icons/ti";
import { MdWatchLater } from "react-icons/md";
import {
  THead,
  TBody,
  TR,
  TD,
  TH,
  Tables,
} from "../../Components/AbstractsComponents/Tables/index";
import { TextFields } from "../../Components/AbstractsComponents/InputFields";
import Modal from "../../Components/AbstractsComponents/Modal2/Modal";
import ToastController from "../../Controllers/toastController";
import { useNavigate } from "react-router-dom";
import { useCallbackPrompt } from "../../Hooks/useCallbackPrompt";
import DialogueBox from "../../Components/AbstractsComponents/DialogueBox";
import classNames from "classnames";
const tabsData = [
  { title: "All Vehicles", current: true, value: `auctionCars` },
  { title: "Offered Vehicles", current: false, value: `offeredCars` },
];

const SingleAuction = () => {
  const [searchParam] = useSearchParams();
  const id = searchParam.get("id");
  const [auction, setAuction] = useState(null);
  const [touch, setTouch] = useState(false);
  const editFlag = useRef(false);
  const [coverImage, setCoverImage] = useState(null);
  const [Tabs, setTabs] = useState(tabsData);

  const currentTab = Tabs.find((tab) => tab.current);

  const formFlag = useRef(null);

  const [showDialogue, setShowDialogue] = useState(false);

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialogue);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  // const [auctionData, setAuctionData] = useState({
  //   title: "",
  //   numberOfAuctionCars: "",
  //   location: "",
  // });

  useEffect(() => {
    if (touch || coverImage) {
      setShowDialogue(true);
    }
  }, [touch, coverImage]);

  const dateTimeFormatter = (date) => {
    let newDate = new Date(date);
    console.log("@date....", newDate);

    const month = ("0" + (newDate.getMonth() + 1)).slice(-2);
    const day = ("0" + newDate.getDate()).slice(-2);
    const year = newDate.getFullYear();
    const hours = newDate.getUTCHours();
    const minutes = newDate.getUTCMinutes();

    const shortDate = `${year}-${month}-${day}`;
    const shortTime = `${hours > 9 ? hours : `0${hours}`}:${
      minutes > 9 ? minutes : `0${minutes}`
    }`;

    console.log("short time....", shortTime, shortDate);

    return { shortDate, shortTime };
  };

  const { values, setValues, handleSubmit, getFieldProps } = useFormik({
    initialValues: {
      title: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      location: {
        description: "",
        coords: null,
      },
      area: "",
    },

    onSubmit: (d) => {
      console.log("Updated Data......", d);
      handleUpdateAuction(d);
    },
  });

  const checkTernary = (x, v) => {
    return x !== "" ? x : v ? v : undefined;
  };

  useEffect(() => {
    if (auction) {
      setValues({
        ...values,
        title: checkTernary(auction?.title, ""),
        startDate: checkTernary(
          dateTimeFormatter(auction?.auctionStartDateTime).shortDate,
          ""
        ),
        startTime: checkTernary(
          dateTimeFormatter(auction?.auctionStartDateTime).shortTime,
          ""
        ),
        endDate: checkTernary(
          dateTimeFormatter(auction?.auctionEndDateTime).shortDate,
          ""
        ),
        endTime: checkTernary(
          dateTimeFormatter(auction?.auctionEndDateTime).shortTime,
          ""
        ),
        location: {
          description: checkTernary(auction?.location?.description, ""),

          coords: {
            longitude: auction?.location?.lng,
            latitude: auction?.location?.lat,
          },
        },
        area: checkTernary(auction?.location?.area, ""),
      });
    }
  }, [auction]);

  console.log("Values.....", values, formFlag);

  useEffect(() => {
    if (id) {
      AuctionController.getSingleAuction(id)
        .then((res) => {
          if (res) {
            console.log("Single Auction", res);

            setTimeout(() => {
              editFlag.current = true;
            }, 300);

            setAuction(res);

            setError(null);
          }
        })
        .catch((err) => {
          console.log("Err ", err);
          setError("Error while fetching auction details");
          setAuction({
            title: "",
            pictures: [],
            vehicles: [],
          });
        });
    }
  }, []);

  console.log("Filter", auction);
  const tableHeading = [
    "Picture",
    "Title",
    "Price",
    "Buy Now Price",
    "Initial Bid Amount",
    "Make",
    "Year",
    "Transmission",
    "Drive Train",
    "KM Driven",
    "Engine",
  ];

  const pages = [
    { name: "Auctions", href: "/auctions", current: false },
    { name: "Auction Details", href: `/auction-info?id=${id}`, current: true },
  ];

  const handleLocationField = (address) => {
    console.log(address, "updated address");
    setValues({
      ...values,
      location: {
        description: address.address,
        coords: {
          longitude: address.location.lng,
          latitude: address.location.lat,
        },
      },
      area: address.area,
    });
  };

  const [visible, setVisible] = useState(false);
  const [vId, setVId] = useState({
    id: "",
    aVId: "",
  });

  const [msg, setMsg] = useState({
    err: "",
    success: "",
  });
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);

  const toast = new ToastController();

  const handleDeleteAuctionVehicle = () => {
    let data = {
      auction: id,
      vehicle: vId.id,
      allowOverride: false,
    };

    console.log("Delete Vehicle Data....", data);
    setProcessing(true);

    if (msg.err) {
      data = {
        ...data,
        allowOverride: true,
      };
    }

    AuctionController.deleteAuctionVehicle(data)
      .then((res) => {
        console.log("res....", res);
        if (res.code === 403) {
          setMsg({
            err: res.message,
            success: "",
          });
        } else {
          setMsg({
            err: "",
            success: res.message,
          });
          let filter = auction?.auctionCars.filter((v) => v._id !== vId.aVId);
          setAuction({
            ...auction,
            auctionCars: filter,
          });
          toast.endProcessingWithSuccess("Auction Vehicle Removed....");
        }
        setProcessing(false);
      })
      .catch((err) => {
        console.log("er...", err);
        setMsg(err.error.message);
        setProcessing(false);
      });
  };

  useEffect(() => {
    if (editFlag.current) {
      setTouch(true);
      editFlag.current = false;
    }
  }, [values, coverImage]);

  const handleUpdateAuction = (d) => {
    if (!touch) {
      return;
    }

    // console.log("@auction new data....", newData);

    console.log("@auction update Data....", d);
    setLoading(true);
    toast.showProcessing("updating auction....");
    if (coverImage !== null) {
      AuctionController.uploadImage(coverImage)
        .then((res) => {
          console.log("@auction img res....", res);
          const newData = {
            title: d?.title,
            location: {
              description: d?.location?.description,
              coords: {
                longitude: d?.location?.coords?.longitude,
                latitude: d?.location?.coords?.latitude,
              },
              area: d?.area,
            },
            picturesRemoved: [auction?.pictures[0]],
            picturesAdded: [res.data.image],
            auctionStartDateTime: d?.startDate + "T" + d.startTime + ":00.000Z",
            auctionEndDateTime: d?.endDate + "T" + d.endTime + ":00.000Z",
            // auctionEndDateTime: new Date(d?.startDate),
            // auctionStartDateTime: new Date(d?.endDate),
          };

          console.log("@auction new data....", newData);

          AuctionController.updateAuction(newData, id)
            .then((res) => {
              console.log("@auction update res...", res);
              setLoading(false);
              toast.endProcessingWithSuccess("Auction Updated....");
              navigate("/auctions");
            })
            .catch((err) => {
              console.log("@err update...", err);
              setLoading(false);
              toast.endProcessingWithError(err);
            });
        })
        .catch((err) => {
          console.log("err...", err);
        });
    } else {
      const data = {
        title: d?.title,
        location: {
          description: d?.location?.description,
          coords: {
            longitude: d?.location?.coords?.longitude,
            latitude: d?.location?.coords?.latitude,
          },
          area: d?.area,
        },
        auctionStartDateTime: d?.startDate + "T" + d.startTime + ":00.000Z",
        auctionEndDateTime: d?.endDate + "T" + d.endTime + ":00.000Z",
        // auctionStartDateTime: new Date(d?.startDate),
        // auctionEndDateTime: new Date(d?.endDate),
      };
      AuctionController.updateAuction(data, id)
        .then((res) => {
          console.log("update res...", res);
          setLoading(false);
          toast.endProcessingWithSuccess("Auction Updated....");
          navigate("/auctions");
        })
        .catch((err) => {
          console.log("@err update...", err);
          setLoading(false);
          toast.endProcessingWithError(err);
        });
    }
  };

  const [copy, setCopy] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  }, []);

  // console.log("Auction Data....", auctionData);

  const renderTabs = () => {
    return (
      <nav
        className="flex divide-x divide-gray-200 rounded-lg shadow isolate"
        aria-label="Tabs"
      >
        {Tabs.map((tab, tabIdx) => (
          <button
            type="button"
            onClick={() => {
              // if (isTouched) {
              //   setSave(true);
              //   return;
              // }
              setTabs(
                Tabs.map((t, i) =>
                  i === tabIdx
                    ? { ...t, current: true }
                    : { ...t, current: false }
                )
              );
            }}
            key={tab.title}
            className={classNames(
              tab.current
                ? "text-gray-900 font-semibold"
                : "text-gray-500 hover:text-gray-700 font-semibold",
              tabIdx === 0 ? "rounded-l-lg" : "",
              tabIdx === Tabs.length - 1 ? "rounded-r-lg" : "",
              "group relative min-w-0 capitalize flex-1 overflow-hidden bg-white py-3 px-4 text-sm font-semibold text-center hover:bg-gray-50 focus:z-10"
            )}
            aria-current={tab.current ? "page" : undefined}
          >
            <span>{tab.title}</span>
            <span
              aria-hidden="true"
              className={classNames(
                tab.current ? "bg-indigo-500" : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5"
              )}
            />
          </button>
        ))}
      </nav>
    );
  };

  return (
    <>
      <Modal
        visible={visible}
        setVisible={setVisible}
        wrapperClass={`max-w-[300px] flex item-center justify-center px-4 py-6 bg-white`}
      >
        <div className="flex flex-col gap-2">
          <div>
            {msg.err || msg.success ? (
              <div className="flex flex-col gap-2">
                {msg.err && (
                  <div>
                    <p className="text-red-600 text-xs font-[600] items-center text-center flex flex-col gap-1">
                      <TiInfoLargeOutline className="text-3xl" />
                      {msg.err}
                    </p>
                    <p className="text-center text-gray-600 text-sm font-[600]">
                      Do you still want to remove vehicle?
                    </p>
                  </div>
                )}
                {msg.success && (
                  <p className="text-green-600 text-xs font-[600] items-center text-center flex flex-col gap-1">
                    <TiInfoLargeOutline className="text-3xl" />
                    {msg.success}
                  </p>
                )}
              </div>
            ) : (
              <p className="text-center text-gray-600 text-sm font-[600]">
                Do you want to remove vehicle?
              </p>
            )}
          </div>
          <div className="flex items-center justify-between gap-3">
            <button
              type="button"
              onClick={() => {
                setVisible(false);
                setMsg({
                  err: "",
                  success: "",
                });
              }}
              className="rounded-md flex-1 mx-auto self-center p-2 bg-gray-200  text-black font-[600]  text-base"
            >
              {msg.err !== "" || msg.success !== "" ? "Ok" : "Cancel"}
            </button>
            <button
              type="button"
              onClick={handleDeleteAuctionVehicle}
              className={`rounded-md flex-1 mx-auto p-2 ${
                msg.success && "hidden"
              }   inline-flex items-center self-center justify-center bg-red-500 text-white font-[600] text-base`}
            >
              {processing ? (
                <TailSpin width={20} height={20} color={`white`} />
              ) : msg.err ? (
                "Yes Remove It"
              ) : (
                "Remove"
              )}
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex items-center justify-between ">
        <BreadCrumb pages={pages} className={`mt-3`} />
        <div className="flex gap-3  text-sm font-semibold text-[#566589] ">
          Copy Auction Public Url
          <CopyToClipboard
            text={`https://www.inforide.app/Auction/singleAuction/${id}`}
          >
            <button
              type="button"
              onClick={() => {
                setCopy(true);
              }}
              className="text-xl font-semibold text-[#566589] "
            >
              {copy ? <TiTick className="text-green-600" /> : <IoCopySharp />}
            </button>
          </CopyToClipboard>
        </div>
      </div>
      <div>
        {error ? (
          <div className="min-h-[70vh] w-full flex items-center justify-center">
            <h3 className="flex items-center gap-3 text-lg text-red-500 capitalize py-7">
              <CgDanger className="text-[30px]" /> {error}
            </h3>
          </div>
        ) : (
          <>
            {!auction ? (
              <div className="min-h-[70vh] w-full flex items-center justify-center">
                <TailSpin width={70} height={70} color={"blue"} />
              </div>
            ) : (
              <form
                onSubmit={handleSubmit}
                ref={formFlag}
                className="flex flex-col w-full h-auto gap-6 py-8"
              >
                <div className="flex items-center justify-between">
                  <h3 className="text-left font-semibold text-[#566589]">
                    Auction Details
                  </h3>
                  <button
                    onClick={() => setShowDialogue(false)}
                    disabled={loading || !touch}
                    type="submit"
                    className="self-end p-2 text-white rounded-md disabled:cursor-not-allowed bg-primary"
                  >
                    Update Auction
                  </button>
                </div>
                <div className="flex flex-col gap-5">
                  <div className="relative">
                    <div className="aspect-w-4 aspect-h-1">
                      {coverImage !== null ? (
                        <img
                          src={coverImage?.url}
                          className="object-cover object-center w-full h-full rounded-xl"
                          alt="cover-pic"
                        />
                      ) : (
                        <img
                          src={auction?.pictures[0]?.image?.url}
                          className="object-cover object-center w-full h-full rounded-xl"
                          alt="cover-pic"
                        />
                      )}
                    </div>
                    <label
                      htmlFor="file"
                      className="absolute top-5 right-5 z-[1000]"
                    >
                      <FiEdit className="text-2xl text-white cursor-pointer" />
                      <input
                        id="file"
                        type={`file`}
                        onChange={(e) => {
                          setCoverImage({
                            url: URL.createObjectURL(e.target.files[0]),
                            image: e.target.files[0],
                          });
                        }}
                        className="w-full text-center sr-only file:hidden placeholder:hidden font-lato placeholder:text-gray-400"
                      />
                    </label>
                    <div className="absolute inset-0 flex items-center justify-center w-full rounded-xl bg-gray-300/20 ">
                      <h2 className="text-4xl font-bold text-white uppercase">
                        {auction?.title}
                      </h2>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 max-w-[500px] m-auto w-full self-center -mt-10">
                    <TextFields
                      type={`text`}
                      placeholder={`Enter Title`}
                      name={`title`}
                      label={`Title`}
                      className={`px-2 py-2 border border-gray-200 !bg-gray-100 `}
                      {...getFieldProps(`title`)}
                    />

                    <div className="flex w-full gap-4">
                      <div className="flex flex-col flex-1 gap-1 ">
                        <p className="text-dark1 font-bold text-sm mb-[2px] text-left">
                          Start Date
                        </p>
                        <input
                          type={`date`}
                          name={`startDate`}
                          {...getFieldProps(`startDate`)}
                          value={values?.startDate}
                          className="w-full h-12 px-2 bg-white border-2 border-gray-300 rounded-lg outline-none "
                        />
                      </div>
                      <div className="flex flex-col flex-1 gap-1 ">
                        <p className="text-dark1 font-bold text-sm mb-[2px] text-left">
                          Start Time
                        </p>
                        <input
                          type={`time`}
                          name={`startTime`}
                          value={values?.startTime}
                          {...getFieldProps(`startTime`)}
                          className="w-full h-12 px-2 bg-white border-2 border-gray-300 rounded-lg outline-none "
                          placeholder="09:00 PM"
                        />
                      </div>
                    </div>
                    <div className="flex w-full gap-4">
                      <div className="flex flex-col flex-1 gap-1 ">
                        <p className="text-dark1 font-bold text-sm mb-[2px] text-left">
                          End Date
                        </p>
                        <input
                          type={`date`}
                          name={`endDate`}
                          value={values?.endDate}
                          {...getFieldProps(`endDate`)}
                          className="w-full h-12 px-2 bg-white border-2 border-gray-300 rounded-lg outline-none "
                        />
                      </div>
                      <div className="flex flex-col flex-1 gap-1 ">
                        <p className="text-dark1 font-bold text-sm mb-[2px] text-left">
                          End Time
                        </p>
                        <input
                          type={`time`}
                          name={`endTime`}
                          value={values?.endTime}
                          {...getFieldProps(`endTime`)}
                          className="w-full h-12 px-2 bg-white border-2 border-gray-300 rounded-lg outline-none"
                          placeholder="09:00 PM"
                        />
                      </div>
                    </div>

                    <LocationTextField
                      type="text"
                      name="location"
                      id="location"
                      label="Location / Address"
                      placeholder="2564 Joes Road, New York"
                      value={values.location.description}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          location: {
                            ...values.location,
                            description: e.target.value,
                          },
                        })
                      }
                      // {...getFieldProps(``)}
                      // {...getFieldProps("location")}
                      // icon={LocationIcon}
                      onLoactionChange={handleLocationField}
                    />
                    <div className="flex flex-col flex-1 w-full h-full gap-1 -mt-3">
                      <p className="text-dark1 font-bold text-sm mb-[2px] text-left">
                        Area *
                      </p>
                      <input
                        type={`text`}
                        value={`${values.area}`}
                        name={`area`}
                        {...getFieldProps(`area`)}
                        className="w-full h-[48px] outline-none border-2 bg-white px-2 border-gray-300 rounded-lg"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="flex items-center justify-between">
                    <h4 className="text-xl text-left font-bold text-[#566589] ">
                      Vehicles List
                    </h4>
                    <Link
                      to={`/vehicles?id=${id}`}
                      className="px-4 py-2 font-semibold text-white no-underline rounded-lg bg-primary"
                    >
                      Add Vehicle
                    </Link>
                  </div>

                  {renderTabs()}
                  <div className="bg-white min-h-[70vh] rounded-3xl">
                    <Tables>
                      <THead>
                        <TR>
                          {tableHeading.map((ls, index) => (
                            <TH
                              className={`${
                                ls === "Actions"
                                  ? "sticky right-0 rounded-tr-2xl bg-gray-400 z-10 text-white"
                                  : ""
                              }  whitespace-nowrap`}
                              key={index}
                            >
                              {ls}
                            </TH>
                          ))}
                        </TR>
                      </THead>
                      {auction?.[currentTab.value] !== undefined &&
                      auction?.[currentTab.value].length > 0 ? (
                        <TBody className={`relative`}>
                          {auction?.[currentTab.value]?.map((auctionCar) => {
                            const {
                              pictures,
                              make,
                              year,
                              _id,
                              transmission,
                              driveTrain,
                              KMDriven,
                              engine,
                              priceRange,
                              title,
                              businessId,
                            } = auctionCar.vehicle;

                            const { buyNowPrice, initialBidAmount } =
                              auctionCar;
                            const auctionVehicleId = auctionCar._id;

                            return (
                              <TR key={_id}>
                                {pictures && (
                                  <TD>
                                    <div className="w-32 h-32">
                                      <img
                                        src={pictures[0]?.image?.url}
                                        className="object-cover w-full h-full rounded-lg"
                                        alt="vehicle"
                                      />
                                    </div>
                                  </TD>
                                )}
                                <TD>{title}</TD>
                                <TD>${priceRange?.min}</TD>
                                <TD>${buyNowPrice ? buyNowPrice : "0"}</TD>
                                <TD>${initialBidAmount}</TD>
                                <TD>{make}</TD>
                                <TD>{year}</TD>
                                <TD>{transmission}</TD>
                                <TD>{driveTrain}</TD>
                                <TD>{KMDriven}</TD>
                                <TD>{engine}</TD>

                                <TD
                                  className={`sticky inset-y-0 my-auto right-0  `}
                                >
                                  <div className="flex items-center h-full gap-2">
                                    <button
                                      className="bg-primary text-white no-underline py-2 px-6 mr-2 rounded-lg"
                                      onClick={() => {
                                        // window.open(`https://business.inforide.app/?accessKey=${businessId}-38e552d965994f65a0abe96310a15915&vehicleId=${_id}`)
                                        window.open(
                                          `${process.env.REACT_APP_DELEGATE_URL}/?accessKey=${businessId}-38e552d965994f65a0abe96310a15915&vehicleId=${_id}`
                                        );
                                      }}
                                    >
                                      message
                                    </button>
                                    <Link
                                      to={`/vehicles/modify-vehicle?auctionVehicleId=${auctionCar?._id}`}
                                      className="px-3 py-2 font-semibold text-center text-white no-underline rounded-lg bg-primary"
                                    >
                                      View
                                    </Link>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setVisible(true);
                                        setVId({
                                          id: _id,
                                          aVId: auctionVehicleId,
                                        });
                                      }}
                                      className="px-3 py-2 font-semibold text-center text-white no-underline bg-red-500 rounded-lg"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </TD>
                              </TR>
                            );
                          })}
                        </TBody>
                      ) : (
                        <TBody>
                          <p className="">No Vehicles To Display</p>
                        </TBody>
                      )}
                    </Tables>
                  </div>
                </div>
              </form>
            )}
          </>
        )}
        <DialogueBox
          cancelNavigation={cancelNavigation}
          confirmNavigation={confirmNavigation}
          show={showPrompt}
        />
      </div>
    </>
  );
};

export default SingleAuction;
