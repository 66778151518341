import React, { useEffect, useState } from "react";

// import VehiclController from "../../Controllers/vehicleController";
import {
  TBody,
  THead,
  TD,
  TH,
  TR,
  Tables,
} from "../../Components/AbstractsComponents/Tables";
import { Link, useSearchParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import Pagination from "../../Components/AbstractsComponents/Pagination";
import { useBuyers } from "../../Controllers/buyersController";

const Vehicles = () => {
  const [searchParam] = useSearchParams();
  const pageNumber = searchParam.get("pageNumber") || 0;

  const filterParams = {
    limit: 10,
    pageNo: parseInt(pageNumber) + 1,
  };
  const { currentPage, loading, totalPages, buyersData } =
    useBuyers(filterParams);

  const TableHead = ["Picture", "Full Name", "Phone Number"];

  return (
    <div>
      <div>
        {loading ? (
          <div className="flex items-center justify-center min-h-[70vh]">
            <TailSpin width={70} height={70} color={`blue`} />
          </div>
        ) : (
          <div>
            <div className="flex flex-col w-full h-auto gap-6 py-8">
              <h3 className="text-left font-semibold  text-[#566589]">
                Buyers
              </h3>

              <div className="bg-white min-h-[70vh]    rounded-3xl">
                <Tables>
                  <THead>
                    <TR>
                      {TableHead.map((ls, index) => (
                        <TH
                          className={`${
                            ls === "Actions"
                              ? "sticky right-0 rounded-tr-2xl bg-gray-400 z-10 text-white"
                              : ""
                          }  whitespace-nowrap`}
                          key={index}
                        >
                          {ls}
                        </TH>
                      ))}
                    </TR>
                  </THead>
                  {buyersData.length > 0 ? (
                    <TBody className={`relative`}>
                      {buyersData?.map((buyer, index) => {
                        const { _id, profilePicture, fullName, phoneNumber } =
                          buyer;

                        return (
                          <TR key={_id}>
                            <TD>
                              <div className="w-16 h-16 rounded-full shadow-xl">
                                <img
                                  src={
                                    profilePicture?.image?.url
                                      ? profilePicture?.image?.url
                                      : "/Images/vehicle-placeholder.webp"
                                  }
                                  className="object-cover w-full h-full rounded-full"
                                  alt="buyer"
                                />
                              </div>
                            </TD>
                            <TD>{fullName}</TD>
                            <TD>{phoneNumber}</TD>
                          </TR>
                        );
                      })}
                    </TBody>
                  ) : (
                    <div className="text-center text-lg font-semibold text-[#566589]">
                      No Buyer Data Available
                    </div>
                  )}
                </Tables>
              </div>
            </div>

            <Pagination
              noOfPages={totalPages}
              currentPage={parseInt(pageNumber)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Vehicles;
