import axios from "axios";
import ReduxDispatchController from "./reduxDispatchController";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ToastController from "./toastController";

class UtilityController {
  static getMetaFilters() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/main-admin/vehicle/filter-meta`)
        .then((res) => {
          console.log(res, "@meta filters");
          if (res.data.success) {
            resolve(res.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err, "@meta error");

          reject(false);
        });
    });
  }
}

export default UtilityController;

export const useMetaData = () => {
  const [loading, setLoading] = useState(false);
  const metaData = useSelector((state) => state.MetaDataReducer.metaData);

  const handleGetMetaFiters = async () => {
    try {
      setLoading(true);
      const data = await UtilityController.getMetaFilters();
      console.log(data, "@make..!");
      ReduxDispatchController.METADATA.set(data.data);
    } catch (error) {
      setLoading(false);
      //   ToastController.error(error);
    }
  };

  useEffect(() => {
    if (!metaData) {
      handleGetMetaFiters();
    }
  }, []);

  return {
    loading,
    make: metaData?.make || [],
  };
};
