import React from "react";
//    importing components
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { BuisnessSlidingPaneBtn } from "./buisnessSlidingPaneBtn";
import { BuyerSlidingPaneBtn } from "./buyerSlidingPaneBtns";
import { VehicleSlidingPaneBtn } from "./vehicleSlidingPaneBtns";
import { TransactionStatusSlidingPane } from "./tranIdSlidingPane";
//    importing images
//    importing icons
//    imoporting css files
import "./transactionsTable.css";
var WalletProducts = [
  {
    amount: 23000,
    business: { fullName: "atamuhiludin" },
    buyer: { fullName: "ata muhiuldin" },
    dateTime: "2022-03-29T00:00:00.000Z",
    payload: {},
    status: "resolved",
    vehicle: { title: "Impreza Premium" },
  },
  {
    amount: 23000,
    businessTitle: "atamuhiludin",
    buyerName: "ata muhiuldin",
    dateTime: "2022-03-29T00:00:00.000Z",
    payload: {},
    status: "resolved",
    vehicleTitle: "Impreza Premium",
  },
];
function BuisnessSlidingPaneBtns(cell, row) {
  return <BuisnessSlidingPaneBtn business={cell} />;
}
function BuyerTableDataBtns(cell, row) {
  return <BuyerSlidingPaneBtn buyer={cell} />;
}

function VehicleTableDataBtns(cell, row) {
  return <VehicleSlidingPaneBtn vehicle={cell} />;
}
function TableDataTransactionBtns(cell, row) {
  return <TransactionStatusSlidingPane offer={cell} />;
}

const renderShowsTotal = (to, total) => {
  return (
    <p className="show-table-data-text">
      Showing {to} from {total}&nbsp;&nbsp;
    </p>
  );
};
export const WalletTable = ({ data }) => {
  // console.log("=> TRNSACTIONS", data)
  // console.log(data);
  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 7, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 4, // the pagination bar size.
    prePage: "<<", // Previous page button text
    nextPage: ">>", // Next page button text
    firstPage: ">", // First page button text
    lastPage: ">>", // Last page button text
    paginationShowsTotal: renderShowsTotal, // Accept bool or function
    hidePageListOnlyOnePage: true,
  };
  return (
    <div>
      <BootstrapTable
        bodyContainerClass="my-custom-body"
        tableContainerClass="my-custom-table-class"
        options={options}
        headerStyle={{ padding: "0px 0px" }}
        tableStyle={{
          borderRadius: "30px",
          minHeight: "calc(100vh - 300px)",
          position: "relative",
          background: "#ffffff",
          border: "none",
          width: "100%",
          marginBottom: "20px",
        }}
        bodyStyle={{
          overflow: "overlay",
          height: "calc(100vh - 300px)",
          background: "#ffffff",
          borderRadius: "30px",
        }}
        data={data}
        pagination={true}
      >
        <TableHeaderColumn
          dataField="business"
          tdStyle={{
            height: "70px",
            padding: "0px 0px 0px 10px",
            verticalAlign: "middle",
            color: "#16478E",
            fontWeight: "700",
          }}
          isKey={true}
          width="150px"
          dataFormat={BuisnessSlidingPaneBtns}
          className="td-header-string-example"
        >
          <div style={{ display: "flex" }}>Buisness Name</div>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="buyer"
          width="160px"
          thStyle={{
            position: "sticky",
            right: "0px",

            backgroundColor: "white",
            borderRadius: "30px",
          }}
          tdStyle={{
            height: "70px",
            padding: "0px 0px 0px 10px",
            verticalAlign: "middle",
            color: "#16478E",
            fontWeight: "700",
          }}
          className="td-header-string-example"
          dataFormat={BuyerTableDataBtns}
        >
          <div style={{ display: "flex" }}>Buyer Full-Name</div>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="vehicle"
          width="300px"
          thStyle={{
            position: "sticky",
            right: "0px",
            backgroundColor: "white",
            borderRadius: "30px",
          }}
          tdStyle={{
            height: "70px",
            padding: "0px 0px 0px 10px",
            verticalAlign: "middle",
            color: "#16478E",
            fontWeight: "700",
          }}
          className="td-header-string-example"
          dataFormat={VehicleTableDataBtns}
        >
          <div style={{ display: "flex" }}>Vehicle</div>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="dateTime"
          tdStyle={{
            height: "70px",
            padding: "0px 0px 0px 10px",
            verticalAlign: "middle",
            fontWeight: "700",
          }}
          className="td-header-string-example"
          width="160px"
        >
          <div style={{ display: "flex" }}>Time</div>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="amount"
          width="100px"
          tdStyle={{
            height: "70px",
            padding: "0px 0px 0px 10px",
            verticalAlign: "middle",
            fontWeight: "700",
          }}
          className="td-header-string-example"
        >
          <div style={{ display: "flex" }}>Amount</div>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="offer"
          width="120px"
          thStyle={{
            position: "sticky",
            right: "0px",
            backgroundColor: "white",
            borderRadius: "30px",
          }}
          tdStyle={{
            height: "70px",
            padding: "0px 0px 0px 10px",
            fontWeight: "700",
            verticalAlign: "middle",
            position: "sticky",
            right: "0px",
            backgroundColor: "white",
          }}
          dataFormat={TableDataTransactionBtns}
          className="td-header-string-example"
        >
          <div style={{ display: "flex" }}>Status</div>
        </TableHeaderColumn>
      </BootstrapTable>
      {/* <div className="wallet-total-divs">
        <div className="wallet-total-main-div">
          <div>
            <p className="payable-text">Total Payable:</p>
            <p className="payable-amount">-$ 1800</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};
