import React from "react";
//    importing icons
import LockIcon from "../../../Assests/Icons/lock.svg";
import { TailSpin } from "react-loader-spinner";
//    importing css files
import "./button.css";
export const Buttons = (props) => {
  const Styles = {
    backgroundColor: props.backgroundColor,
    width: props.width,

    Disable: {
      color: "white",
      backgroundColor: "#C7C7C7",
      boxShadow: "none",
      width: "144px",
      height: "40px",
      // lineHeight:"40px",
      marginLeft: "auto",
      margin: "0px",
    },
  };
  return (
    <div>
      {props.disabled ? (
        <span className="lock-badge">
          <img src={LockIcon} className="lock-icon" alt="" />
        </span>
      ) : (
        <span style={{ textAlign: "right" }}>
          {props.badgeValue ? (
            <span className="button-badge">{props.badgeValue}</span>
          ) : (
            ""
          )}
        </span>
      )}
      <button
        type={props.type}
        id={props.id}
        disabled={
          (props.processing ? true : props.disabled ? true : "") ||
          props.disableBtn
        }
        onClick={props.onClick}
        className={props.className ? props.className : "default-button"}
        style={
          props.messageButton
            ? Styles.messageButton
            : props.offerButton
            ? Styles.offerButton
            : props.editButton
            ? Styles.editButton
            : props.disabled
            ? Styles.Disable
            : props.style
        }
      >
        {props.processing ? (
          <span style={{ margin: "auto 0px" }}>
            <TailSpin
              height="30px"
              width="30px"
              color="white"
              ariaLabel="processing"
            />
          </span>
        ) : (
          <div
            style={{
              display: "flex",
              verticalAlign: "middle",
              justifyContent: "space-between",
              margin: "auto",
            }}
          >
            {props.leftIcon ? (
              <img
                src={props.leftIcon}
                className="button-icon left-icon"
                alt=""
              />
            ) : (
              ""
            )}
            <p style={{ textAlign: "left", margin: "auto" }}>
              {props.children}
            </p>
            {props.RightIcon ? (
              <img
                src={props.RightIcon}
                className="button-icon right-icon"
                alt=""
              />
            ) : (
              ""
            )}
          </div>
        )}
      </button>
      {/* Badge Span */}
    </div>
  );
};
