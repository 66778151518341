import * as Yup from "yup";

export const updateVehicleValidationSchema = Yup.object().shape({
  // images: Yup.array().required(`minimum single img is require`),
  make: Yup.string().required("Make Required!"),
  modal: Yup.string().required("Model Required!"),
  transmission: Yup.string().required("Transmission Required!"),
  engine: Yup.string().required("Engine Required!"),
  // vehicleType: Yup.string().required("Vehicle Type Required!"),
  year: Yup.number().required("Year Required!"),
  interior: Yup.string().required("Interior Required!"),
  driveTrain: Yup.string().required("Drive Train Required!"),
  mileageCity: Yup.number().required("Mileage City Required!"),
  mileageHighway: Yup.number().required("Mileage Highway Required!"),
  description: Yup.string(),
  trimLevel: Yup.string().required("Trim Level Required!"),
  vehicleSellingStatus: Yup.string(),
  title: Yup.string().required("Title Required!"),
  style: Yup.string().required("Style Required!"),
  madeIn: Yup.string(),
  fuleType: Yup.string().required("Fuel Type Required!"),
  exterior: Yup.string().required("Exterior Required!"),
  driveType: Yup.string().required("Drive Type Required!"),
  KMDriven: Yup.number(),
  VIN: Yup.string().required("VIN Required!"),
  priceMin: Yup.number().required("Minimum Price Range Required!"),
  // priceMax: Yup.number().required("Maximum Price Range Required!"),
});
