import { ERRORS } from "./errorMessages";
import axios from "axios";
import ReduxDispatchController from "./reduxDispatchController";
import ToastController from "./toastController";
import { useSelector } from "react-redux";
import store from "../Store";
import { useEffect, useState } from "react";
import AuthController from "./authController";
class BusinessesController {
  static ADAPTER = {
    formatBusiness: (business) => {
      return {
        payload: business,
        pictureUrl: business?.profilePicture?.avatar?.url,
        fullName: business.fullName,
        email: business.email,
        recievable: `$${business.payable ? business.payable : 0}`,
        actions: business.verificationStatus,
      };
    },
  };
  static APPROVAL_STATUS = {
    APPROVED: "approved",
    PENDING: "pending",
    BLOCKED: "blocked",
    ALL: "",
  };

  static isValidApproalFilter(filterStatus) {
    return (
      filterStatus === BusinessesController.APPROVAL_STATUS.APPROVED ||
      filterStatus === BusinessesController.APPROVAL_STATUS.PENDING ||
      filterStatus === BusinessesController.APPROVAL_STATUS.BLOCKED
    );
  }

  static getBusinesses() {
    return new Promise((resolve, reject) => {
      // console.log(`AuthToken: ${axios.defaults.headers.common['Authorization']}` )
      axios
        .get(`/main-admin/auth/get-businesses-by-verification-status`)
        .then((res) => {
          if (res.data.success) {
            resolve(
              res.data.data.map((b) =>
                BusinessesController.ADAPTER.formatBusiness(b)
              )
            );
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  static getBuyers() {
    return new Promise((resolve, reject) => {
      // console.log(`AuthToken: ${axios.defaults.headers.common['Authorization']}` )
      axios
        .get(`/main-admin/buyers/get-all`)
        .then((res) => {
          console.log(res.data, "@buyers....");
          if (res.data.success) {
            resolve(true);
            // res.data.data.map((b) =>
            //   BusinessesController.ADAPTER.formatBusiness(b)
            // )
          } else {
            console.log(res.data, "@buyers errr....");

            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err, "@buyers errr....");

          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }
  static loadBusinesses() {
    let vehiclesLoading = store.getState().BusinessesReducer.businessesLoading;
    let alreadyLoaded =
      store.getState().BusinessesReducer.businesses.length > 0;
    if (!(vehiclesLoading && alreadyLoaded)) {
      ReduxDispatchController.BUSINESSES.setLoading(true);
      BusinessesController.getBusinesses()
        .then((businesses) => {
          // console.log(`Loaded businesses`)
          // console.log(businesses);
          ReduxDispatchController.BUSINESSES.setAll(businesses);
          ReduxDispatchController.BUSINESSES.setLoading(false);
        })
        .catch((err) => {
          console.log(`CATCH Error in loading businesses.`);
          console.log(err);
          ToastController.error(err);
          ReduxDispatchController.BUSINESSES.setLoading(false);
        });
    }
  }

  static updateApprovalStatus(businessId, approvalStatus) {
    return new Promise((resolve, reject) => {
      const data = {
        _id: businessId,
        approvalStatus: approvalStatus,
      };
      // console.log("Approval Data", data);
      axios
        .post(`/main-admin/auth/change-approval-status`, data)
        .then((res) => {
          if (res.data.success) {
            ReduxDispatchController.BUSINESSES.setApprovalStatus(
              businessId,
              approvalStatus
            );
            resolve(true);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }
  static markPaymentAsRecieved(businessId, amount, currentRecievables) {
    return new Promise((resolve, reject) => {
      console.log({ amount, number: Number(amount) }, "@payment amount");
      axios
        .post(`/payment/account/receive-money-from-business/${businessId}`, {
          amount: Number(amount),
        })
        .then((res) => {
          console.log(res, "@payment ");
          if (res.data.success) {
            ReduxDispatchController.BUSINESSES.updatePayables(
              businessId,
              currentRecievables - amount
            );
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((err) => {
          reject(false);
        });
    });
  }
}
export default BusinessesController;

export const useBusinessWithApprovalStatus = (approvalStatus) => {
  useEffect(() => {
    BusinessesController.loadBusinesses();
  }, []);

  let { allBusinesses, loading, businessCount } = useSelector((state) => ({
    allBusinesses: state.BusinessesReducer.businesses,
    loading: state.BusinessesReducer.businessesLoading,
    businessCount: state.BusinessesReducer.businessCount,
  }));

  let businesses = [];
  if (BusinessesController.isValidApproalFilter(approvalStatus)) {
    console.log(`Filter by:${approvalStatus}`);
    businesses = allBusinesses.filter(
      (b) => b.payload.verificationStatus === approvalStatus
    );
  } else {
    businesses = allBusinesses;
  }

  return { businesses, loading, businessCount };
};

export const useAuctions = () => {
  const [loading, setLoading] = useState(false);
  const auctions = useSelector((state) => state.AuctionReducer?.auctions);
  const noOfPages = useSelector((state) => state.AuctionReducer?.noOfPages);

  useEffect(() => {
    if (!auctions || auctions.length <= 0) {
      console.log("@auction hook runnging.....");
      setLoading(true);
      BusinessesController.getBuyers()
        .then((res) => {
          setLoading(false);
          ReduxDispatchController.AUCTIONS.set(res);
          console.log(res, "@auctions auctions in hook.......");
        })
        .catch((err) => {
          console.log(err, "@auctions auctions error in hook....");
          setLoading(false);
        });
    }
  }, []);

  return {
    loading: loading,
    auctions,
    noOfPages,
  };
};
