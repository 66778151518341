import React, { useEffect } from "react";
// import WalletIcon from "../../Assests/Icons/wallletView.png";
import { WalletTable } from "../../Components/CompositeComponents/Table/TransactionsTable/transactionsTable";
// import { Modals } from "../../Components/AbstractsComponents/Modal/modal";
// import {
//   WalletModal,
//   SetUpPaymentModal,
//   SetUpPaymentAmountModal,
// } from "../../Components/CompositeComponents/WalletModal/walletModal";
//    importing css files
import Skeletons from "../../Components/ModuleComponents/Skeleton/transactionListSkeleton";
import "./transactionsScreen.css";
import { useTransactions } from "../../Controllers/transactionsController";
export const TransactionsScreen = () => {
  // const [modalIsOpen, setIsOpen] = React.useState(false);
  // function openModal() {
  //   setIsOpen(true);
  // }

  // function closeModal() {
  //   setIsOpen(false);
  // }
  //destructuring pathname from location
  // const { pathname } = location.pathname;

  //Javascript split method to get the name of the path in array
  // const splitLocation = window.location.href.split("/");
  useEffect(() => {
    // Update the document title using the browser API
    // console.log(splitLocation);
  });
  const { loading, transactions, totalReceivable } = useTransactions();
  console.log(transactions, "@transaction......");
  return (
    <React.Fragment>
      <div>
        <div className="all-vehicle-header">
          <h1>Transactions</h1>
          <div style={{ margin: "auto 0px", display: "flex" }}>
            {/* <button
              className="wallet-view-button"
              onClick={(e) => openModal(e)}
            >
              <img src={WalletIcon} className="wallet-button-icon" alt="" />
              View
            </button>
            <Modals modalIsOpen={modalIsOpen} closeModal={closeModal}>
              {splitLocation[3] === "transactions#addPaymentMethod" ? (
                <SetUpPaymentModal closeModal={closeModal} />
              ) : splitLocation[3] === "transactions#AddPaymentAmount" ? (
                <SetUpPaymentAmountModal closeModal={closeModal} />
              ) : (
                <WalletModal />
              )}
            </Modals> */}
            <p className="wallet-payable-text">
              Payable:<span>{`$${totalReceivable}`}</span>
            </p>
          </div>
        </div>
        <div>
          {/* <WalletTable /> */}
          {loading ? (
            <>
              <Skeletons />
              <Skeletons />
              <Skeletons />
              <Skeletons />
              <Skeletons />
              <Skeletons />
              <Skeletons />
              <Skeletons />
            </>
          ) : (
            <WalletTable data={transactions} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
