import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import React, { useEffect, forwardRef, useRef } from "react";
import styles from "./modal.module.css";
import { createPortal } from "react-dom";

const Modal = ({ children, wrapperClass, setVisible, visible, closeModal }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (visible) {
      disableBodyScroll(modalRef.current);
    } else {
      clearAllBodyScrollLocks();
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [visible]);

  return createPortal(
    visible && (
      <>
        <div
          onClick={closeModal}
          // ref={modalRef}
          className={`${styles.anmiateModal} fixed top-0 z-[10000000] left-0 transition-[animation]  duration-200 ease-in-out w-screen h-screen bg-black/60  bg-modalWrapperColor backdrop-blur-[10px]`}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={`w-full flex absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2   max-h-max ${wrapperClass}`}
          >
            {children}
          </div>
        </div>
      </>
    ),
    document.body
  );
};

export default forwardRef(Modal);
