import { VEHICLES } from "../types";
import produce from "immer";
let state = {
  vehicles: null,
};

const VehicleReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case VEHICLES.SET_ALL_VEHICLES:
      return produce(mState, (draftState) => {
        console.log("Vehicles Reducer", action);
        draftState.vehicles = action?.payload;
      });
    case VEHICLES.SET_UPDATED_DATA:
      return produce(mState, (draftState) => {
        const filterVehicle = draftState?.vehicles?.vehicles?.findIndex(
          (v) => v?._id === action?.payload?._id
        );
        console.log({ filterVehicle, draftState }, "@update index in reducer");

        if (filterVehicle >= 0) {
          draftState.vehicles.vehicles[filterVehicle] = action?.payload;
        }
      });

    case VEHICLES.DELTE_VEHICLE:
      return produce(mState, (draftState) => {
        console.log("@delete vehicle ....", action.payload);
        draftState.vehicles.vehicles = draftState.vehicles?.vehicles.filter(
          (v) => v._id !== action.payload
        );
      });
    default:
      return { ...mState };
  }
};

export default VehicleReducer;
