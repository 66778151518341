import ReduxDispatchController from "./reduxDispatchController";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import ReduxDispatchController from "./reduxDispatchController";
import axios from "axios";

class AuctionController {
  static getAllAuctions() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/auction`)
        .then((res) => {
          console.log("Auction .....", res.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log("Err At Auctions....", err);
          reject(err);
        });
    });
  }

  static createAuction = (data) => {
    console.log("Create Auction Data...", data);
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("image", data.image.file);
      formData.append("title", data.title);
      formData.append("auctionStartDateTime", data.auctionStartDateTime);
      formData.append("auctionEndDateTime", data.auctionEndDateTime);
      formData.append("location", JSON.stringify(data.location));

      axios
        .post(`/auction`, formData)
        .then((res) => {
          console.log("@auction create...", res.data);
          if (res.data.success) {
            console.log("@auction res.......", res.data);
            ReduxDispatchController.AUCTIONS.setNewAuction(res.data.data);
            resolve(true);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log("Err...", err);
          reject(err);
        });
    });
  };

  static updateAuction = (data, aId) => {
    console.log("Data...", data, aId);
    return new Promise((resolve, reject) => {
      axios
        .patch(`auction/${aId}/update-data-and-image-urls`, data)
        .then((res) => {
          console.log(res.data, "@auction update in controller .......");

          if (res.data.success) {
            ReduxDispatchController.AUCTIONS.setUpdateAuction(res.data.data);
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  static uploadImage = (data) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("image", data.image);
      axios
        .post(`/image/upload`, formData)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.log("err upload file....", err);
          reject(err);
        });
    });
  };

  static deleteAuctionVehicle = (data) => {
    console.log("@delete auction vehicle data....", data);
    return new Promise((resolve, reject) => {
      axios
        .post(`/auction/vehicle/remove-auctioned-vehicle`, data)
        .then((res) => {
          if (res.data.success) {
            console.log("Res....", res);
            ReduxDispatchController.AUCTIONS.setUpdateAuction(res.data.data);
            resolve(res.data.data);

            // AuctionController.getAllAuctions()
            //   .then((res1) => {
            //     ReduxDispatchController.AUCTIONS.set(res1);
            //     console.log(res1, "@auctions auctions in hook.......");
            //   })
            //   .catch((err1) => {
            //     console.log(err1, "@auctions auctions error in hook....");
            //   });
          } else {
            reject(res.data);
          }
        })
        .catch((err) => {
          console.log("Err....", err);
          reject(err);
        });
    });
  };

  static getSingleAuctionVehicle(id) {
    return new Promise((resolve, reject) => {
      console.log(id, "@single id........");
      axios
        .get(`/auction/vehicle/${id}`)
        .then((res) => {
          if (res.data.success) {
            console.log("@single vehicle response......", res.data.data);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log("@single vehicle error.....", err);
          reject(err);
        });
    });
  }

  static getSingleAuction(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/auction/${id}`)
        .then((res) => {
          if (res) {
            console.log("@single auction response......", res.data);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log("@single auction err....", err);
          reject(err);
        });
    });
  }

  static delete(_id, flag) {
    return new Promise((resolve, reject) => {
      console.log(_id, "@delete Auction _id");
      axios
        .delete(`/auction/${_id}?allowOverride=${flag}`)
        .then((res) => {
          console.log(res.data, `@delete Auction res.........`);

          if (res.data.success) {
            console.log(`@delete Auction success.........`);
            ReduxDispatchController.AUCTIONS.deleteSingleAuction(_id);
            resolve(true);
          } else {
            console.log(res.data);
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err, `@delete Auction error.........`);
          reject("Network Error");
        });
    });
  }

  static addNewVehicle(data) {
    console.log("@add new vehicle data......", data);
    return new Promise((resolve, reject) => {
      axios
        .post(`/auction/vehicle`, data)
        .then((res) => {
          if (res.data.success) {
            console.log("@Res cotroller", res.data);
            console.log("@delete vehicle", data?.vehicle);
            ReduxDispatchController.VEHICLES.setDeleteVheicle(data?.vehicle);
            resolve(true);
          } else {
            console.log("Message", res.data.error.message);
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log("Err.........", err);
          reject(err);
        });
    });
  }
}

export default AuctionController;

export const useAuctions = () => {
  const [loading, setLoading] = useState(false);
  const auctions = useSelector((state) => state.AuctionReducer?.auctions);
  const noOfPages = useSelector((state) => state.AuctionReducer?.noOfPages);

  useEffect(() => {
    if (!auctions || auctions.length <= 0) {
      console.log("@auction hook runnging.....");
      setLoading(true);
      AuctionController.getAllAuctions()
        .then((res) => {
          setLoading(false);
          ReduxDispatchController.AUCTIONS.set(res);
          console.log(res, "@auctions auctions in hook.......");
        })
        .catch((err) => {
          console.log(err, "@auctions auctions error in hook....");
          setLoading(false);
        });
    }
  }, []);

  return {
    loading: loading,
    auctions,
    noOfPages,
  };
};
