import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ERRORS } from "./errorMessages";
import ReduxDispatchController from "./reduxDispatchController";
import ToastController from "./toastController";
class OffersController {
  static toast = new ToastController();

  static getAll() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/offer/offer/get-refundable-refunded-offers`)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  static setMarkAsRefunded = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/offer/offer/mark-as-refunded/${id}`)
        .then((res) => {
          if (res.data.success) {
            console.log("@offer update res...", res.data.data);
            ReduxDispatchController.OFFERS.setUpdateOffer(res.data.data);
            resolve(true);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  };
}

export default OffersController;

export const useOffers = () => {
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    console.log("@offers hook runnging.....");
    setLoading(true);
    OffersController.getAll()
      .then((res) => {
        setLoading(false);
        setOffers(res.offers);

        ReduxDispatchController.OFFERS.set(res.offers);
        console.log(res, "@offers offers in hook.......");
      })
      .catch((err) => {
        console.log(err, "@offers offers error in hook....");
        setLoading(false);
      });
  }, []);

  return {
    offers,
    loading,
  };
};
