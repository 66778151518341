import store from "../Store/index";
import { IsAuthenticated } from "../Store/Types/types";
import {
  AUTH,
  BUSINESSES,
  TRANSACTIONS,
  AUCTIONS,
  VEHICLES,
  OFFERS,
  BUYERS,
  METADATA,
} from "../Store/types";
class ReduxDispatchController {
  static AUTH = {
    setAuthStatus: (isAutenticated) => {
      // console.log("function called");
      // console.log(isAutenticated);
      // console.log("function called");

      store.dispatch({
        type: IsAuthenticated,
        payload: isAutenticated,
      });
    },
    setSplashVisible: (visible) => {
      store.dispatch({
        type: AUTH.SET_SPLASH_VISIBLE,
        payload: visible,
      });
    },
    setCurrentUser: (user) => {
      store.dispatch({
        type: AUTH.SET_CURRENT_USER,
        payload: user,
      });
    },
  };
  static BUSINESSES = {
    setAll: (businesses) => {
      store.dispatch({
        type: BUSINESSES.SET_ALL,
        payload: businesses,
      });
    },
    setKeyValue: (key, value) => {
      store.dispatch({
        type: BUSINESSES.SET_KEY_VALUE,
        payload: { key, value },
      });
    },
    setLoading: (loading) => {
      ReduxDispatchController.BUSINESSES.setKeyValue(
        "businessesLoading",
        loading
      );
    },
    setApprovalStatus: (businessId, approvalStatus) => {
      store.dispatch({
        type: BUSINESSES.SET_APPROVAL_STATUS,
        payload: { businessId, approvalStatus },
      });
    },
    updatePayables: (businessId, amount) => {
      store.dispatch({
        type: BUSINESSES.UPDATE_PAYABLES,
        payload: { businessId, amount },
      });
    },
  };

  static BUYERS = {
    set: (buyers) => {
      console.log(buyers, "@buyers.......");
      store.dispatch({
        type: BUYERS.SET_ALL,
        payload: buyers,
      });
    },
  };

  static TRANSACTIONS = {
    set: (transactions, totalReceivable) => {
      console.log(transactions, "@trans.......");
      store.dispatch({
        type: TRANSACTIONS.SET_ALL,
        payload: { transactions, totalReceivable },
      });
    },
    setKeyValue: (key, value) => {
      store.dispatch({
        type: TRANSACTIONS.SET_KEY_VALUE,
        payload: { key, value },
      });
    },
    setLoading: (isLoading) => {
      ReduxDispatchController.TRANSACTIONS.setKeyValue("loading", isLoading);
    },
    setStatus: (transactionId, status) => {
      store.dispatch({
        type: TRANSACTIONS.SET_STATUS,
        payload: { transactionId, status },
      });
    },
  };

  static VEHICLES = {
    set: (vehicles) => {
      console.log("Vehicles in action....", vehicles);
      store.dispatch({
        type: VEHICLES.SET_ALL_VEHICLES,
        payload: vehicles,
      });
    },
    setUpdatedVehicleData: (data) => {
      store.dispatch({
        type: VEHICLES.SET_UPDATED_DATA,
        payload: data,
      });
    },
    setDeleteVheicle: (id) => {
      console.log("@delete vehicle redux dispatch contorller...", id);
      store.dispatch({
        type: VEHICLES.DELTE_VEHICLE,
        payload: id,
      });
    },
  };

  static OFFERS = {
    set: (offers) => {
      store.dispatch({
        type: OFFERS.SET_ALL_OFFERS,
        payload: offers,
      });
    },
    setUpdateOffer: (offer) => {
      console.log("offer update in dispatch controller....", offer);
      store.dispatch({
        type: OFFERS.SET_UPDATE_OFFER,
        payload: offer,
      });
    },
  };

  static METADATA = {
    set: (metadata) => {
      store.dispatch({
        type: METADATA.SET_ALL_METADATA,
        payload: metadata,
      });
    },
  };

  static AUCTIONS = {
    set: (auctions) => {
      store.dispatch({
        type: AUCTIONS.SET_AUCTIONS,
        payload: auctions,
      });
    },
    setNewAuction: (auction) => {
      console.log("New Auction @action...", auction);
      store.dispatch({
        type: AUCTIONS.SET_NEW_AUCTION,
        payload: auction,
      });
    },

    setUpdateAuction: (auction) => {
      console.log("New Auction @action...", auction);
      store.dispatch({
        type: AUCTIONS.SET_UPDATE_AUCTION,
        payload: auction,
      });
    },
    setSingleAuction: (auction) => {
      store.dispatch({
        type: AUCTIONS.SET_SINGLE_AUCTION,
        payload: auction,
      });
    },

    deleteSingleAuction: (_id) => {
      store.dispatch({
        type: AUCTIONS.DELETE_SINGLE_AUCTION,
        payload: _id,
      });
    },

    addVehicle: (id, vehicle) => {
      console.log("Hit......", id, vehicle);
      store.dispatch({
        type: AUCTIONS.ADD_NEW_VEHICLE,
        payload: { id, vehicle },
      });
    },
  };
}
export default ReduxDispatchController;
