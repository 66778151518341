import { combineReducers } from "redux";
import AuthReducer from "./authReducer";
import BusinessesReducer from "./businessesReducer";
import TransactionsReducer from "./trnsactionsReducer";
import AuctionReducer from "./auctionReducer";
import VehicleReducer from "./vehicleReducer";
import BuyersReducer from "./buyersReducer";
import OffersReducer from "./offersReducer";
import MetaDataReducer from "./metaDataReducer";

const rootReducer = combineReducers({
  AuthReducer,
  BusinessesReducer,
  TransactionsReducer,
  AuctionReducer,
  VehicleReducer,
  BuyersReducer,
  OffersReducer,
  MetaDataReducer,
});

export default rootReducer;
