import { AUCTIONS } from "../types";
import produce, { current } from "immer";
let state = {
  auctions: [],
  noOfPages: null,
  auction: null,
};

const AuctionReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case AUCTIONS.SET_AUCTIONS:
      return produce(mState, (draftState) => {
        draftState.auctions = action.payload.auctions;
        draftState.noOfPages = action.payload.noOfPages;
      });
    case AUCTIONS.SET_SINGLE_AUCTION:
      return produce(mState, (draftState) => {
        draftState.auction = action.payload;
      });
    case AUCTIONS.SET_NEW_AUCTION:
      return produce(mState, (draftState) => {
        // let newAuction = action.payload;
        draftState.auctions.push(action.payload);
        // draftState.auctions = [...draftState.auctions, newAuction];
      });

    case AUCTIONS.SET_UPDATE_AUCTION:
      return produce(mState, (draftState) => {
        const auctionIndex = draftState.auctions.findIndex(
          (item) => item._id === action.payload._id
        );
        if (auctionIndex >= 0) {
          draftState.auctions[auctionIndex] = action.payload;
        }
      });

    case AUCTIONS.DELETE_SINGLE_AUCTION:
      return produce(mState, (draftState) => {
        let filteredAuctions = draftState.auctions.filter(
          (item) => item._id !== action.payload
        );

        draftState.auctions = filteredAuctions;
      });
    case AUCTIONS.DELETE_SINGLE_AUCTION_VEHICLE:
      console.log("@auction vehicle delte...", action.payload);
      return produce(mState, (draftState) => {
        console.log("@auction delete vehicle", action.payload);
        let index = draftState.auctions.findIndex(
          (item) => item._id === action.payload.aId
        );
        console.log("index...", current(draftState.auction[index]));
        if (index >= 0) {
          let filter = draftState.auctions[index].auctionCars.filter(
            (item) => item._id !== action.payload.vId
          );
          draftState.auctions[index].auctionCars = filter;
        }
      });

    case AUCTIONS.ADD_NEW_VEHICLE:
      console.log("@auction add new vehicle  Hit .....");
      return produce(mState, (draftState) => {
        const index = draftState.auctions.findIndex(
          (auction) => auction._id === action.payload.id
        );

        console.log("@auction index.....", index);

        console.log(
          "Auction Before...",
          index,
          current(draftState.auctions[index]),
          action.payload.vehicle
        );
        if (index >= 0) {
          let auctionVehicles = draftState.auctions[index].vehicles;

          console.log("@auction vehicles .....", current(auctionVehicles));

          console.log("@auction payload .....", action.payload);

          console.log(
            "@auction state before udpate .....",
            current(draftState.auctions[index])
          );

          draftState.auctions[index].vehicles = [
            ...auctionVehicles,
            action.payload.vehicle,
          ];

          console.log(
            "@auction state after udpate .....",
            current(draftState.auctions[index])
          );
        } else {
          console.log("Invalid ......");
        }

        // filterOption.vehicles = [...filterOption.vehicles, newVehicle];
      });
    default:
      return { ...mState };
  }
};
export default AuctionReducer;
