import { Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import styles from "./customMarker.module.css";

const CustomMarker = ({ position, isMarker, onChange, liveLocation }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 1000);
  }, []);

  if (loaded) {
    console.log(`Renderting Marker`);
    return (
      <>
        {/* orignal marker */}
        <Marker zIndex={9999} position={position} visible={false}></Marker>{" "}
        {/* ....................
        .......seprator........
        ................ */}
        {/* current locatin marker */}
        <Marker
          icon={{
            url: "/my-location-48.png",

            // size: {
            //   height: 20,
            //   width: 20,
            // },
            // path: "/my-location-48.png",
            // fillColor: "blue",
          }}
          position={liveLocation}
        />
        {/* ....................
        .......seprator........
        ................ */}
        {/* fake marker */}
        <div className={styles.fakeMarkerWrapper}>
          <div
            className={styles.fakeMarker}
            style={
              !isMarker
                ? { marginTop: "-35px", fontSize: "55px" }
                : { marginTop: "-28px" }
            }
          >
            <MdLocationPin />
          </div>
          <div className={styles.shadow}></div>
        </div>
      </>
    );
  } else {
    return false;
  }
};

export default CustomMarker;
