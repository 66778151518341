import React, { useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { TailSpin } from "react-loader-spinner";
import { TextFields } from "../../../Components/AbstractsComponents/InputFields";
import AuctionController from "../../../Controllers/auctionController";
import { useNavigate } from "react-router";
import { LocationTextField } from "../../../Components/AbstractsComponents/InputFields/LocationInputField";
import ToastController from "../../../Controllers/toastController";
import { MdWatchLater } from "react-icons/md";

const AuctionForm = () => {
  const navigate = useNavigate();
  const [area, setArea] = useState("");
  const [auction, setAuction] = useState({
    title: "",
    image: { file: null, url: "" },
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    location: {
      description: "",
      area: "",
      coords: {
        longitude: null,
        latitude: null,
      },
    },
  });

  const toast = new ToastController();
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(null);

  const handleSubmit = () => {
    console.log(auction, "@auction data.......");

    if (
      !auction.image.file ||
      !auction.title ||
      !auction.startDate ||
      !auction.location.area ||
      // !auction.startTime ||
      !auction.endDate ||
      // !auction.endTime ||
      !auction?.location?.description
    ) {
      toast.endProcessingWithError("All fields are required!");
      setValidate("All fields are required!");
      return;
    }
    setValidate("");
    let data = {
      title: auction.title,
      auctionStartDateTime:
        auction.startDate + "T" + auction.startTime + ":00.000Z",
      auctionEndDateTime: auction.endDate + "T" + auction.endTime + ":00.000Z",
      // auctionStartDateTime: new Date(auction.startDate),
      // auctionEndDateTime: new Date(auction.endDate),
      location: {
        description: auction.location.description,
        coords: auction.location.coords,
        area: area,
      },
      image: auction.image,
    };

    console.log("data create auction.....", data);
    setLoading(true);
    toast.showProcessing("Creating an auction ......");
    AuctionController.createAuction(data)
      .then((res) => {
        if (res) {
          toast.endProcessingWithSuccess("Auction created successfully");
          setAuction({
            title: "",
            image: { file: null, url: "" },
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            location: {
              description: "",
              area: "",
              coords: {
                longitude: null,
                latitude: null,
              },
            },
          });
          setLoading(false);
          navigate("/auctions");
        }
      })
      .catch((err) => {
        ToastController.error(err);
        console.log("Err", err);

        setLoading(false);
      });
  };

  const handleLocationField = (address) => {
    console.log(address, "updated address");
    setArea(address?.area);
    setAuction({
      ...auction,
      location: {
        description: address.address,
        area: address.area,
        coords: {
          longitude: address.location.lng,
          latitude: address.location.lat,
        },
      },
    });
  };

  // console.log("Auction ....", auction);
  return (
    <div className="flex flex-col gap-4 max-w-[500px] self-center w-full">
      <div
        className={`max-w-[500px] w-full h-[200px] rounded-md   border-dashed border-2 border-gray-300 ${
          auction?.image?.file && "border-none"
        }`}
      >
        {auction?.image?.file !== null ? (
          <div className="relative w-full h-full">
            <img
              src={auction?.image?.url}
              className="object-cover w-full h-full rounded-md"
              alt="cover"
            />
            <div
              className="absolute -top-3 -right-2"
              onClick={() =>
                setAuction({ ...auction, image: { url: "", file: null } })
              }
            >
              <RiCloseCircleFill className="text-[20px] text-red-600 cursor-pointer" />
            </div>
          </div>
        ) : (
          <label
            htmlFor="file"
            className="flex flex-col items-center justify-center w-full h-full gap-1 text-center cursor-pointer font-lato"
          >
            <h3 className="text-lg font-bold font-lato">Select cover image</h3>
            <input
              type="file"
              accept="image/*"
              onChange={(e) =>
                setAuction({
                  ...auction,
                  image: {
                    file: e.target.files[0],
                    url: URL.createObjectURL(e.target.files[0]),
                  },
                })
              }
              id="file"
              title=""
              placeholder="Choose File"
              className="w-full text-center sr-only file:hidden placeholder:hidden font-lato placeholder:text-gray-400"
            />
          </label>
        )}
      </div>
      <TextFields
        type={`text`}
        value={`${auction.title}`}
        placeholder="Auction Title"
        label={"Auction Title"}
        className={`pl-2`}
        onChange={(e) => setAuction({ ...auction, title: e.target.value })}
      />

      <LocationTextField
        type="text"
        name="location"
        id="location"
        label="Location / Address"
        placeholder="2564 Joes Road, New York"
        value={auction?.location?.description}
        onChange={(e) =>
          setAuction({
            ...auction,
            location: { ...auction?.location, description: e.target.value },
          })
        }
        // {...getFieldProps("location")}
        // icon={LocationIcon}
        onLoactionChange={handleLocationField}
      />
      <div className="flex flex-col flex-1 w-full h-full gap-1 -mt-3">
        <p className="text-dark1 font-bold text-sm mb-[2px] text-left">
          Area *
        </p>
        <input
          type={`text`}
          value={`${area}`}
          onChange={(e) => setArea(e.target.value)}
          className="w-full h-[48px] outline-none border-2 bg-white px-2 border-gray-300 rounded-lg"
        />
      </div>

      <div className="flex w-full gap-4 ">
        <div className="flex flex-col flex-1 w-full h-full gap-1">
          <p className="text-dark1 font-bold text-sm mb-[2px] text-left">
            Start Date*
          </p>
          <input
            type={`date`}
            name={`startDate`}
            value={auction.startDate}
            onChange={(e) =>
              setAuction({
                ...auction,
                startDate: e.target.value,
              })
            }
            className="w-full h-[48px] outline-none border-2 bg-white px-2 border-gray-300 rounded-lg"
          />
        </div>
        <div className="flex flex-col flex-1 w-full h-full gap-1">
          <p className="text-dark1 font-bold text-sm mb-[2px] text-left">
            Start Time*
          </p>
          <div className="w-full h-[48px] flex justify-between items-center outline-none border-2 bg-white px-2 border-gray-300 rounded-lg">
            <input
              type={`time`}
              name={`startTime`}
              value={auction.startTime}
              onChange={(e) =>
                setAuction({
                  ...auction,
                  startTime: e.target.value,
                })
              }
              className="w-full outline-none "
              placeholder="09:00 PM"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-4 ">
        <div className="flex flex-col flex-1 w-full h-full gap-1">
          <p className="text-dark1 font-bold text-sm mb-[2px] text-left">
            End Date*
          </p>
          <input
            type={`date`}
            name={`endDate`}
            value={auction.endDate}
            onChange={(e) =>
              setAuction({
                ...auction,
                endDate: e.target.value,
              })
            }
            className="w-full h-[48px] outline-none border-2 bg-white px-2 border-gray-300 rounded-lg"
          />
        </div>
        <div className="flex flex-col flex-1 w-full h-full gap-1">
          <p className="text-dark1 font-bold text-sm mb-[2px] text-left">
            End Time*
          </p>
          <div className="w-full h-[48px] flex justify-between items-center outline-none border-2 bg-white px-2 border-gray-300 rounded-lg">
            <input
              type={`time`}
              name={`endTime`}
              value={auction.endTime}
              onChange={(e) =>
                setAuction({
                  ...auction,
                  endTime: e.target.value,
                })
              }
              className="w-full outline-none"
              placeholder="09:00 PM"
            />
          </div>
        </div>
      </div>

      <button
        onClick={handleSubmit}
        disabled={loading}
        className="self-end text-center inline-flex justify-center items-center p-2 w-[150px] text-white rounded-lg bg-primary"
      >
        {loading ? (
          <TailSpin width={20} height={20} color={`white`} />
        ) : (
          "Create Auction"
        )}
      </button>
      {validate && (
        <p className="text-lg text-red-500 capitalize text-center font-[500]">
          {validate}
        </p>
      )}
    </div>
  );
};

export default AuctionForm;
