import React, { useState } from "react";
import Skeletons from "../../Components/ModuleComponents/Skeleton/transactionListSkeleton";
import { useAuctions } from "../../Controllers/auctionController";

import {
  TBody,
  THead,
  TD,
  TH,
  TR,
  Tables,
} from "../../Components/AbstractsComponents/Tables";
// import { auctions } from "./data";
import { Link, useSearchParams } from "react-router-dom";
import DeleteAuctionModal from "./DeleteAuctionModal";

const Auctions = () => {
  const TableHead = ["Picture", "Title", "Created At"];

  // const auctions = useSelector((state) => state.AuctionReducer.auctions);
  const { auctions, loading } = useAuctions();
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);

  console.log("Auction Data In Auctions Page.....", auctions);

  const handleConfirmModal = (_id) => {
    setId(_id);
    setShow(true);
  };

  // console.log("Vehicles.....", auctions[0].vehicles);

  return (
    <div className="flex flex-col w-full h-auto gap-6 py-8">
      <div className="flex justify-between items-center ">
        <h3 className="text-left font-semibold text-[#566589]">Auctions</h3>
        <Link
          to={`/modify-auction`}
          className="bg-primary text-white no-underline py-2 px-6 rounded-lg"
        >
          Create Auction
        </Link>
      </div>
      <div
        className={`${
          loading ? "bg-transparent" : "bg-white"
        } min-h-[70vh] rounded-3xl`}
      >
        {loading ? (
          <>
            <Skeletons />
            <Skeletons />
            <Skeletons />
            <Skeletons />
            <Skeletons />
            <Skeletons />
            <Skeletons />
          </>
        ) : (
          <Tables>
            <THead>
              <TR>
                {TableHead.map((ls, index) => (
                  <TH
                    className={
                      ls === "Actions"
                        ? "sticky right-0 bg-gray1 z-10 text-white"
                        : ""
                    }
                    key={index}
                  >
                    {ls}
                  </TH>
                ))}
              </TR>
            </THead>
            {auctions.length > 0 && auctions !== undefined ? (
              <TBody className={`relative`}>
                {auctions?.map((auction) => {
                  const { pictures, title, createdAt, _id } = auction;

                  return (
                    <TR key={_id}>
                      <TD>
                        <div className="w-28 h-28">
                          <img
                            src={pictures[0]?.image?.url}
                            className="object-cover object-center w-full h-full rounded-lg"
                            alt="vehicle"
                          />
                        </div>
                      </TD>
                      <TD>{title}</TD>
                      <TD>{createdAt.slice(0, 10).replaceAll("-", "/")}</TD>
                      <TD>{""}</TD>
                      <TD>{""}</TD>

                      <TD className={`sticky inset-y-0 my-auto right-0  `}>
                        <div className="flex items-center h-full gap-1">
                          <div className="flex items-center h-full flex-1 ">
                            <Link
                              to={`/auction-info?id=${_id}`}
                              className="px-3 py-2 font-semibold text-center text-white no-underline rounded-lg bg-primary"
                            >
                              View
                            </Link>
                          </div>
                          <button
                            onClick={() => handleConfirmModal(_id)}
                            className="bg-red-400 flex-1 rounded-lg text-white px-3 py-2 w-full text-center inline-flex justify-center items-center "
                          >
                            {/* {processing === _id ? (
                        <TailSpin
                          height="20px"
                          width="20px"
                          color="white"
                          ariaLabel="loading"
                          />
                          ) : (
                            "Delete"
                          )} */}
                            Delete
                          </button>
                        </div>
                      </TD>
                    </TR>
                  );
                })}
              </TBody>
            ) : (
              <p className="text-xl text-gray-300 font-[600]  border-none  mt-24 text-right">
                No Auctions To Display
              </p>
            )}
          </Tables>
        )}
      </div>

      <DeleteAuctionModal
        show={show}
        setShow={setShow}
        title="Do Want to Delete?"
        id={id}
      />
    </div>
  );
};

export default Auctions;
