import { BUSINESSES } from "../types";
import produce from "immer";
import BusinessesController from "../../Controllers/businessesController";
const state = {
    businesses: [], 
    businessesLoading: false,
    businessCount: {
        pendingCount: 0, 
        approvedCount: 0, 
        allCount: 0, 
        blockedCount: 0
    }
}

const BusinessesReducer = (mState = {...state}, action) => {
    switch(action.type){
        case BUSINESSES.SET_ALL:
            return produce(mState, draftState => {
                draftState.businesses = action.payload;
                draftState.businessCount.allCount = draftState.businesses.length 
                draftState.businessCount.approvedCount = draftState.businesses.filter(b => b.payload.verificationStatus === BusinessesController.APPROVAL_STATUS.APPROVED).length 
                draftState.businessCount.pendingCount = draftState.businesses.filter(b => b.payload.verificationStatus === BusinessesController.APPROVAL_STATUS.PENDING).length 
                draftState.businessCount.blockedCount = draftState.businesses.filter(b => b.payload.verificationStatus === BusinessesController.APPROVAL_STATUS.BLOCKED).length 
            })
        case BUSINESSES.SET_KEY_VALUE:
            return produce(mState, draftState => {
                draftState[action.payload.key] = action.payload.value;
            }) 
        case BUSINESSES.SET_APPROVAL_STATUS:
            return produce(mState, draftState => {
                let { businessId, approvalStatus } = action.payload;
                let businessIndex = draftState.businesses.findIndex(b => b.payload._id === businessId);
                if(businessIndex >= 0){
                    draftState.businesses[businessIndex].payload.verificationStatus = approvalStatus
                    draftState.businesses[businessIndex].actions = approvalStatus;


                    // console.log("Updated", draftState.businesses[businessIndex])

                    draftState.businessCount.allCount = draftState.businesses.length 
                    draftState.businessCount.approvedCount = draftState.businesses.filter(b => b.payload.verificationStatus === BusinessesController.APPROVAL_STATUS.APPROVED).length 
                    draftState.businessCount.pendingCount = draftState.businesses.filter(b => b.payload.verificationStatus === BusinessesController.APPROVAL_STATUS.PENDING).length 
                    draftState.businessCount.blockedCount = draftState.businesses.filter(b => b.payload.verificationStatus === BusinessesController.APPROVAL_STATUS.BLOCKED).length 

                }else{
                    console.log(`Invalid Business ID: ${businessId}`);
                }
            })
        case BUSINESSES.UPDATE_PAYABLES:
            return produce(mState, draftState => {
                let { businessId, amount } = action.payload;
                let businessIndex = draftState.businesses.findIndex(b => b.payload._id === businessId);
                if(businessIndex >= 0){
                    draftState.businesses[businessIndex].payload.payable = amount
                    draftState.businesses[businessIndex].recievable = `$${amount}`;
                    console.log(`Business Updated.`,draftState.businesses[businessIndex] )
                }else{
                    console.log(`Invalid Business ID: ${businessId}`);
                }
            })
        default:
            return {...mState};
    }
}
export default BusinessesReducer;