import React, { useEffect, useState } from "react";
import { Buttons } from "../../../Components/AbstractsComponents/Button/button";
import BusinessesController from "../../../Controllers/businessesController";
//     importing css files
import "./walletModal.css";

export const SetUpPaymentAmountModal = ({ business, closeModal }) => {
  const [recieveAbleInputValue, setRciveableInput] = useState(
    business?.payable || 0
  );
  const [processing, setProcessing] = useState(false);

  const handleAddPayment = () => {
    console.log("Reciveable....", recieveAbleInputValue);
    if (!recieveAbleInputValue > 0) {
      return;
    }
    console.log(`paying ${recieveAbleInputValue} to ${business._id}`);
    setProcessing(true);
    BusinessesController.markPaymentAsRecieved(
      business._id,
      recieveAbleInputValue,
      business.payable
    )
      .then((marked) => {
        setProcessing(false);
        closeModal();
      })
      .catch((err) => {
        setProcessing(false);
      });
  };

  return (
    <div className="set-up-payment-modal-div">
      <div style={{ textAlign: "center" }}>
        <h4>
          Total Payable : <span>${business.payable}</span>
        </h4>
      </div>
      <div className="set-up-dapyment-method-input-div add-funds-div">
        <div style={{ width: "100%" }}>
          <input
            modal
            style={{
              width: "421px",
              fontSize: "30px",
              borderRadius: "8px",
              padding: "10px 10px",
              backgroundColor: "white",
              boxShadow: "0px 4px 8px 0px grey",
              border: "none",
            }}
            placeholder="Add Recieveable Amount"
            // defaultValue={business.payable}
            value={recieveAbleInputValue}
            type={"number"}
            onChange={(e) => setRciveableInput(parseInt(e.target.value))}
          />
          {recieveAbleInputValue > business.payable ? (
            <p style={{ position: "absolute", color: "red" }}>
              Value Must be Equal to or less than {business.payable}
            </p>
          ) : (
            ""
          )}
        </div>
        <div style={{ marginTop: "30px" }}>
          {recieveAbleInputValue > business.payable ? (
            false
          ) : (
            <Buttons
              processing={processing}
              disableBtn={
                business.payable === 0 ||
                recieveAbleInputValue <= 0 ||
                recieveAbleInputValue === ""
              }
              onClick={() => handleAddPayment()}
              style={{
                width: "100%",
                height: "60px",
                fontSize: "18px",
                boxShadow: "none",
                lineHeight: "55px",
              }}
            >
              Mark as Paid
            </Buttons>
          )}
        </div>
      </div>
    </div>
  );
};
