import React from "react";
import { MdMenu } from "react-icons/md";
//     importing components
import SearchIcon from "../../../Assests/Icons/Search.svg";
import { Buttons } from "../../AbstractsComponents/Button/button";
import ReduxDispatchController from "../../../Controllers/reduxDispatchController";
import {
  DropDownsPop,
  DropDownItems,
  DropDownMenu,
} from "../../../Components/AbstractsComponents/DropDown/dropDown";
import { Link } from "react-router-dom";
//     importing css files
import "./userNavBar.css";
import AuthController from "../../../Controllers/authController";
export const UserNavBar = ({ setShow, show }) => {
  return (
    <React.Fragment>
      <div className="user-navbar-main-div">
        <div className="user-navbar-inner-div !flex !justify-between lg:!justify-end">
          {/*<div className="user-nav-serach-div">
          //    <img src={SearchIcon} className="search-icon" alt="" />
          //   <input
          //     type="text"
          //     className="searc-anything-input"
          //     placeholder="Search anything"
          //   /> 
            
  // </div>*/}
          <button
            // onClick={() => setShow(!show)}
            className=" text-3xl w-[70px] pl-3  flex items-center justify-center text-blue-600 lg:hidden"
          >
            <MdMenu />
          </button>
          <div>
            {/* <img src={} className='user-nav-bell-icon' /> */}
            <Link to="/" className="react-router-link">
              <Buttons
                onClick={AuthController.logout}
                style={{ width: "130px", height: "40px" }}
              >
                Log out
              </Buttons>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
