import React, { useState } from 'react';
import SlidePanel from '../../../AbstractsComponents/SlidingPane/slidingPane';
export const VehicleSlidingPaneBtn = ({vehicle}) =>{
    const [visible,setVisible] = useState(false)
    return(
        <div>
            <a className='slide-buyer-open-button' onClick={()=>setVisible(true)}>{vehicle.title}</a>
            <SlidePanel width={"40%"} visible={visible} title={vehicle.title} onClose={()=>setVisible(false)}>
                <div>
                    <p>Some details of buisness</p>
                </div>
            </SlidePanel>
        </div>
    )
}