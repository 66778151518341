import React from "react";
//    importing compoents
import { WelcomeToInfo } from "../../Components/ModuleComponents/AuthComponents/WelcomeToInfo/welcomeToInfo";
import { SignIn } from "../../Components/ModuleComponents/AuthComponents/SignIn/signIn";
//     importing css files
import "./signInScreen.css";
export const SignInScreen = () => {
  return (
    <div className="sign-in-view-main-div">
      <div className="sign-in-image-div">
        <WelcomeToInfo />
      </div>
      <div className="sign-in-info-div">
        <SignIn />
      </div>
    </div>
  );
};
