export const formatCurrency = (value) => {
  if (value) {
    let v = value.toFixed(2);
    let formattedOutput = new Intl.NumberFormat("en-US", {
      // style: "currency",
      currency: "USD",
    }).format(v);
    return formattedOutput;
  }
};
