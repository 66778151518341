import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import {
  AddTagInputField,
  TextFields,
} from "../../../Components/AbstractsComponents/InputFields";
import { updateVehicleValidationSchema } from "../../../Validations/updateVehicle";
import UploadImgCardV2 from "../../../Components/AbstractsComponents/ImageUploader/UploadImgCardV2";
import { TailSpin } from "react-loader-spinner";
import DialogueBox from "../../../Components/AbstractsComponents/DialogueBox";
import { useCallbackPrompt } from "../../../Hooks/useCallbackPrompt";
import { data } from "autoprefixer";
// import VehiclesController from "../../modules/vehicles";

const VehicleForm = ({
  onSubmit,
  className,
  loading,
  isEdit,
  initialValues,
}) => {
  const formFlag = useRef(false);
  const imgFlag = useRef(false);

  const [deletedImages, setDeletedImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [validationErr, setValidationErr] = useState("");

  const [dataUpdated, setDataUpdated] = useState({
    images: false,
    data: false,
  });

  const [showDialogue, setShowDialogue] = useState(false);

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialogue);

  //   const handleImages = (data) => {
  //     setValues({ ...values, images: [...data] });
  //   };

  const checkTernary = (x) => {
    return x ? x : "";
  };

  useEffect(() => {
    if (dataUpdated.data || dataUpdated.images) {
      setShowDialogue(true);
    }
  }, [dataUpdated.data, dataUpdated.images]);

  useEffect(() => {
    if (isEdit) {
      setValues({
        ...values,
        make: checkTernary(initialValues?.make),
        modal: checkTernary(initialValues?.modal),
        transmission: checkTernary(initialValues?.transmission),
        engine: checkTernary(initialValues?.engine),
        // vehicleType: checkTernary(initialValues?.vehicleType),
        interior: checkTernary(initialValues?.interior),
        driveTrain: checkTernary(initialValues?.driveTrain),
        mileageCity: checkTernary(initialValues?.mileageCity),
        mileageHighway: checkTernary(initialValues?.mileageHighway),
        description: checkTernary(initialValues?.description),
        year: checkTernary(initialValues?.year),
        trimLevel: checkTernary(initialValues?.trimLevel),
        vehicleSellingStatus: checkTernary(initialValues?.vehicleSellingStatus),
        title: checkTernary(initialValues?.title),
        style: checkTernary(initialValues?.style),
        madeIn: checkTernary(initialValues?.madeIn),
        fuleType: checkTernary(initialValues?.fuleType),
        exterior: checkTernary(initialValues?.exterior),
        driveType: checkTernary(initialValues?.driveType),
        KMDriven: checkTernary(initialValues?.KMDriven),
        VIN: checkTernary(initialValues?.VIN),
        priceMin: checkTernary(initialValues?.priceRange?.min),
        priceMax: checkTernary(initialValues?.priceRange?.max),
        options: checkTernary(initialValues?.knownIssues, []),
      });
    }
  }, [initialValues]);

  const { handleSubmit, touched, errors, getFieldProps, setValues, values } =
    useFormik({
      initialValues: {
        make: "",
        modal: "",
        transmission: "",
        engine: "",
        // vehicleType: "",
        mileageCity: "",
        mileageHighway: "",
        interior: "",
        driveTrain: "",
        description: "",
        year: "",
        trimLevel: "",
        vehicleSellingStatus: "",
        title: "",
        style: "",
        madeIn: "",
        fuleType: "",
        exterior: "",
        driveType: "",
        KMDriven: "",
        VIN: "",
        priceMin: "",
        priceMax: "",
        isTouchedData: false,
        isTouchedImg: false,
        options: [],
        optionValue: "",
      },

      validationSchema: isEdit ? updateVehicleValidationSchema : null,

      onSubmit: (d) => {
        console.log("Data onSubmit......", d);
        setShowDialogue(false);
        let finalDAta = {
          ...d,
          images: newImages,
          deletedImages: deletedImages,
          isTouchedData: dataUpdated.data,
          isTouchedImg: dataUpdated.images,
        };

        console.log("Final Data.......", finalDAta);

        // if (!isEdit && finalDAta.images.length <= 0) {
        //   setValidationErr("Upload Images");
        //   return;
        // }
        onSubmit(finalDAta);
      },
    });

  const onDeleteImage = (image) => {
    if (!image.avatar.raw) {
      setDeletedImages([...deletedImages, image]);
    } else {
      let updatedImgs = newImages.filter((img) => img._id !== image._id);
      setNewImages(updatedImgs);
    }
  };

  const onAddNewImage = (image) => {
    console.log(image, "ON ADD..............................");
    setValidationErr(null);
    setNewImages([...image]);
  };

  useEffect(() => {
    if (!formFlag.current && isEdit && !!initialValues) {
      setTimeout(() => {
        formFlag.current = true;
        imgFlag.current = true;
      }, 1000);
    }
  }, [initialValues]);

  useEffect(() => {
    if (formFlag.current) {
      setDataUpdated({ ...dataUpdated, data: true });
      formFlag.current = false;
    }
  }, [values]);

  useEffect(() => {
    if (newImages.length > 0) {
      setDataUpdated({ ...dataUpdated, images: true });
    } else if (deletedImages.length > 0) {
      setDataUpdated({ ...dataUpdated, images: true });
    } else {
      setDataUpdated({ ...dataUpdated, images: false });
    }
    console.log(deletedImages, "@all deleted imgs");
    console.log(newImages, "@all  new imgs");
  }, [deletedImages, newImages]);

  return (
    <div>
      <div className={`  py-5 flex flex-col gap-3 ${className}`}>
        <form onSubmit={handleSubmit} className="flex flex-col w-full">
          <div className="flex justify-between gap-5">
            <button
              onClick={() => showDialogue(false)}
              disabled={
                isEdit
                  ? !dataUpdated.images && !dataUpdated.data
                    ? true
                    : loading
                  : loading
              }
              type="submit"
              class="inline-flex justify-center disabled:cursor-not-allowed min-w-[130px] my-4 ml-auto items-center rounded-md border border-transparent bg-primary px-2 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            >
              {loading ? (
                <TailSpin
                  height="20px"
                  width="20px"
                  color="white"
                  ariaLabel="loading"
                />
              ) : isEdit ? (
                "Update Vehicle"
              ) : (
                "Create Vehicle"
              )}
            </button>
          </div>
          <div className="grid w-full grid-cols-1 lg:grid-cols-[1.5fr_3fr] gap-5">
            <div className="relative">
              <UploadImgCardV2
                onDeleteImage={onDeleteImage}
                onAddNewImage={onAddNewImage}
                validationErr={validationErr}
                formikError={
                  <span className="text-[#CC2936]   left-2 text-[11px] -bottom-5 ">
                    {touched["images"] && errors["images"]}
                  </span>
                }
                // onChange={handleImages}
                initialImagesUrl={checkTernary(initialValues?.pictures)}
              />
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex flex-col justify-between gap-3 md:flex-row ">
                <h1 className="text-lg font-bold text-[#566589]">
                  Vehicle Information
                </h1>
              </div>
              <div className="grid w-full grid-cols-2 gap-4">
                <div className="relative">
                  <TextFields
                    placeholder={`Enter Make`}
                    name={`make`}
                    label={`Make`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`make`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["make"] && errors["make"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Enter Modal`}
                    name={`modal`}
                    label={`Modal`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`modal`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["modal"] && errors["modal"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Enter transmission`}
                    name={`transmission`}
                    label={`Transmission`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`transmission`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["transmission"] && errors["transmission"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Enter Engine`}
                    name={`engine`}
                    label={`Engine`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`engine`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["engine"] && errors["engine"]}
                  </span>
                </div>

                {/*<div className="relative ">
                  <TextFields
                     placeholder={`Enter Vehicle Type`}
                     name={`vehicleType`}
                     label={`Vehicle Type`}
                     className={`px-2 py-2 border border-gray-200`}
                     {...getFieldProps(`vehicleType`)}
                   />
                   <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                     {touched["vehicleType"] && errors["vehicleType"]}
                  </span>
               </div> */}

                <div className="relative ">
                  <TextFields
                    placeholder={`Enter Year`}
                    name={`styearckNumber`}
                    type={`number`}
                    label={`Year`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`year`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["year"] && errors["year"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Enter Interior`}
                    name={`interior`}
                    label={`Interior`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`interior`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["interior"] && errors["interior"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Enter Drive Train`}
                    name={`driveTrain`}
                    label={`Drive Train`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`driveTrain`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["driveTrain"] && errors["driveTrain"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Enter Fuel Economy in City`}
                    name={`mileageCity`}
                    type={`number`}
                    label={`Mileage  City`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`mileageCity`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5">
                    {touched["mileageCity"] && errors["mileageCity"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Enter Fuel Economy on Highway`}
                    name={`mileageHighway`}
                    type={`number`}
                    label={`Mileage Highway`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`mileageHighway`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["mileageHighway"] && errors["mileageHighway"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Minimum Price Range`}
                    name={`priceMin`}
                    type={`number`}
                    label={`Minimum Price Range`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`priceMin`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["priceMin"] && errors["priceMin"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Maximum Price Range`}
                    name={`priceMax`}
                    type={`number`}
                    label={`Maximum Price Range`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`priceMax`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["priceMax"] && errors["priceMax"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Enter Trim Level`}
                    name={`trimLevel`}
                    type={`string`}
                    label={`Trim Level`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`trimLevel`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["trimLevel"] && errors["trimLevel"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Vehicle Selling Status`}
                    name={`vehicleSellingStatus`}
                    type={`string`}
                    label={`Selling Status`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`vehicleSellingStatus`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["vehicleSellingStatus"] &&
                      errors["vehicleSellingStatus"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Title`}
                    name={`title`}
                    type={`string`}
                    label={`Title`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`title`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["title"] && errors["title"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Style`}
                    name={`style`}
                    type={`string`}
                    label={`Style`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`style`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["style"] && errors["style"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Made In`}
                    name={`madeIn`}
                    type={`string`}
                    label={`Made In`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`madeIn`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["madeIn"] && errors["madeIn"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Fuel Type`}
                    name={`fuleType`}
                    type={`string`}
                    label={`Fuel Type`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`fuleType`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["fuleType"] && errors["fuleType"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Exterior`}
                    name={`exterior`}
                    type={`string`}
                    label={`Exterior`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`exterior`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["exterior"] && errors["exterior"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`Drive Type`}
                    name={`driveType`}
                    type={`string`}
                    label={`Drive Type`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`driveType`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["driveType"] && errors["driveType"]}
                  </span>
                </div>
                <div className="relative ">
                  <TextFields
                    placeholder={`KM Driven`}
                    name={`KMDriven`}
                    type={`number`}
                    label={`KM Driven`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`KMDriven`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["KMDriven"] && errors["KMDriven"]}
                  </span>
                </div>
                <div className="relative col-span-2">
                  <TextFields
                    placeholder={`VIN`}
                    name={`VIN`}
                    type={`string`}
                    label={`VIN`}
                    className={`px-2 py-2 border border-gray-200`}
                    {...getFieldProps(`VIN`)}
                  />
                  <span className="text-[#CC2936] absolute left-2 text-[11px] -bottom-5 ">
                    {touched["VIN"] && errors["VIN"]}
                  </span>
                </div>

                <div className="relative">
                  <AddTagInputField
                    label={`Known Issues`}
                    className={`py-1 px-3`}
                    options={values?.options}
                    name={`optionValue`}
                    {...getFieldProps(`optionValue`)}
                    placeholder={"add known issues"}
                    onDeleteItem={(e) => {
                      let filterValue = values.options?.filter(
                        (_, i) => e !== i
                      );
                      setValues({ ...values, options: filterValue });
                    }}
                    onAddItem={(e) =>
                      setValues({
                        ...values,
                        options: [...values.options, e],
                        optionValue: "",
                      })
                    }
                    onUpdateItem={(value, index) => {
                      let optionsCopy = [...values.options];
                      optionsCopy[index] = value;

                      if (index >= 0) {
                        setValues({
                          ...values,
                          options: optionsCopy,
                          optionValue: "",
                        });
                      }
                    }}
                    onEdit={(e) => setValues({ ...values, optionValue: e })}
                  />
                </div>

                <div className="relative">
                  <label className="w-full text-sm font-bold text-left ">
                    Description
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    rows={2}
                    className="block w-full min-h-[134px] max-w-lg p-3 bg-transparent border-2  border-gray-300 rounded-md  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    defaultValue={""}
                    {...getFieldProps(`description`)}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <DialogueBox
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        show={showPrompt}
      />
    </div>
  );
};

export default VehicleForm;
