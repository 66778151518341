import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { MdKeyboardArrowDown } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoAddCircleOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";

// import { useStore, setStore } from "../../store/carData";

export const TextFields = ({
  label,
  name,
  type,
  placeholder,
  onChange,
  labelClass,
  className,
  ...rest
}) => {
  return (
    <div className="flex flex-col text-sm sm:text-base">
      <label
        className={`text-dark1 font-bold text-sm mb-[2px] text-left ${labelClass}`}
        htmlFor={name}
      >
        {label}
        <span> *</span>
      </label>
      <input
        className={`block w-full h-12 rounded-md bg-white border-2 border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${className}`}
        type={type}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};

export const AddTagInputField = ({
  label,
  name,
  type,
  placeholder,
  onChange,
  labelClass,
  className,
  options,
  value,
  onAddItem,
  onDeleteItem,
  onUpdateItem,
  onEdit,
  ...rest
}) => {
  const [editIndex, setEditIndex] = useState(undefined);

  const handleEdit = (value, index) => {
    onEdit(value);
    setEditIndex(index);
  };

  const handleAddUpdate = () => {
    if (editIndex >= 0 && onUpdateItem && value !== "") {
      onUpdateItem(value, editIndex);
      setEditIndex(undefined);
    } else {
      onAddItem && value !== "" && onAddItem(value);
    }
  };

  return (
    <div className="flex flex-col text-sm sm:text-base w-full">
      <label
        className={`text-dark1 font-bold text-sm text-left uppercase mb-[2px] ${labelClass}`}
        htmlFor={name}
      >
        {label}
        <span> *</span>
      </label>

      <div className="border-2 border-gray-300 border-solid rounded-md overflow-hidden flex flex-col items-center">
        <div className="relative w-full">
          <input
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleAddUpdate();
              }
            }}
            className={`block w-full  border-b border focus:outline-none shadow-none !pr-6 ${className}`}
            type={type}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            {...rest}
          />
          <button
            type="button"
            className="absolute inset-y-0 my-auto h-full px-2 right-0 flex justify-center items-center bg-green-700 text-white"
            onClick={handleAddUpdate}
          >
            {editIndex >= 0 ? "Update" : "Add"}
          </button>
        </div>

        <ul className="h-[100px] w-full overflow-y-auto py-2 !px-0 flx flex-col gap-1">
          {options?.length > 0 &&
            options?.map((item, index) => {
              return (
                <li
                  className="px-2 relative w-full flex items-center justify-between gap-1"
                  key={index}
                >
                  <p className="text-xs text-dark1">{item}</p>

                  <div className="flex gap-1">
                    <button
                      type="button"
                      onClick={() => handleEdit(item, index)}
                    >
                      <FiEdit className="text-sm" />
                    </button>
                    <button
                      type="button"
                      onClick={() => onDeleteItem && onDeleteItem(index)}
                    >
                      <AiFillCloseCircle className="text-red-400 text-sm " />
                    </button>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export const SelectField = ({
  label,
  name,
  type,
  placeholder,
  options,
  value,
  onChange,
  setValue,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    options ? options[0]?.title : ""
  );
  const [showOption, setShowOption] = useState(false);

  // // get store options and set it to the state
  // const storeData = useStore((state) => state);

  // // set store data
  // const setStoreData = (data) => {
  //   setStore(data);
  // };

  return (
    // <OutsideClickHandler onOutsideClick={() => setShowOption(false)}>
    //   <div className="relative flex flex-col w-full text-sm gap-y-1 sm:text-base">
    //     <label className=" font-lato_semibold" htmlFor={name}>
    //       {label}
    //       <span className="text-primary"> *</span>
    //     </label>
    //     <div className="relative ">
    //       <div
    //         onClick={() => setShowOption(!showOption)}
    //         className="bg-n2 py-[13px] items-center flex justify-between cursor-pointer sm:px-5 px-2 outline-none w-full rounded-[10px] placeholder:text-n1"
    //       >
    //         {value.title ? (
    //           value.title
    //         ) : (
    //           <span style={{ color: "#ADB5BD" }}>{placeholder}</span>
    //         )}
    //         <MdKeyboardArrowDown />
    //       </div>
    //       <div
    //         className={`${
    //           showOption
    //             ? "block top-14 z-10 "
    //             : " -z-[999] hidden top-0 opacity-0"
    //         } absolute transition-all duration-300 bg-white shadow-2xl rounded-[10px] py-2 max-h-[200px] w-full overflow-y-auto`}
    //       >
    //         {options?.map((ls, index) => {
    //           const { title } = ls;
    //           return (
    //             <div
    //               key={index}
    //               onClick={() => {
    //                 setValue(ls);
    //                 setShowOption(false);
    //               }}
    //               className="px-5 py-1 cursor-pointer hover:bg-n2"
    //             >
    //               {title}
    //             </div>
    //           );
    //         })}
    //       </div>
    //     </div>
    //     {/* <select placeholder={placeholder}>{children}</select> */}
    //   </div>
    // </OutsideClickHandler>
    <div className="relative flex flex-col w-full text-sm gap-y-1 sm:text-base">
      <label className=" font-lato_semibold" htmlFor={name}>
        {label}
        <span className="text-dark1"> *</span>
      </label>
      <div className="relative sm:px-5 px-2 bg-n2 rounded-[10px]">
        <select
          className={` bg-n2 after:content-["&#8964;"] outline-none py-[13px]  w-full  ${
            value !== "" ? "" : "text-n1"
          }`}
          onChange={(e) => {
            let option = options.find((o) => o.title === e.target.value);
            if (option) {
              setValue({ id: option.id, title: option.title });
            } else {
              setValue({ id: "", title: e.target.value });
            }
          }}
          defaultValue={value.title}
          value={value.title ? value.title : ""}
          size={1}
          name={name}
        >
          {/* {placeholder && ( */}
          <option className=" placeholder" selected>
            {placeholder ? placeholder : "Select Field"}
          </option>
          {/* )} */}
          {options?.map((ls, index) => {
            const { title } = ls;
            return (
              <option
                // value={ls.id}

                // label={ls.title}
                // id={ls.id}
                key={index}
                // onClick={() => {
                //   setValue(ls);
                //   setShowOption(false);
                // }}
                className="cursor-pointer !hover:bg-n2 text-black "
                // style={{ color: "black", padding: "10px" }}
              >
                <span> {title}</span>
              </option>
            );
          })}
        </select>
        {/* <MdKeyboardArrowDown className="absolute inset-y-0 my-auto right-5" /> */}
      </div>
    </div>
  );
};
export const SelectOption = ({ children }) => {
  return <option>{children}</option>;
};
export const SelectFieldV2 = ({
  label,
  options,
  value,
  onChange,
  placeholder,
  defaultValue,
  className,
  loading,
  containerClass,
  icon,
  isRequired,
  ...rest
}) => {
  const [outline, setOutLine] = useState(false);

  return (
    <div className={`relative ${className}`}>
      <label
        htmlFor={rest.id}
        className="block !text-sm font-medium text-neutral-900"
      >
        {label}
        {isRequired && <span className="text-r1">*</span>}
      </label>
      <div
        className={`relative flex rounded-[30px] justify-between  
        items-center w-full !py-[0px] !bg-white 2xl:!min-h-[50px] !min-h-[40px] !overflow-hidden  !border-[#F1F2F4] !border !gap-x-2 2xl:!gap-x-3 
        ${outline && !loading ? "!border-blue-600" : ""}
      ${!icon && "!pl-3 !py-[12px]"}
      ${containerClass}`}
      >
        <select
          {...rest}
          value={value}
          onChange={onChange}
          disabled={loading}
          className={`!bg-white 2xl:!text-base !h-full hover:!cursor-pointer !text-sm after:!content-["&#8964;"] !outline-none !w-full ${
            value !== "" ? "" : "text-neutral-900"
          } ${className}`}
          onFocus={() => setOutLine(true)}
          onBlurCapture={() => setOutLine(false)}
        >
          {placeholder && (
            <option value="" disabled selected>
              {placeholder}
            </option>
          )}
          {defaultValue && <option value={``}>{defaultValue}</option>}
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        {/* {loading && (
          <div className="absolute rounded-[30px]  w-full h-full top-0 left-0 flex items-center justify-center pr-1 bg-gray1 pointer-events-none">
            <Loading className="text-grayLight2" size={15} loading={true} />
          </div>
        )} */}
      </div>
    </div>
  );
};
