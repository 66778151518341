export const AUTH = {
  SET_SPLASH_VISIBLE: "SET_SPLASH_VISIBLE",
  SET_CURRENT_USER: "SET_CURRENT_USER",
};

export const BUSINESSES = {
  SET_ALL: "SET_ALL",
  SET_KEY_VALUE: "SET_KEY_VALUE_BUSINESSES",
  SET_APPROVAL_STATUS: "SET_BUSINESS_APPROVA_STATUS",
  UPDATE_PAYABLES: "UPDATE_BUSINESS_PAYABLES",
};

export const TRANSACTIONS = {
  SET_ALL: "SET_ALL_TRANSACTIONS",
  SET_KEY_VALUE: "SET_TRANSACTIONS_KEY_VALUE",
  SET_STATUS: "SET_TRANSACTIONS_STATUS",
};

export const BUYERS = {
  SET_ALL: "SET_ALL_BUYERS",
};

export const AUCTIONS = {
  SET_AUCTIONS: "SET_AUCTIONS",
  ADD_NEW_VEHICLE: "ADD_NEW_VEHICLE",
  SET_SINGLE_AUCTION: "SET_SINGLE_AUCTION",
  SET_NEW_AUCTION: "SET_NEW_AUCTION",
  SET_UPDATE_AUCTION: "SET_UPDATE_AUCTION",
  DELETE_SINGLE_AUCTION: "DELETE_SINGLE_AUCTION",
  DELETE_SINGLE_AUCTION_VEHICLE: "DELETE_SINGLE_AUCTION_VEHICLE",
};

export const VEHICLES = {
  SET_ALL_VEHICLES: "SET_ALL_VEHICLES",
  SET_UPDATED_DATA: "SET_UPDATED_DATA",
  DELTE_VEHICLE: "DELTE_VEHICLE",
};

export const OFFERS = {
  SET_ALL_OFFERS: "SET_ALL_OFFERS",
  SET_UPDATE_OFFER: "SET_UPDATE_OFFER",
};

export const METADATA = {
  SET_ALL_METADATA: "SET_ALL_METADATA",
};
