//    importing components
import { Routing } from "./Routing/routing";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import AuthController from "./Controllers/authController";
import { useEffect } from "react";
import BusinessesController from "./Controllers/businessesController";
import { useMetaData } from "./Controllers/utilityController";

function App() {
  const { loading, make } = useMetaData();
  console.log(make, "@make..!");
  const user = AuthController.getCurrentUser();
  console.log(user, "@auth user in app");
  if (user === null) {
    AuthController.restorePresistedCredencials();
  }

  return (
    <div className="App" style={{ height: "50vh" }}>
      <Routing />
    </div>
  );
}

export default App;
