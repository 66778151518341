import React, { useRef } from "react";
import Popup from "reactjs-popup";
import BusinessesController from "../../../Controllers/businessesController";
//     importing css file
import "./dropDown.css";
export const DropDownsPop = (props) => {
  const ref = useRef();
  const Items=[
    BusinessesController.APPROVAL_STATUS.APPROVED,
    BusinessesController.APPROVAL_STATUS.PENDING,
    BusinessesController.APPROVAL_STATUS.BLOCKED,
  ]
  const closeTooltip = () => ref.current.close();
  // const Processing=(li)=>{
  //   // props.onClick(li)
  //   // props.onChange(true)
  //   setInterval(() => {props.onChange(false)}, 2000);
  // }

  const handleSelect = (li) => {
    closeTooltip();
    if(props.onChange) props.onChange(li);
  }


  return (
    <Popup
      trigger={
        <div className="menu-items">
          <img
            src={props.heading}
            style={{ margin: "7px 0px 0px 0px" }}
            width={props.width}
            height={props.height}
            alt=""
          />
        </div>
      }
      position="bottom right"
      closeOnDocumentClick={false}
      on="click"
      mouseLeaveDelay={0}
      mouseEnterDelay={0}
      contentStyle={{ padding: "0px", border: "none" }}
      arrow={false}
      ref={ref}
      nested
      style={props.style}
      disabled={props.Processing?true:false}
    >
      <div className={props.className ? "menu " + props.className : "menu"}>
        {Items.map(li=>(<div
          className={
            props.className ? "menu-item " + props.className : "menu-item"
          }
          style={props.style}
          onClick={(e)=> handleSelect(li)}
        >
          {li}
        </div>))}
      </div>
    </Popup>
  );
};
// export const DropDownMenu = (props) => {
//   return (
//     <div
//       className={props.className ? "menu " + props.className : "menu"}
//       style={props.style}
//     >
//       {props.children}
//     </div>
//   );
// };
// export const DropDownItems = (props) => {
//   return (
//     <div
//       className={props.className ? "menu-item " + props.className : "menu-item"}
//       style={props.style}
//       onClick={props.onClick}
//     >
//       {props.children}
//     </div>
//   );
// };
