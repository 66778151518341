import React from "react";
//     importing icons
//     importing css files
import "./textFields.css";
export const TextFields = (props) => {
  const myArray = props.textIconArr;

  return (
    <div className={`text-input-main-div`}>
      <label htmlFor={props.id}>{props.label}</label>
      <br />
      <input
        value={props.value}
        id={props.id}
        type={props.type}
        onChange={props.onChange}
        onBlur={props.onBlur}
        name={props.name}
        placeholder={props.placeholder}
        className={`text-fileds-input`}
        style={props.style}
        min={props.min}
      />
      {props.textIconArr ? (
        <div
          className={
            props.modal ? "text-modal-input-icons" : "text-input-icons"
          }
        >
          {myArray.map((ls) => (
            <img src={ls} alt="" />
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export const FormTextField = (props) => {
  return (
    <div>
      <label className="add-vehicle-label" htmlFor={props.id}>
        {props.label}
      </label>
      <input
        value={props.value}
        type={props.type}
        id={props.id}
        className="add-vehicle-input"
        onChange={props.onChange}
        onBlur={props.onBlur}
        name={props.name}
        placeholder={props.placeholder}
      />
    </div>
  );
};
export const FormSelectField = (props) => {
  return (
    <div
      style={{ position: "relative", display: "flex", flexDirection: "column" }}
    >
      <label className="add-vehicle-label" htmlFor={props.id}>
        {props.label}
      </label>
      <select
        className="add-vehicle-input"
        value={props.value}
        type={props.type}
        id={props.id}
        onChange={props.onChange}
        onBlur={props.onBlur}
        name={props.name}
      >
        {props.children}
      </select>
      {/* <img src={SelectIcon} className="select-filed-arrow" /> */}
    </div>
  );
};
export const FormSelectFieldOption = (props) => {
  return <option>{props.children}</option>;
};
