import React, { useEffect, useState } from "react";

//     importing componants
import { useLocation, Outlet, Navigate } from "react-router-dom";
// import { Row, Col, Container } from "react-bootstrap";
import { SideBar } from "../Components/ModuleComponents/SideBar/sideBar";
import { UserNavBar } from "../Components/ModuleComponents/UserNavBar/userNavBar";
//    importing css files
import "./dashBoardRouting.css";
import { useSelector } from "react-redux";
import BusinessesController from "../Controllers/businessesController";
export const DashBoardRouting = () => {
  const [show, setShow] = useState(false);
  // const location = useLocation();

  //destructuring pathname from location
  // const { pathname } = location;
  const getBuyers = async () => {
    const res = await BusinessesController.getBuyers();
    console.log(res, "@buyers....");
  };

  useEffect(() => {
    getBuyers();
  }, []);
  //Javascript split method to get the name of the path in array
  const currentUser = useSelector((state) => state.AuthReducer.currentUser);
  console.log(currentUser, "@current user");

  // const splitLocation = pathname.split("/");
  // console.log(splitLocation);

  if (currentUser) {
    return (
      <div>
        <div style={{ display: "flex", position: "sticky", top: "0px" }}>
          <div className=" side-bar-main-div">
            <SideBar show={show} />
          </div>
          <div
            xl={10}
            lg={10}
            md={10}
            sm={12}
            xs={12}
            className="flex-1 w-full dash-board-table-colum-div "
          >
            <div className="w-full">
              <UserNavBar />
            </div>
            <div
              className="dashboard-col-table-col-inner-div"
              style={{
                top: "0px",
                height: "calc(100vh - 70px)",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to={"/auth"} />;
  }
};
