import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useVehicles } from "../../../Controllers/vehicleController";
import BreadCrumb from "../../../Components/AbstractsComponents/BreadCrumb";
import VehicleForm from "./VehicleForm";
import VehiclController from "../../../Controllers/vehicleController";
import AuctionController from "../../../Controllers/auctionController";
import Modal from "../../../Components/AbstractsComponents/Modal2/Modal";
import BuyNow from "./BuyNow";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import classNames from "classnames";
import {
  Tables,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from "../../../Components/AbstractsComponents/Tables";
import { formatCurrency } from "../../../Utils/helperFunctions";
import { useSelector } from "react-redux";
import KnownIssues from "./KnownIssues";
import Offers from "./Offers";
const tabsData = [
  {
    // content: renderDetails(),
    title: "Details",
    current: true,
  },
  {
    // content: renderBids(),
    title: "Bids",
    current: false,
  },
  {
    // content: renderBids(),
    title: "Known Issues",
    current: false,
  },
  {
    // content: renderBids(),
    title: "Buy Now Offers",
    current: false,
  },
  {
    title: "Offers",
    current: false,
  },
];
const vehicleTabsData = [
  {
    // content: renderDetails(),
    title: "Details",
    current: true,
  },
  {
    // content: renderBids(),
    title: "Offers",
    current: false,
  },
];
const ModifyVehicle = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const vehicleId = params.get("id");
  const auctionVehicleId = params.get("auctionVehicleId");
  const isEditMode = vehicleId || auctionVehicleId;

  const vehiclesData = useSelector((state) => state.VehicleReducer.vehicles);
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [bids, setbids] = useState([]);
  const [Bidsloading, setBidsLoading] = useState(false);
  const [Tabs, setTabs] = useState(
    auctionVehicleId ? tabsData : vehicleTabsData
  );
  const [filterVehicle, setFilterVehicle] = useState(null);
  const [loadingVehcile, setLoadingVehicle] = useState(false);
  const [lastBid, setLastBid] = useState(0);
  const [isTouched, setIsTouched] = useState(false);
  const [save, setSave] = useState(false);

  console.log("bids data......", bids);

  useEffect(() => {
    if (vehicleId) {
      const filter = vehiclesData
        ? vehiclesData?.vehicles?.find((item) =>
            item._id === vehicleId ? {} : null
          )
        : null;

      if (filter) {
        setFilterVehicle(filter);
      } else {
        // call api.........
        handleSingleVehicle();
      }
    }

    if (auctionVehicleId) {
      setLoadingVehicle(true);
      handleSingleVehicle();
    }
  }, [auctionVehicleId, vehicleId]);

  console.log("Target Vehicle.....", filterVehicle);

  const handleSingleVehicle = () => {
    if (vehicleId) {
      VehiclController.getSingleVehicle(vehicleId)
        .then((res) => {
          setFilterVehicle(res);
          setLoadingVehicle(false);
        })
        .catch((err) => {
          console.log("Err Single Auction Vehicle......", err);
          setFilterVehicle(null);
          setLoadingVehicle(false);
        });
    }

    if (auctionVehicleId) {
      AuctionController.getSingleAuctionVehicle(auctionVehicleId)
        .then((res) => {
          console.log("@Auction Vehicle Res.....", res);
          setFilterVehicle(res?.vehicle);
          setLastBid(res?.lastBidAmount);
          console.log("Last Bid.....", res.lastBidAmount);
          setLoadingVehicle(false);
        })
        .catch((err) => {
          console.log("Err Single Auction Vehicle......", err);
          setFilterVehicle(null);
          setLoadingVehicle(false);
          setLastBid(null);
        });
    }
  };

  const breadCrumbs = [
    { name: "Vehicles", href: "/vehicles", current: false },
    {
      name: "Modify Vehicle",
      href: `/vehicles/modify-vehicle?id=${vehicleId}`,
      current: true,
    },
  ];

  const handleGetBids = () => {
    console.log(isEditMode, "@id auction vehicle id..........");
    setBidsLoading(true);
    VehiclController.getBidsByVehicle(isEditMode)
      .then((res) => {
        console.log(res, "@bids res..........");
        setbids(res?.bids);
        setBidsLoading(false);
      })
      .catch((err) => {
        setBidsLoading(false);
        // console.log(err, '');
      });
  };

  useEffect(() => {
    handleGetBids();
  }, []);

  const onUpdateVehicle = (data) => {
    console.log("Data.....", data);
    const vehicleImages = data.isTouchedImg
      ? {
          new: data?.images,
          deleted: data?.deletedImages,
        }
      : null;
    //   data?.images?.url === initialValeues.image ? null : data.image?.file;
    console.log(vehicleImages, "@vehicle image data.....");

    let vehicleData = data.isTouchedData
      ? {
          title: data?.title,
          driveTrain: data?.driveTrain,
          interior: data?.interior,
          exterior: data?.exterior,
          transmission: data?.transmission,
          engine: data?.engine,
          fuleType: data?.fuleType,
          mileageCity: data?.mileageCity,
          mileageHighway: data?.mileageHighway,
          description: data?.description,
          VIN: data?.VIN,
          year: data?.year,
          modal: data?.modal,
          make: data?.make,
          trimLevel: data?.trimLevel,
          style: data?.style,
          madeIn: data?.madeIn,
          driveType: data?.driveType,
          KMDriven: data?.KMDriven,
          priceRange: {
            min: data?.priceMin,
            // max: data?.priceMax,
          },
          knownIssues: data?.options,
        }
      : null;

    console.log("@vehicle Data Modify vehicle..... ", vehicleData);
    setProcessing(true);
    VehiclController.updateVehicle(
      filterVehicle?._id,
      vehicleData,
      vehicleImages,
      (progress) => {
        setProgress(progress.toFixed(2));
      }
    )
      .then((res) => {
        console.log(res, "@update all promises success values........");
        setTimeout(() => {
          setProcessing(false);
          setProgress(0);
          navigate(`/vehicles`);
        }, 500);
      })
      .catch((err) => {
        console.log(err, "@update all promises error........");
        setProcessing(false);
      });
  };

  // render vehicle details
  const renderDetails = () => {
    return (
      <>
        <div className="flex flex-col w-full ">
          <h3 className="text-left mt-5 font-semibold text-[#566589]">
            Modify Vehicle
          </h3>
          {auctionVehicleId && (
            <div className="flex flex-col gap-1 w-full items-start mt-3 text-[#566589]">
              <p>Last Bid Amount :{" $" + " " + lastBid}</p>
            </div>
          )}
          <VehicleForm
            isEdit={isEditMode}
            className={`pt-4`}
            initialValues={filterVehicle}
            onSubmit={onUpdateVehicle}
          />
        </div>
        <Modal visible={processing}>
          <div className="flex items-center justify-center w-32 mx-auto">
            <CircularProgressbar
              value={progress}
              // classes={{
              //   text: "text-2xl",
              // }}
              text={`${progress}%`}
              background
              backgroundPadding={6}
              // className={`!text-2xl mx-auto`}
              styles={buildStyles({
                backgroundColor: "#0984e3",
                textColor: "#fff",
                pathColor: "#fff",
                trailColor: "transparent",
                textSize: "10px",
              })}
            />
          </div>
        </Modal>
      </>
    );
  };

  const renderBids = () => {
    const TableHead = [
      "Profile Picture",
      "Full Name",
      "Phone Number",
      "Date",
      "Bid Amount",
      "Buy Now Price",
    ];

    return (
      !Bidsloading && (
        <div className="pt-4">
          <h3 className="text-left font-semibold text-[#566589] mb-4">Bids</h3>

          <Tables>
            <THead>
              <TR>
                {TableHead.map((ls, index) => (
                  <TH
                    className={
                      ls === "Actions"
                        ? "sticky right-0 bg-gray-400 z-10 text-white"
                        : ""
                    }
                    key={index}
                  >
                    {ls}
                  </TH>
                ))}
              </TR>
            </THead>
            {bids?.length > 0 ? (
              <TBody className={`relative`}>
                {bids?.map((bid, index) => {
                  const { _id, createdAt, bidAmount, buyer, auctionVehicle } =
                    bid;

                  const date = new Date(createdAt).toDateString();
                  return (
                    <TR key={_id}>
                      <TD>
                        <div className="w-40 h-40">
                          <img
                            src={
                              buyer?.profilePicture?.image?.url
                                ? buyer?.profilePicture?.image?.url
                                : "/Images/vehicle-placeholder.webp"
                            }
                            className="object-cover w-full h-full rounded-lg"
                            alt="vehicle"
                          />
                        </div>
                      </TD>
                      <TD>{buyer?.fullName}</TD>
                      <TD>{buyer?.phoneNumber}</TD>
                      <TD>{date.slice(4)}</TD>
                      <TD>${formatCurrency(bidAmount)}</TD>
                      <TD>${formatCurrency(auctionVehicle?.buyNowPrice)}</TD>
                    </TR>
                  );
                })}
              </TBody>
            ) : (
              <div className="text-center text-lg font-semibold text-[#566589]">
                No Data Found
              </div>
            )}
          </Tables>
        </div>
      )
    );
  };

  const renderTabs = () => {
    return (
      <nav
        className="flex divide-x divide-gray-200 rounded-lg shadow isolate"
        aria-label="Tabs"
      >
        {Tabs.map((tab, tabIdx) => (
          <button
            onClick={() => {
              if (isTouched) {
                setSave(true);
                return;
              }
              setTabs(
                Tabs.map((t, i) =>
                  i === tabIdx
                    ? { ...t, current: true }
                    : { ...t, current: false }
                )
              );
            }}
            key={tab.title}
            className={classNames(
              tab.current
                ? "text-gray-900 font-semibold"
                : "text-gray-500 hover:text-gray-700 font-semibold",
              tabIdx === 0 ? "rounded-l-lg" : "",
              tabIdx === Tabs.length - 1 ? "rounded-r-lg" : "",
              "group relative min-w-0 capitalize flex-1 overflow-hidden bg-white py-3 px-4 text-sm font-semibold text-center hover:bg-gray-50 focus:z-10"
            )}
            aria-current={tab.current ? "page" : undefined}
          >
            <span>{tab.title}</span>
            <span
              aria-hidden="true"
              className={classNames(
                tab.current ? "bg-indigo-500" : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5"
              )}
            />
          </button>
        ))}
      </nav>
    );
  };

  return (
    <div>
      <BreadCrumb pages={breadCrumbs} className={`mt-3`} />

      {/* hero section */}

      <div className="w-full mb-4">
        <div className="aspect-w-4 aspect-h-1">
          <img
            src={filterVehicle?.pictures[0]?.image.url}
            className="object-cover object-center w-full h-full rounded-xl"
            alt="cover-pic"
          />
        </div>
      </div>

      {/* tabs */}
      {renderTabs()}

      {auctionVehicleId && (
        <>
          {Tabs[0]?.current && renderDetails()}
          {Tabs[1]?.current && renderBids()}
          {Tabs[2]?.current && (
            <KnownIssues
              isTouched={isTouched}
              setIsTouched={setIsTouched}
              save={save}
              setSave={setSave}
              data={filterVehicle?.knownIssues}
              vehicleId={filterVehicle?._id}
              onSubmit={(res) => setFilterVehicle(res)}
            />
          )}
          {Tabs[3]?.current && <BuyNow vehicleId={filterVehicle?._id} />}
          {Tabs[4]?.current && <Offers vehicleId={filterVehicle?._id} />}
        </>
      )}
      {vehicleId && (
        <>
          {Tabs[0]?.current && renderDetails()}
          {Tabs[1]?.current && <Offers vehicleId={filterVehicle?._id} />}
        </>
      )}
    </div>
  );
};

export default ModifyVehicle;
