import React from "react";
//    importing components
//    importing images
import Logo from "../../../Assests/Icons/InfoRide-01 1.svg";
//    importing icons
import greyCarIcon from "../../../Assests/Icons/car (3) 1.png";
import WalletIcon from "../../../Assests/Icons/wallet.svg";
import { Link, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FaUsers, FaMoneyCheckAlt } from "react-icons/fa";
//    importing css files
import "./sideBar.css";

export const SideBar = (props) => {
  //     const [toggle, setToggle] = useState(false);

  //   const  openNav=()=>{
  //         document.getElementById("mySidebar").style.marginLeft = "0px";
  //         document.getElementById("main").style.width = "84%";
  //         setToggle(false)
  //       }
  //     const  closeNav=()=> {
  //         document.getElementById("mySidebar").style.marginLeft = "-250px";
  //         document.getElementById("main").style.width= "100%";
  //         setToggle(true)

  //       }
  const location = useLocation();
  const [params] = useSearchParams();

  const auctionId = params.get(`id`);

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <div className="side-bar-main-div">
      <div>
        <img src={Logo} alt="" />
      </div>
      {/* <button className='sidebar-toggle-button' onClick={()=>{toggle===false?closeNav():openNav()}}>toggle</button> */}
      <div className="sidebar-button-main-div">
        <Link to="/allBuisnesses" className="react-router-link">
          <div className="side-bar-button-div">
            <button
              className={
                splitLocation[1] === "allBuisnesses"
                  ? " side-bar-button-actives"
                  : "side-bar-buttons"
              }
            >
              <img
                className={
                  splitLocation[1] === "allBuisnesses"
                    ? "side-bar-icon-active"
                    : "side-bar-icon"
                }
                src={greyCarIcon}
                alt=""
              />

              <div className="side-bar-button-text">Dealers</div>
            </button>
          </div>
        </Link>
        <Link to="/conflicts" className="react-router-link">
          <div className="side-bar-button-div">
            <button
              className={
                splitLocation[1] === "conflicts"
                  ? "side-bar-button-actives"
                  : "side-bar-buttons"
              }
            >
              <img
                className={
                  splitLocation[1] === "conflicts"
                    ? "side-bar-icon-active"
                    : "side-bar-icon"
                }
                src={WalletIcon}
                alt=""
              />
              <div className="side-bar-button-text">Conflicts</div>
            </button>
          </div>
        </Link>
        <Link to="/buyers" className="react-router-link">
          <div className="side-bar-button-div">
            <button
              className={
                splitLocation[1] === "buyers"
                  ? "side-bar-button-actives"
                  : "side-bar-buttons"
              }
            >
              <FaUsers
                className={` ${
                  splitLocation[1] === "buyers"
                    ? "text-white"
                    : "text-gray-500/70"
                }  self-center w-[25px] h-[25px] m-[10px] `}
              />
              <div className="side-bar-button-text">Buyers</div>
            </button>
          </div>
        </Link>
        <Link to="/refundable" className="react-router-link">
          <div className="side-bar-button-div">
            <button
              className={
                splitLocation[1] === "refundable"
                  ? "side-bar-button-actives"
                  : "side-bar-buttons"
              }
            >
              <FaMoneyCheckAlt
                className={` ${
                  splitLocation[1] === "refundable"
                    ? "text-white"
                    : "text-gray-500/70"
                }  self-center w-[25px] h-[25px] m-[10px] `}
              />
              <div className="side-bar-button-text">Refundable</div>
            </button>
          </div>
        </Link>
        <Link to="/auctions" className="react-router-link">
          <div className="side-bar-button-div">
            <button
              className={
                splitLocation[1] === "auctions"
                  ? "side-bar-button-actives"
                  : "side-bar-buttons"
              }
            >
              <img
                className={
                  splitLocation[1] === "auctions"
                    ? "side-bar-icon-active"
                    : "side-bar-icon"
                }
                src={WalletIcon}
                alt=""
              />
              <div className="side-bar-button-text">Auctions</div>
            </button>
          </div>
        </Link>
        <Link to="/vehicles" className="react-router-link">
          <div className="side-bar-button-div">
            <button
              className={
                splitLocation[1] === "vehicles" && !auctionId
                  ? " side-bar-button-actives"
                  : "side-bar-buttons"
              }
            >
              <img
                className={
                  splitLocation[1] === "vehicles" && !auctionId
                    ? "side-bar-icon-active"
                    : "side-bar-icon"
                }
                src={greyCarIcon}
                alt=""
              />

              <div className="side-bar-button-text">Vehicles</div>
            </button>
          </div>
        </Link>
      </div>
    </div>
  );
};
