import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

export default function Pagination({ noOfPages = 10, currentPage, disable }) {
  parseInt(currentPage);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const pagination = new Array(noOfPages).fill(0);
  const [params, setParams] = useSearchParams();

  const handleUpdateQueryParam = (obj) => {
    if (typeof obj === "object") {
      for (const key in obj) {
        params.set(key, obj[key]);
      }
      setParams(params);
    } else {
      alert("you can pass only object in update params");
    }
  };

  const handleNext = () => {
    if (currentPage < noOfPages - 1 && !disable) {
      handleUpdateQueryParam({ pageNumber: parseInt(currentPage) + 1 });
      // navigate(`${pathname}?pageNumber=${parseInt(currentPage) + 1}`);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0 && !disable) {
      handleUpdateQueryParam({ pageNumber: parseInt(currentPage) - 1 });
      // navigate(`${pathname}?pageNumber=${currentPage - 1}`);
    }
  };

  const handleOnNumberClick = (index) => {
    // if (currentPage > 0 && !disable) {
    handleUpdateQueryParam({ pageNumber: index });
    // navigate(`${pathname}?pageNumber=${currentPage - 1}`);
    // }
  };
  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
          Previous
        </button>
        <button className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing
            <span className="font-medium">
              {" "}
              {currentPage === 0 ? 1 : currentPage}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {" "}
              {noOfPages > 2 ? currentPage + 2 : 2}
            </span>{" "}
            of <span className="font-medium"> {noOfPages}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={handlePrev}
              disabled={currentPage == 0}
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
              <span className="sr-only">Previous</span>
              <BsChevronLeft className="h-5 w-5 text-xl" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
            {pagination.map((_, index) => {
              return (
                <>
                  {index === 0 && currentPage >= 2 && noOfPages > 3 && (
                    <>
                      {
                        <button
                          // to={`${pathname}?pageNumber=${index}`}
                          onClick={() => handleOnNumberClick(index)}
                          aria-current="page"
                          className={`${
                            currentPage == index
                              ? "bg-indigo-50 border-indigo-500 text-indigo-600 underline"
                              : "text-indigo-500 no-underline "
                          } relative z-10 inline-flex items-center border px-4 py-2 text-sm font-medium  focus:z-20`}
                        >
                          {index + 1}
                        </button>
                      }
                      <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
                        ...
                      </span>
                    </>
                  )}

                  {index < currentPage + 2 && index > currentPage - 2 && (
                    <button
                      onClick={() => handleOnNumberClick(index)}
                      aria-current="page"
                      className={`${
                        currentPage == index
                          ? "bg-indigo-50 border-indigo-500 text-indigo-600 underline"
                          : "text-indigo-500 no-underline "
                      } relative z-10 inline-flex items-center border px-4 py-2 text-sm font-medium  focus:z-20`}
                    >
                      {index + 1}
                    </button>
                  )}

                  {index === pagination.length - 1 &&
                    noOfPages > 3 &&
                    currentPage < pagination.length - 2 && (
                      <>
                        <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
                          ...
                        </span>
                        <button
                          onClick={() => handleOnNumberClick(index)}
                          aria-current="page"
                          className={`${
                            currentPage == index
                              ? "bg-indigo-50 border-indigo-500 text-indigo-600 underline"
                              : "text-indigo-500 no-underline "
                          } relative z-10 inline-flex items-center border px-4 py-2 text-sm font-medium  focus:z-20`}
                        >
                          {index + 1}
                        </button>
                      </>
                    )}
                </>
              );
            })}

            <button
              onClick={handleNext}
              disabled={currentPage == noOfPages - 1}
              className="relative inline-flex items-center disabled:cursor-not-allowed rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
              <span className="sr-only">Next</span>
              <BsChevronRight className="h-5 w-5 text-xl" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
