import React from "react";
//     importing components
import SlidingPane from "react-sliding-pane";
//    importing icons
import BackArrow from "../../../Assests/Icons/backArow.svg";
//    importing css files
import "./slidePane.css";
import "react-sliding-pane/dist/react-sliding-pane.css";

const SlidePanel = (props) => {
  const vertical = props.vertical ? true : false;
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  return (
    <SlidingPane
      closeIcon={<div>Some div containing custom close icon.</div>}
      isOpen={props.visible}
      from={vertical ? "bottom" : "right"}
      overlayClassName="some-custom-overlay-class"
      shouldCloseOnEsc={true}
      hideHeader
      height={props.height ? props.height : "100%"}
      width={props.width ? props.width : 900}
      onRequestClose={handleClose}
    >
      <div className="slide-pane-main-div">
        <div style={{ width: 20 }} />
        <span style={{ fontSize: 30, cursor: "pointer" }} onClick={handleClose}>
          <img src={BackArrow} alt="" className="back-arrow"/>
          {props.title}
        </span>
        <div>{props.children}</div>
      </div>
    </SlidingPane>
  );
};

export default SlidePanel;
