import React, { useEffect, useState } from "react";
//     importing components
import { DropDownsPop } from "../../../Components/AbstractsComponents/DropDown/dropDown";
import { Buttons } from "../../AbstractsComponents/Button/button";
import { TailSpin } from "react-loader-spinner";
//    importing icons
import ArrowIcon from "../../../Assests/Icons/chevronIcon.svg";
//    importing css files
import "./allBuisnessTableButton.css";
import BusinessesController from "../../../Controllers/businessesController";
export const AllBuisnessesTableButton = (props) => {
  const [defaultValue, setDefaultValue] = useState(props.selectedValue);
  const [isProcessing, setIsProcessing] = useState(false);
  

  const handleChangeApprovalStatus = (status) => {
    let previousValue = defaultValue;
    setDefaultValue(status);
    setIsProcessing(true)
      BusinessesController.updateApprovalStatus(props.business.payload._id, status)
        .then(updated => {
          setIsProcessing(false)
        }).catch(err => {
          console.log(`Error in updating status`);
          setIsProcessing(false)
          setDefaultValue(previousValue);
        })
  }


  return (
    <div className="All-vehicle-button-div">
      <Buttons
        style={
          defaultValue === BusinessesController.APPROVAL_STATUS.APPROVED
            ? { color: "white", backgroundColor: "#64B822" }
            : defaultValue=== BusinessesController.APPROVAL_STATUS.BLOCKED
            ? { backgroundColor: "#EC2222", color: "white" }
            : {}
        }
        className="dropdown-approval-buton"
      >

        
        {isProcessing ? (
          <TailSpin
            height="25px"
            width="25px"
            color="white"
            ariaLabel="loading"
          />
        ) : (
          <span>{defaultValue}</span>
        )}
      </Buttons>
      <div
        className="drop-down-button-arrow-div"
        style={
          defaultValue  === BusinessesController.APPROVAL_STATUS.APPROVED
            ? { color: "white", backgroundColor: "#64B822" }
            : defaultValue === BusinessesController.APPROVAL_STATUS.BLOCKED
            ? { backgroundColor: "#EC2222", color: "white" }
            : {}
        }
      >
        <DropDownsPop
          heading={ArrowIcon}
          onChange={handleChangeApprovalStatus}
          Processing={isProcessing}
          width="16px"
          height="16px"
        ></DropDownsPop>
      </div>
    </div>
  );
};
