import ReduxDispatchController from "./reduxDispatchController";
import axios from "axios";
import { ERRORS } from "./errorMessages";
import store from "../Store";
class AuthController {
  static SAVED_SESSION_KEY = "key@inforide-admin.webv0.0.2";

  static USER = {
    fullName: "admin",
    email: "admin@inforide.com",
  };

  static login(username, password, remember) {
    return new Promise((resolve, reject) => {
      console.log(remember, "@auth remmber me");
      axios
        .post(`/main-admin/auth/login`, {
          username: username,
          password: password,
        })
        .then((res) => {
          if (res.data.success) {
            let token = `bearer ${res.data.data.token}`;
            console.log(`@auth res`, res.data);
            if (remember) {
              console.log(`@auth remember`);
              AuthController.presistCredencials(AuthController.USER, token);
            }
            ReduxDispatchController.AUTH.setCurrentUser(AuthController.USER);
            axios.defaults.headers.common["Authorization"] = token;
            resolve(true);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  static checkAuthStatus() {
    return new Promise((resolve, reject) => {
      const currentUser = AuthController.getCurrentUser();

      this.restorePresistedCredencials();

      //   console.log(currentUser, "@auth current user");
      //   setTimeout(() => {
      //       let updatedUser = {
      //           user: AuthController.USER,
      //           token: "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MjFjYThhZDE4MzZmNDFhZTE5NWIxNTQiLCJpYXQiOjE2NDY3MzYyMjF9.6byVA-x3zd0WOYc-PPK-4hAJjNmb8yPLw0XNRZjAvaw",
      //       }
      //       AuthController.presistCredencials(updatedUser.user, updatedUser.token)
      //       ReduxDispatchController.AUTH.setCurrentUser(updatedUser.user);
      //       axios.defaults.headers.common['Authorization'] = updatedUser.token;
      //       resolve(updatedUser)
      //   }, 1000)

      //   axios
      //     .post(`/business/auth/check-auth-status`, { email: currentUser.email })
      //     .then((res) => {
      //       console.log(res.data, "@auath check status");
      //       if (res.data.success) {
      //         resolve(true);
      //       } else {
      //         reject(res.data.error.message);
      //       }
      //     })
      //     .catch((err) => {
      //       reject(ERRORS.NETWORK_ERROR);
      //     });
    });
  }

  static logout() {
    axios.defaults.headers.common["Authorization"] = undefined;
    ReduxDispatchController.AUTH.setCurrentUser(null);
    localStorage.removeItem(AuthController.SAVED_SESSION_KEY);
    ReduxDispatchController.AUTH.setSplashVisible(true);
  }

  static restorePresistedCredencials() {
    let presistedData = AuthController.getPresistedCredencials();
    console.log(`@auth Restored data`, presistedData);
    if (presistedData) {
      ReduxDispatchController.AUTH.setCurrentUser(presistedData.user);
      axios.defaults.headers.common["Authorization"] = presistedData.token;
    }
    return presistedData;
  }

  static getPresistedCredencials = () => {
    let strSessionData = localStorage.getItem(AuthController.SAVED_SESSION_KEY);
    if (strSessionData) {
      return JSON.parse(strSessionData);
    } else {
      return null;
    }
  };
  static presistCredencials = (user, token) => {
    if (user && token) {
      console.log({ user, token }, "@auth persisting credentials");
      localStorage.setItem(
        AuthController.SAVED_SESSION_KEY,
        JSON.stringify({ user, token })
      );
    } else {
      console.log(`undefined credencials: not presisted`);
    }
  };

  static getCurrentUser = () => {
    return store.getState().AuthReducer.currentUser;
  };
}
export default AuthController;
