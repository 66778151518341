import ReduxDispatchController from "./reduxDispatchController";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import ReduxDispatchController from "./reduxDispatchController";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import ToastController from "./toastController";
import { ERRORS } from "./errorMessages";

class VehiclController {
  static getAllVehicles(
    paramsObj = {
      pageSize: 10,
      pageNumber: 0,
    }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/main-admin/vehicle/get-vehicles-by-filters`, {
          params: paramsObj,
        })
        .then((res) => {
          if (res) {
            console.log("@vehicles controller...", res.data.data);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log("@vehicles controller err..", err);
          reject(err);
        });
    });
  }

  static getSingleVehicle(id) {
    return new Promise((resolve, reject) => {
      console.log(id, "@single id........");
      axios
        .get(`/main-admin/vehicle/${id}`)
        .then((res) => {
          if (res.data.success) {
            console.log("@single vehicle response......", res.data.data);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log("@single vehicle error.....", err);
          reject(err);
        });
    });
  }

  static getBidsByVehicle(_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/auction/bid/get-bids-by-filters`, {
          params: {
            pageSize: 10,
            pageNumber: 0,
            auctionVehicle: _id,
            // vehicle: _id,
          },
        })
        .then((res) => {
          if (res) {
            console.log("@bids...", res.data.data);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log("@bids  err..", err);
          reject(err);
        });
    });
  }
  static getAllVehiclesByBusiness(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/vehicle/business/get-vehicle-by-business/${id}`)
        .then((res) => {
          console.log("Res by business", res.data.data);
          if (res.data.success) {
            resolve(res.data.data.vehicles);
          }
        })
        .catch((err) => {
          reject(err);
          console.log("Err", err);
        });
    });
  }

  static updateData = (obj, id) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/vehicles/${id}`, obj)
        .then((res) => {
          if (res.data.success) {
            let vehicle = res.data.data;
            console.log("Updated Data....");
            ReduxDispatchController.VEHICLES.setUpdatedVehicleData(vehicle);
            resolve(vehicle);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log("CATCH Error in updating vehicle");
          console.log(err);
          reject("Network Error");
        });
    });
  };

  static updateVehicle = (
    vehicleId,
    vehicleData,
    vehicleImages,
    onUploadProgress
  ) => {
    // console.log("Update Fun data....", vehicleData, vehicleId, vehicleImages);
    return new Promise((resolve, reject) => {
      if (!!vehicleImages && vehicleImages?.new?.length > 0) {
        console.log("@vehicle imgs.....running");
        let progressArray = vehicleImages.new.map((i) => 0);
        progressArray.push(0);
        let totalProgress = progressArray.length * 100;
        let currentProgress = 0;
        VehiclController.uploadAllImages(vehicleImages.new, (p, index) => {
          progressArray[index] = p;
          currentProgress = 0;
          progressArray.map((i) => (currentProgress += i));
          // console.log("@progress", currentProgress);
          // console.log("@progress test 2", {
          //   currentprogress: (currentProgress / totalProgress) * 100,
          //   totalProgress,
          // });
          onUploadProgress(parseInt((currentProgress / totalProgress) * 100));
        })
          .then((res) => {
            let images = res;
            console.log(
              vehicleImages?.deleted,
              "@aws upload all imgs res......"
            );

            let formData = {
              ...vehicleData,
              picturesAdded: images,
              picturesRemoved: vehicleImages?.deleted || [],
            };
            VehiclController.migrateVehicle(vehicleId, formData)
              .then((res) => {
                progressArray[progressArray.length - 1] = 100;
                currentProgress = 0;
                progressArray.map((i) => (currentProgress += i));
                onUploadProgress(
                  parseInt(currentProgress / totalProgress) * 100
                );
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        console.log("Removed....", vehicleImages?.deleted);
        let formData = {
          ...vehicleData,
          picturesRemoved: vehicleImages?.deleted || [],
          picturesAdded: [],
        };
        VehiclController.migrateVehicle(vehicleId, formData)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }

      // const toastController = "new ToastController();";
      // let promisesArray = [];
      // if (vehicleData) {
      //   promisesArray.push(this.updateData(vehicleData, vehicleId));
      // }
      // if (vehicleImages) {
      //   promisesArray.push(
      //     this.updateVehicleImages(vehicleId, vehicleImages)
      //   );
      // }
      // // toastController.showProcessing("Updating vehicle...");
      // Promise.all(promisesArray)
      //   .then((values) => {
      //     console.log(values, "@vehicle promises values................");
      //     // toastController.endProcessingWithSuccess("Vehicle updated...");
      //     resolve(values);
      //   })
      //   .catch((err) => {
      //     // toastController.endProcessingWithError(err);
      //     reject("ERRORS.NETWORK_ERROR");
      //   });
    });
  };

  static idGenerator = () => {
    return `${Math.floor(Math.random() * 21232122233331)}`;
  };

  static singleImageUpload = (
    image,
    index,
    onProgress = (uploadPercentage, index) => false
  ) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("image", image.raw);

      console.log("@Image S3", image);

      axios
        .post(`/image/upload`, formData, {
          onUploadProgress: ({ progress, rate }) => {
            let uploadProgress = Math.round(progress * 100);
            console.log({ uploadProgress, index }, "@progres.............");
            onProgress(uploadProgress, index);
          },
        })
        // .on("httpUploadProgress", (res) => {
        //   // console.log("@progress test 1", res);
        //   onProgress(Math.round((res.loaded / res.total) * 100), index);
        // })
        // .promise()
        .then(({ data }) => {
          console.log(data, "@aws res upload.......");
          onProgress(100, index);

          if (data.success) {
            resolve(data.data.image);
          } else {
            resolve({
              success: false,
              error: { message: "success false in img upload" },
            });
          }
          // console.log("New Block", bl);
        })
        .catch((err) => {
          console.log("@aws Error", err);
          onProgress(100, index);
          resolve({ success: false, error: { message: err } });
        });
    });
  };

  static migrateVehicle = (vehicleId, data) => {
    console.log("@migrate Vehicle Data......", data, vehicleId);
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `/vehicle/business/${vehicleId}/update-data-and-image-urls`,
          data
        )
        .then(({ data }) => {
          console.log(data, "@migrate vehicle.......");
          if (data.success) {
            let vehicle = data.data;
            console.log("@migrate  Vehicle Res....", { vehicle, data });
            ReduxDispatchController.VEHICLES.setUpdatedVehicleData(vehicle);
            ToastController.success("vehicle updated.....");
            resolve(vehicle);
          } else {
            reject(data.error.message);
            ToastController.error(data.error.message);
          }
        })
        .catch((err) => {
          console.log(err, "error.........");
          reject(err);
        });
    });
  };

  static uploadAllImages = (imagesArray, onUploadProgress, onComplete) => {
    return new Promise((resolve, reject) => {
      console.log(imagesArray, "@aws imgs array for upload");
      // let promises = imagesArray.map((i, index) =>
      //   this.uploadSingle(i.image, index, onUploadProgress)
      // );

      let promises = imagesArray.map((i, index) =>
        VehiclController.singleImageUpload(i.image, index, onUploadProgress)
      );

      Promise.all(promises)
        .then((uploadedImages) => {
          console.log(uploadedImages, "@aws all imgs res values......");

          // let formattedImgs = uploadedImages
          //   .filter((img) => img.success)
          //   .map(
          //     (img) =>
          //       img && {
          //         image: { key: img.image.key, url: img.image.url },
          //         avatar: { key: img.image.key, url: img.image.url },
          //       }
          //   );

          // console.log(formattedImgs, "@aws filtered imgs values......");

          if (uploadedImages.length > 0) {
            resolve(uploadedImages);
          } else {
            reject(`@aws error in uploading imgs..........`);
          }
        })
        .catch((err) => reject(err));
    });
  };

  static onUploadProgress = (percentage, index, orignalArray) => {
    if (percentage === -1) {
      /// uploading faild.
    } else {
      orignalArray[index].percentage = percentage;
    }
  };

  static getAllBuyNowOffersOfVehicle(vId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/offer/offer/get-offers-by-vehicle/${vId}`, {
          params: {
            buyNow: true,
          },
        })
        .then(({ data }) => {
          console.log("buynow offers....", data);
          if (data.success) {
            resolve(data.data);
          } else {
            reject(data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }
  static getAllOffersOfVehicle(vId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/offer/offer/get-offers-by-vehicle/${vId}`, {
          // params: {
          //   buyNow: false,
          // },
        })
        .then(({ data }) => {
          console.log("buynow offers....", data);
          if (data.success) {
            resolve(data.data);
          } else {
            reject(data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  static acceptOffer(offerId, status) {
    return new Promise((resolve, reject) => {
      const toast = new ToastController();
      const reqData = {
        offerStartedDate: "2002-12-09",
        status: status,
      };
      console.log({ offerId, status, reqData }, "@offer req data..........");

      // axios.patch(`/offer/offer/update-status/${offerId}`, reqData)
      axios
        .post(`/offer/offer/accept-reject-counter-offer/${offerId}`, reqData)
        .then((res) => {
          console.log(res, "@offer accept res............");
          if (res.data.success) {
            console.log("RESP ----> ", res.data.data.status);
            // console.log("OffId ----> ", offerId);
            ToastController.success(`offer successfully ${status}...!`);
            resolve(true);
          } else {
            ToastController.error(
              res.data.error.message ?? res.data.error.code
            );
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  static updateVehicleImages = (v_id, images) => {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      let deletedImgs = JSON.stringify({ photos: images.deleted });
      let newImgs = images.new;
      formData.append("data", deletedImgs);
      for (let index = 0; index < newImgs.length; index++) {
        const singleImage = newImgs[index];
        formData.append("photos", singleImage.image.raw);
      }
      axios
        .patch(`/vehicles/${v_id}/update-images`, formData, {
          maxContentLength: 100000000,
          maxBodyLength: 1000000000,
        })
        .then((res) => {
          if (res.data.success) {
            console.log(res.data, "@update img vehicle...........");
            ReduxDispatchController.VEHICLES.setUpdatedVehicleData(
              res.data.data
            );

            resolve(true);
          } else {
            console.log("error updating vehicle", res.data);
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(`CATCH Error in updating images`);
          console.log(err);
          reject("Network Error: please check your internet connection");
        });
    });
  };
}

export default VehiclController;

export const useVehicles = (
  qryObj = {
    // pageSize: 5,
    pageNumber: 0,
  },
  currentPage
) => {
  const [loading, setLoading] = useState(false);
  const vehiclesData = useSelector((state) => state.VehicleReducer.vehicles);
  const {
    businessId,
    pageNumber,
    notAuctionedYet,
    isOfferedVehicles,
    make,
    sortBy,
  } = qryObj;

  console.log("@vehicle filter.......", qryObj);

  const handleGetVhicles = () => {
    setLoading(true);
    VehiclController.getAllVehicles(qryObj)
      .then((res) => {
        if (res) {
          setLoading(false);
          console.log("@vehicles hook res....", res);
          ReduxDispatchController.VEHICLES.set(res);
        }
      })
      .catch((err) => {
        console.log(err, "@vehicles vehicles error in hook....");
        setLoading(false);
      });
  };

  useEffect(() => {
    // if (!vehiclesData.vehicles) {
    //   console.log("@running ....");
    //   handleGetVhicles();
    // } else if (vehiclesData?.noOfPages > pageNumber) {
    //   handleGetVhicles();
    // }

    handleGetVhicles();

    // }
  }, [
    pageNumber,
    businessId,
    notAuctionedYet,
    make,
    isOfferedVehicles,
    sortBy,
  ]);

  return {
    loading: loading,
    vehiclesData: vehiclesData?.vehicles || [],
    totalPages: vehiclesData?.noOfPages,
  };
};
