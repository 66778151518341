import React, { useEffect, useState } from "react";
import VehiclController from "../../../Controllers/vehicleController";
import {
  Tables,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from "../../../Components/AbstractsComponents/Tables";
import { formatCurrency } from "../../../Utils/helperFunctions";
import { TailSpin } from "react-loader-spinner";
import Pagination from "../../../Components/AbstractsComponents/Pagination";
import { TiArrowUnsorted } from "react-icons/ti";
import ToastController from "../../../Controllers/toastController";

const Offers = ({ vehicleId }) => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [accept, setAccept] = useState(null);
  const [reject, setReject] = useState(null);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    setLoading(true);
    VehiclController.getAllOffersOfVehicle(vehicleId)
      .then((res) => {
        setOffers(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log("buynow err....", err);
        setErrMsg(err);
        setLoading(false);
        ToastController.error(err);
      });
  }, []);

  useEffect(() => {
    if (flag) {
      setLoading(true);
      VehiclController.getAllOffersOfVehicle(vehicleId)
        .then((res) => {
          setOffers(res);
          setLoading(false);
        })
        .catch((err) => {
          console.log("buynow err....", err);
          setErrMsg(err);
          setLoading(false);
        });
    }
  }, [flag]);

  const handleAcceptOffer = (offerId, status) => {
    status = status == true ? "accepted" : "rejected";
    setAccept(offerId);
    VehiclController.acceptOffer(offerId, status)
      .then((res) => {
        console.log("@accept res...", res);
        setAccept(null);
        setFlag(offerId);
      })
      .catch((err) => {
        setFlag(offerId);
        setAccept(null);
        console.log("@accept offer err...", err);
      });
  };
  const handleRejectOffer = (offerId, status) => {
    status = status == true ? "accepted" : "rejected";
    setReject(offerId);
    VehiclController.acceptOffer(offerId, status)
      .then((res) => {
        console.log("@accept res...", res);
        setReject(null);
        setFlag(offerId);
      })
      .catch((err) => {
        setReject(null);
        console.log("@accept offer err...", err);
      });
  };

  console.log("@offers...", offers);

  const TableHead = [
    "Profile Picture",
    "Full Name",
    "Phone Number",
    "Buy Now Amount",
    "Offer Status",
    "Actions",
  ];

  return !loading ? (
    <div className="pt-4">
      <h3 className="text-left font-semibold text-[#566589] mb-4">
        All Offers
      </h3>

      <Tables>
        <THead>
          <TR>
            {TableHead.map((ls, index) => (
              <TH
                className={
                  ls === "Actions"
                    ? "sticky right-0 bg-gray-400 z-10 text-white"
                    : ""
                }
                key={index}
              >
                {ls}
              </TH>
            ))}
          </TR>
        </THead>
        {offers?.length > 0 ? (
          <TBody className={`relative table-row-group`}>
            {offers?.map((offer, index) => {
              const { _id, amount, buyer, business, vehicle, status } = offer;
              console.log("@single offer...", offer);
              return (
                <TR key={_id}>
                  <TD>
                    <div className="w-16 h-16">
                      <img
                        src={
                          buyer?.profilePicture?.image?.url
                            ? buyer?.profilePicture?.image?.url
                            : "/Images/vehicle-placeholder.webp"
                        }
                        className="object-cover w-full h-full rounded-lg"
                        alt="vehicle"
                      />
                    </div>
                  </TD>
                  <TD className={`capitalize font-[700]`}>{buyer?.fullName}</TD>
                  <TD>{buyer?.phoneNumber}</TD>
                  <TD>${amount > 0 ? formatCurrency(amount) : 0}</TD>
                  <TD className={`capitalize `}>{status}</TD>
                  <TD>
                    <div className="flex gap-2 items-center">
                      {status !== "accepted" &&
                        status !== "rejected" &&
                        status !== "expired" && (
                          <div className="flex gap-2">
                            <button
                              disabled={accept}
                              onClick={() => handleAcceptOffer(_id, true)}
                              className="bg-green-500 text-white px-4 rounded-md p-2"
                            >
                              {accept == _id ? (
                                <TailSpin
                                  width={10}
                                  height={10}
                                  color="white"
                                />
                              ) : (
                                "Accept"
                              )}
                            </button>
                            <button
                              disabled={reject}
                              onClick={() => handleRejectOffer(_id, false)}
                              className="bg-red-500 text-white px-4 rounded-md p-2"
                            >
                              {reject == _id ? (
                                <TailSpin
                                  width={10}
                                  height={10}
                                  color="white"
                                />
                              ) : (
                                "Reject"
                              )}
                            </button>
                          </div>
                        )}
                      <button
                        className="bg-primary text-white no-underline py-2 px-6 mr-2 rounded-lg"
                        onClick={() => {
                          console.log(
                            "@chat...",
                            business?._id,
                            vehicle?._id,
                            _id
                          );
                          window.open(
                            `${process.env.REACT_APP_DELEGATE_URL}/?accessKey=${business?._id}-38e552d965994f65a0abe96310a15915&vehicleId=${vehicle?._id}&offerId=${_id}`
                          );
                        }}
                      >
                        Message
                      </button>
                    </div>
                  </TD>
                </TR>
              );
            })}
          </TBody>
        ) : (
          <div className="text-center text-lg font-semibold text-[#566589]">
            No Data Found
          </div>
        )}
      </Tables>
    </div>
  ) : (
    <div className="w-full min-h-[50vh] flex items-center justify-center">
      <TailSpin width={80} height={80} color={`blue`} />
    </div>
  );
};

export default Offers;
