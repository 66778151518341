import React, { useEffect, useState } from "react";
import TransactionController from "../../Controllers/transactionsController";
import Modal from "../../Components/AbstractsComponents/Modal2/Modal";
import { useNavigate } from "react-router-dom";
// import VehiclController from "../../Controllers/vehicleController";
import {
  TBody,
  THead,
  TD,
  TH,
  TR,
  Tables,
} from "../../Components/AbstractsComponents/Tables";
import { Link, useSearchParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import OffersController, {
  useOffers,
} from "../../Controllers/offersController";
import ToastController from "../../Controllers/toastController";
import { formatCurrency } from "../../Utils/helperFunctions";
// import Pagination from "../../Components/AbstractsComponents/Pagination";

const RefundableTable = () => {
  const { offers, loading } = useOffers();
  const [show, setShow] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [id, setId] = useState(null);
  const [msg, setMsg] = useState({
    error: null,
    success: null,
  });

  const TableHead = [
    "Buyer Full Name",
    "Business Full Name",
    "Vehicle Title",
    "Offer Status",
    "Refundable",
    "Actions",
  ];

  const toast = new ToastController();
  const navigate = useNavigate();

  const handleRefund = (id) => {
    setProcessing(true);
    toast.showProcessing("Refunding in process...");
    OffersController.setMarkAsRefunded(id)
      .then((res) => {
        if (res) {
          setProcessing(false);
          toast.endProcessingWithSuccess("Refunding done!");
          setMsg({
            success: "Refunded Successfully",
            error: null,
          });
          navigate("/conflicts");
        }
      })
      .catch((err) => {
        setProcessing(false);
        toast.endProcessingWithError(err);
        setMsg({
          error: err,
          success: null,
        });
      });
  };

  return (
    <div>
      <div>
        {loading ? (
          <div className="flex items-center justify-center min-h-[70vh]">
            <TailSpin width={70} height={70} color={`blue`} />
          </div>
        ) : (
          <div>
            <div className="flex flex-col w-full h-auto gap-6 py-8">
              <h3 className="text-left font-semibold  text-[#566589]">
                Refundable Offers
              </h3>

              <div className="bg-white min-h-[70vh]    rounded-3xl">
                <Tables>
                  <THead>
                    <TR>
                      {TableHead.map((ls, index) => (
                        <TH
                          className={`${
                            ls === "Actions"
                              ? "sticky right-0 rounded-tr-2xl bg-gray-400 z-10 text-white"
                              : ""
                          }  whitespace-nowrap`}
                          key={index}
                        >
                          {ls}
                        </TH>
                      ))}
                    </TR>
                  </THead>
                  {offers.length > 0 ? (
                    <TBody className={`relative`}>
                      {offers?.map((offer, index) => {
                        const {
                          _id,
                          buyer,
                          business,
                          vehicle,
                          status,
                          disputedRefundableAmount,
                        } = offer;

                        return (
                          <TR key={_id}>
                            <TD>{buyer?.fullName}</TD>
                            <TD>{business?.fullName}</TD>
                            <TD>{vehicle?.title}</TD>
                            <TD>{status.replace("_", " ")}</TD>
                            <TD>{disputedRefundableAmount + "$"}</TD>
                            <TD
                              className={`sticky inset-y-0 my-auto right-0  `}
                            >
                              {status !== "payment_refunded" ? (
                                <div className="flex items-center h-full gap-2">
                                  <button
                                    onClick={() => {
                                      setShow(true);
                                      setId(_id);
                                    }}
                                    className="px-3 py-2 font-semibold text-center text-white no-underline rounded-lg bg-primary"
                                  >
                                    Confirm Refund
                                  </button>
                                </div>
                              ) : (
                                <div className="flex items-center h-full gap-2">
                                  <button
                                    disabled={true}
                                    className="px-3 py-2 border-none font-semibold text-center text-white no-underline rounded-lg bg-green-700"
                                  >
                                    Refunded
                                  </button>
                                </div>
                              )}
                            </TD>
                          </TR>
                        );
                      })}
                    </TBody>
                  ) : (
                    <div className="text-center text-lg font-semibold text-[#566589]">
                      No Offers Data Available
                    </div>
                  )}
                </Tables>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal visible={show} closeModal={() => setShow(false)}>
        <div className="max-w-[350px] flex flex-col gap-2 px-10 py-4 rounded-xl mx-auto bg-white">
          <h3 className="text-lg font-[700] text-center">
            {msg.success
              ? msg.success
              : msg.error
              ? msg.error
              : "Do You Want To Refund?"}
          </h3>
          <div className="flex gap-2">
            {!msg.success && !msg.error && (
              <button
                disabled={processing}
                onClick={() => handleRefund(id)}
                className="inline-flex items-center justify-center w-full px-3 py-2 text-center text-white rounded-lg bg-primary "
              >
                {processing ? (
                  <TailSpin width={20} height={20} color={`white`} />
                ) : (
                  "Confirm"
                )}
              </button>
            )}
            <button
              onClick={() => {
                setShow(false);
                setMsg({
                  error: null,
                  success: null,
                });
                setId(null);
              }}
              className="inline-flex items-center justify-center w-full px-3 py-2 text-center text-black rounded-lg bg-gray-500 "
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RefundableTable;

// <Pagination
// noOfPages={totalPages}
// currentPage={parseInt(pageNumber)}
// />
