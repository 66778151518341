import React, { Component } from "react";
import { Link } from "react-router-dom";
// import NotFoundImage from "../Assests/Images/PageNotFound.gif";
class NotFoundPage extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div>
          {/* <p>404 - Not Found!</p>
          <p>We are sorry but the page you are looking for does not exist.</p> */}
          <Link to="/" style={{ fontSize: "25px" }}>
            Let's go back
          </Link>
          {/* <img src={NotFoundImage} width="100%" height="" alt="" /> */}
          <div>
              <h1 style={{color:"red",fontSize:"80px",fontWeight:"700",textAlign:"center"}}>404</h1>
              <p>We are sorry but the page you are looking for does not exist.</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NotFoundPage;
