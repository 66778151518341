import React, { useEffect, useState } from "react";
// import Skeletons from "../../Components/ModuleComponents/Skeleton/transactionListSkeleton";
import ConfirmationModal from "../../Components/AbstractsComponents/ConfirmationModal/ConfirmationModal";
import Search from "../../Components/AbstractsComponents/Search";
// import VehiclController from "../../Controllers/vehicleController";
import axios from "axios";
import { useAuctions } from "../../Controllers/auctionController";
import {
  TBody,
  THead,
  TD,
  TH,
  TR,
  Tables,
} from "../../Components/AbstractsComponents/Tables";
import { TiArrowSortedUp } from "react-icons/ti";
import { Link, useSearchParams } from "react-router-dom";
import { useVehicles } from "../../Controllers/vehicleController";
import { TailSpin } from "react-loader-spinner";
import BreadCrumb from "../../Components/AbstractsComponents/BreadCrumb";
import Pagination from "../../Components/AbstractsComponents/Pagination";
import { SelectFieldV2 } from "../../Components/AbstractsComponents/InputFields";
import { useMetaData } from "../../Controllers/utilityController";
import { ToggleButton } from "../../Components/AbstractsComponents/ToggleButton";
import classNames from "classnames";
// import { useCallbackPrompt } from "../../Hooks/useCallbackPrompt";

const Vehicles = () => {
  const [searchParam, setSearchParams] = useSearchParams();
  const id = searchParam.get("id");
  const pageNumber = searchParam.get("pageNumber") || 0;
  const makeQuery = searchParam.get("make") || "";

  const { make } = useMetaData();
  console.log(make, "@make.....!");

  const { auctions } = useAuctions();
  const [businessId, setBusinessId] = useState(undefined);
  const [searchList, setSearchList] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [checked, setChecked] = useState(false);
  const [sortBy, setSortBy] = useState("offer");

  const [error, setError] = useState("");

  const filterParams = {
    businessId: businessId,
    pageSize: 5,
    pageNumber: pageNumber,
    make: makeQuery,
    notAuctionedYet: id ? true : false,
    isOfferedVehicles: checked,
    sortBy: sortBy,
  };

  const { vehiclesData, loading, totalPages } = useVehicles(filterParams);

  const handleUpdateQueryParam = (obj) => {
    if (typeof obj === "object") {
      for (const key in obj) {
        searchParam.set(key, obj[key]);
      }
      setSearchParams(searchParam);
    } else {
      // alert("you can pass only object in update params");
    }
  };

  const handleDeleteParams = (arr) => {
    if (Array.isArray(arr)) {
      arr.map((x) => searchParam.delete(x));
      setSearchParams(searchParam);
    } else {
      // alert("array of params required to delete.");
    }
  };

  const filterAuction = auctions?.find((a) => a._id === id);
  console.log("Filter Auction .....", filterAuction);

  useEffect(() => {
    if (!loading) {
      setSearchLoading(false);
    }
  }, [loading]);

  const TableHead = [
    { title: "Picture", callback: null, icon: null },
    { title: "Price", callback: null, icon: null },
    // { title: "Price Max", callback: null, icon: null },
    { title: "Make", callback: null, icon: null },
    { title: "Year", callback: null, icon: null },
    { title: "Transmission", callback: null, icon: null },
    { title: "Phone Link Pressed", callback: null, icon: null },
    { title: "No. Of Saves", callback: null, icon: null },
    {
      title: "No of Messages",
      callback: () => setSortBy("message"),
      icon: (
        <TiArrowSortedUp
          className={`${sortBy === "message" ? "text-blue-600" : ""}`}
        />
      ),
    },
    {
      title: "No of Offers",
      callback: () => setSortBy("offer"),
      icon: (
        <TiArrowSortedUp
          className={`${sortBy === "offer" ? "text-blue-600" : ""}`}
        />
      ),
    },
    { title: "Actions", callback: null, icon: null },
  ];

  // const [vehicles, setVehicles] = useState([]);

  console.log(disabled);

  const openModal = (vehicle) => {
    setModalIsOpen(true);
    setVehicle(vehicle);
    setError("");
  };

  // useEffect(() => {
  //   if (vehiclesData.length > 0) {
  //     setVehicles(vehiclesData);
  //   }
  // }, [vehiclesData]);

  // console.log("vehicles data.....", vehicles);

  const handleSelectByMake = (e) => {
    const value = e.target.value;
    if (value !== "") {
      handleUpdateQueryParam({ make: value });
    } else {
      setTimeout(() => {
        handleDeleteParams([`make`]);
      }, 500);
    }
  };

  const handleChange = (e) => {
    setSearch(e);
    if (e !== "") {
      setProcessing(true);
      axios
        .get(`/main-admin/business/getall-with-regex?queryText=${e}`)
        .then((res) => {
          if (res.data.success) {
            setProcessing(false);
            console.log("Res Search....", res.data.data);
            setSearchList(
              res.data.data.map((li) => ({
                _id: li._id,
                fullName: li.fullName,
                profilePicture: li.profilePicture.image.url,
              }))
            );
          }
        })
        .catch((err) => {
          console.log("Err Search....", err);
          setProcessing(false);
          setSearch("");
        });
    } else {
      setBusinessId(undefined);
    }
  };

  const handleSubmit = (id) => {
    if (!search.length > 0) {
      return;
    }
    setSearchLoading(true);
    setBusinessId(id);
    // VehiclController.getAllVehiclesByBusiness(id)
    //   .then((res) => {
    //     if (res) {
    //       setSearchLoading(false);
    //       setVehicles(res);
    //       setDisabled(true);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //     setSearchLoading(false);
    //     setSearch("");
    //     setDisabled(false);
    //   });
  };

  const handleClearFilter = () => {
    setSearch("");
    setSearchList([]);
    setDisabled(false);
    setBusinessId(undefined);
  };

  const handleAddVehicle = (id) => {
    console.log("ID..........", id);
    let isExit;

    let existed = filterAuction?.auctionCars?.find((item) => item === id);

    if (existed) {
      isExit = true;
    } else {
      isExit = false;
    }
    return isExit;
  };

  const pages = [
    { name: "Auctions", href: "/auctions", current: false },
    { name: "Auction Details", href: `/auction-info?id=${id}`, current: false },
    { name: "Vehicles", href: `/vehicles?id=${id}`, current: true },
  ];

  return (
    <>
      {id && <BreadCrumb pages={pages} className={`mt-3`} />}
      <div>
        {loading ? (
          <div className="flex items-center justify-center min-h-[70vh]">
            <TailSpin width={70} height={70} color={`blue`} />
          </div>
        ) : (
          <>
            <div className="flex flex-col w-full h-auto gap-6 py-8">
              <div className="flex justify-between">
                <h3 className="text-left font-semibold  text-[#566589]">
                  Vehicles
                </h3>

                <div className="flex items-center h-12 gap-4 sm:gap-6">
                  <ToggleButton
                    label={`Vehicles with Offers`}
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                  <SelectFieldV2
                    containerClass={
                      "!py-[3px]  sm:!py-[5px] !min-h-[50px] !min-w-[200px]"
                    }
                    // value={makeQuery}
                    className={"sm:!text-xs !text-[10px]"}
                    placeholder={makeQuery || `Select Make`}
                    defaultValue={`Select Make`}
                    options={make}
                    onChange={handleSelectByMake}
                    // defaultValue={makeQuery}
                    // defaultValue={`Select Field`}
                    // loading={loading}
                  />
                  <Search
                    handleChange={handleChange}
                    processing={processing}
                    list={searchList}
                    setSearchList={setSearchList}
                    search={search}
                    setSearch={setSearch}
                    handleSubmit={handleSubmit}
                    searchLoading={searchLoading}
                    handleClearFilter={handleClearFilter}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="bg-white min-h-[70vh]    rounded-3xl">
                <Tables>
                  <THead>
                    <TR>
                      {TableHead.map((ls, index) => (
                        <TH
                          className={`${
                            ls === "Actions"
                              ? "sticky right-0 rounded-tr-2xl bg-gray-400 z-10 text-white"
                              : ""
                          }  whitespace-nowrap cursor-pointer`}
                          key={index}
                          onClick={ls?.callback}
                        >
                          <div className="flex items-center gap-2">
                            {ls.title} <span>{ls.icon}</span>
                          </div>
                        </TH>
                      ))}
                    </TR>
                  </THead>
                  {vehiclesData.length > 0 ? (
                    <TBody className={`relative`}>
                      {vehiclesData?.map((vehicle, index) => {
                        const {
                          pictures,
                          make,
                          year,
                          _id,
                          transmission,
                          // driveTrain,
                          KMDriven,
                          noOfInComingMessages,
                          numberOfOffers,
                          // engine,
                          priceRange,
                          phoneLinkClick,
                        } = vehicle;

                        console.log("@vehicle", vehicle);

                        console.log("Price Range .....", priceRange);

                        return (
                          <TR key={_id}>
                            <TD>
                              <div className="w-40 h-40">
                                <img
                                  src={
                                    pictures[0]?.image?.url
                                      ? pictures[0]?.image?.url
                                      : "/Images/vehicle-placeholder.webp"
                                  }
                                  className="object-cover w-full h-full rounded-lg"
                                  alt="vehicle"
                                />
                              </div>
                            </TD>
                            <TD>${priceRange?.min}</TD>
                            {/* <TD>${priceRange?.max}</TD> */}
                            <TD>{make}</TD>

                            <TD>{year}</TD>
                            <TD>{transmission}</TD>
                            <TD>{phoneLinkClick || 0}</TD>
                            <TD>{vehicle?.offer?.length || 0}</TD>
                            <TD>{noOfInComingMessages}</TD>
                            <TD>{numberOfOffers}</TD>
                            {/* <TD>{engine}</TD>  */}

                            <TD
                              className={`sticky inset-y-0 my-auto right-0  `}
                            >
                              <div className="flex items-center h-full gap-2">
                                {id ? (
                                  <button
                                    onClick={() => openModal(vehicle)}
                                    className="px-3 py-2 font-semibold text-center text-white no-underline rounded-lg bg-primary"
                                  >
                                    {handleAddVehicle(_id) === true
                                      ? "Added"
                                      : "Add"}
                                  </button>
                                ) : (
                                  <>
                                    <Link
                                      className="px-3 py-2 font-semibold text-center text-white no-underline rounded-lg bg-primary"
                                      to={`/vehicles/modify-vehicle?id=${_id}`}
                                    >
                                      View
                                    </Link>

                                    <button
                                      className="px-3 py-2 font-semibold text-center text-white no-underline rounded-lg bg-primary"
                                      onClick={() =>
                                        window.open(
                                          `${process.env.REACT_APP_DELEGATE_URL}/?accessKey=${vehicle?.businessId?._id}-38e552d965994f65a0abe96310a15915&vehicleId=${vehicle?._id}`
                                        )
                                      }
                                      // to={`/vehicles/modify-vehicle?id=${_id}`}
                                    >
                                      Offers
                                    </button>
                                  </>
                                )}
                              </div>
                            </TD>
                          </TR>
                        );
                      })}
                    </TBody>
                  ) : (
                    <div className="text-center text-lg font-semibold text-[#566589]">
                      All Vehicles Are Auctioned
                    </div>
                  )}
                </Tables>
              </div>
            </div>

            <Pagination
              noOfPages={totalPages}
              currentPage={parseInt(pageNumber)}
            />
            <ConfirmationModal
              aId={id}
              vehicle={vehicle}
              show={modalIsOpen}
              setShow={setModalIsOpen}
              error={error}
              setError={setError}
              filterParams={filterParams}

              // vehicles={vehicles}
              // setVehicles={setVehicles}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Vehicles;
