import React from "react";
import { useAuctions } from "../../../Controllers/auctionController";
import AuctionForm from "./auctionForm";

const ModifyAuction = () => {
  useAuctions();
  return (
    <div className="w-full min-h-[70vh] py-10">
      <div className="flex flex-col gap-3">
        <h1 className="text-[#566589] text-2xl text-left font-bold">
          Create Auction
        </h1>
        <AuctionForm />
      </div>
    </div>
  );
};

export default ModifyAuction;
