import React, { useState } from "react";
//    importing components
import { Buttons } from "../../../AbstractsComponents/Button/button";
import ReduxDispatchController from "../../../../Controllers/reduxDispatchController";
import { useFormik } from "formik";
import { LogInSignIN } from "../../../../Validations/validations";
import { useNavigate } from "react-router-dom";
//    importing imags icon
import EyeIcon from "../../../../Assests/Icons/eye-outline.png";
//    importing css files
import "./signIn.css";
import AuthController from "../../../../Controllers/authController";
import { TextFields } from "../../../AbstractsComponents/TextFields/textFields";

export const SignIn = () => {
  const [LoaderButton] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const inputArr = [EyeIcon];
  let navigate = useNavigate();

  const SignInFormSubmit = (values) => {
    // ReduxDispatchController.AUTH.setAuthStatus(true)
    // navigate("/allBuisnesses?filter=all");
    // console.log("Signin form submit values");
    // console.log(values);
    // console.log("Signin form submit values");

    // console.log("Signin form submit values");
    // console.log(values);
    // console.log("Signin form submit values");
    setProcessing(true);
    AuthController.login(values.email, values.password, values.rememberMe)
      .then((loggedIn) => {
        console.log(`Logged in successfully`);
        setProcessing(false);
        navigate("/");
      })
      .catch((err) => {
        setErrorMessage(err);
        setProcessing(false);
        console.log(err);
      });
  };

  const { handleSubmit, getFieldProps, touched, errors } = useFormik({
    initialValues: {
      email: email,
      password: password,
      rememberMe: false,
    },
    validationSchema: LogInSignIN,
    onSubmit: SignInFormSubmit,
  });
  // const CallApi = () => {
  //   setLoaderButton(true, () => {
  //     axios.get("localhost:300").then((result) => setLoaderButton(false));
  //   });
  // };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="sign-in-main-div">
          <div className="sign-in-info-div">
            <form>
              <h2>Admin login!</h2>
              <p className="login-text">Log in to your account</p>
              <TextFields
                onChange={(e) => setEmail(e.target.value)}
                label={"username"}
                placeholder={"Enter your username"}
                name="email"
                id="email"
                type="email"
                {...getFieldProps("email")}
              />
              <span className="errorMessage" style={{ marginTop: "-10px" }}>
                {touched["email"] && errors["email"]}
              </span>
              <TextFields
                onChange={(e) => setPassword(e.target.value)}
                // textIconArr={inputArr}
                label={"Password"}
                placeholder={"Enter your password"}
                name="password"
                id="password"
                type="password"
                {...getFieldProps("password")}
              />
              <span className="errorMessage" style={{ marginTop: "-10px" }}>
                {touched["password"] && errors["password"]}
              </span>
            </form>
            <div className="remember-me-div">
              <div style={{ display: "flex" }}>
                <input {...getFieldProps("rememberMe")} type="checkbox" />
                <label
                  htmlFor="rememberMe"
                  id="rememberMe"
                  className="check-box-text"
                >
                  Remember me
                </label>
              </div>
              {/* <div className="forget-password-text" onClick={()=>navigate("/forgetPassword")}>
                  Forget Your Password?
                </div> */}
            </div>
            {/* <Link
            to={isValid ? "/pendingApproval" : ""}
            className="react-router-link"
          > */}
            <Buttons
              onClick={(e) => {
                handleSubmit();
              }}
              processing={processing}
              type="submit"
              style={{ height: "60px" }}
            >
              Log in
            </Buttons>
            <div style={{ height: 20 }} className="already-have-account-div">
              <span style={{ color: "red" }}>{errorMessage}</span>
            </div>
            {/* </Link> */}
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
