import { useEffect, useRef, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { AiFillCloseCircle } from "react-icons/ai";
import VehiclController from "../../../Controllers/vehicleController";
import { TailSpin } from "react-loader-spinner";
import ToastController from "../../../Controllers/toastController";
// import ConfirmationModal from "../../../Components/AbstractsComponents/ConfirmationModal/ConfirmationModal";
import Modal from "../../../Components/AbstractsComponents/Modal2/Modal";

const KnownIssues = ({
  data,
  className,
  vehicleId,
  onSubmit,
  isTouched,
  setIsTouched,
  save,
  setSave,
}) => {
  const [value, setValue] = useState("");
  const editFlag = useRef(false);
  const [issues, setIssues] = useState([]);
  const [editIndex, setEditIndex] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  // const [isTouched, setIsTouched] = useState(false);

  const toast = new ToastController();

  useEffect(() => {
    if (!editFlag.current) {
      setTimeout(() => {
        editFlag.current = true;
      }, 1000);
    }

    if (!!data) {
      setIssues(data);
    }
  }, [data]);

  //   useEffect(() => {
  //     // setTimeout(() => {
  //     //   editFlag.current = true;
  //     // }, 1000);
  //     console.log(isTouched, "@touched......");
  //   }, [isTouched]);

  useEffect(() => {
    if (editFlag.current) {
      setIsTouched(true);
      editFlag.current = false;
    }
  }, [issues]);

  const onSubmitIssues = () => {
    let vehicleData = isTouched ? { knownIssues: issues } : null;

    setSave(false);
    setIsTouched(false);
    console.log("@Data Modify vehicle..... ", data);
    setProcessing(true);
    toast.showProcessing("updating issues....");
    VehiclController.migrateVehicle(vehicleId, vehicleData)
      .then((res) => {
        console.log(res, "@known issues res........");
        toast.endProcessingWithSuccess("Known Issues Updated....");

        if (onSubmit) {
          onSubmit(res);
        }
        // setTimeout(() => {
        //   setProcessing(false);
        //     setProgress(0);
        //     navigate(`/vehicles`);
        // }, 500);

        setProcessing(false);
      })
      .catch((err) => {
        toast.endProcessingWithError(err);

        console.log(err, "@known issues error........");
        setProcessing(false);
      });
  };

  const onAdd = () => {
    setIssues([...issues, value]);
    setValue("");
  };

  const onEdit = (value, index) => {
    setValue(value);
    setEditIndex(index);
  };

  const onDelete = (index) => {
    let filterIssues = issues.filter((_, i) => i !== index);
    setIssues(filterIssues);
  };

  const onUpdate = () => {
    const issuesCopy = [...issues];

    if (editIndex >= 0) {
      issuesCopy[editIndex] = value;
      setIssues(issuesCopy);
      setEditIndex(undefined);
      setValue("");
    }
  };
  const handleAction = () => {
    if (value !== "") {
      editIndex >= 0 ? onUpdate() : onAdd();
    }
  };
  return (
    <>
      {save && (
        <Modal
          visible={save}
          wrapperClass={`max-w-[450px] w-full`}
          setVisible={setSave}
        >
          <div className="px-5 py-10 bg-white">
            <div className="flex flex-col gap-4">
              <h4 className="font-[600] text-lg">
                Do you want to save changes?
              </h4>
              <div className="grid grid-cols-2 gap-4">
                <button
                  onClick={onSubmitIssues}
                  className="p-2 text-white bg-blue-500 rounded-md"
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setSave(false);
                    setIsTouched(false);
                  }}
                  className="p-2 text-gray-700 bg-gray-300 rounded-md"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <div className={`relative py-6 ${className}`}>
        <div className="flex items-center justify-between">
          <h3 className="text-left font-semibold text-[#566589] mb-4">
            Known Issues
          </h3>

          <button
            disabled={!isTouched ? true : processing}
            type="button"
            onClick={onSubmitIssues}
            class="inline-flex justify-center disabled:cursor-not-allowed min-w-[130px] min-h-[35px] my-4 ml-auto items-center rounded-md border border-transparent bg-primary px-2 py-2 text-lg font-medium leading-4 text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          >
            {processing ? (
              <TailSpin
                height="20px"
                width="20px"
                color="white"
                ariaLabel="loading"
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
        <div>
          <div className="relative w-full overflow-hidden border rounded-xl">
            <input
              onKeyPress={(e) => e.key === "Enter" && handleAction()}
              className={`block w-full   focus:outline-none shadow-none p-2`}
              type={`text`}
              placeholder={`Add Known Issues`}
              name={`knownIssues`}
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
            <button
              type="button"
              className="absolute inset-y-0 my-auto min-w-[80px] h-full px-2 right-0 flex justify-center items-center bg-green-700 text-white"
              onClick={handleAction}
            >
              {editIndex >= 0 ? "Update" : "Add"}
            </button>
          </div>
          <div className="flex flex-col items-center">
            <ul className=" w-full  py-2 flx flex-col gap-1 !px-0">
              {issues?.length > 0 &&
                issues?.map((item, index) => {
                  return (
                    <li
                      className="relative flex items-center justify-between w-full gap-1 px-3 py-2 text-lg text-gray-500 border-b whitespace-nowrap"
                      key={index}
                    >
                      <p className="!m-0 py-3 text-dark1">{item}</p>

                      <div className="flex gap-2">
                        <button
                          type="button"
                          onClick={() => onEdit(item, index)}
                        >
                          <FiEdit className="text-xl" />
                        </button>
                        <button type="button" onClick={() => onDelete(index)}>
                          <AiFillCloseCircle className="text-xl text-red-400 " />
                        </button>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default KnownIssues;
