import { ERRORS } from "./errorMessages";
import axios from "axios";
import ReduxDispatchController from "./reduxDispatchController";
import ToastController from "./toastController";
import { useSelector } from "react-redux";
import store from "../Store";
import { useEffect, useState } from "react";
import AuthController from "./authController";
class BusinessesController {
  static getBuyers(
    paramsObj = {
      limit: 10,
      pageNo: 0,
    }
  ) {
    return new Promise((resolve, reject) => {
      // console.log(`AuthToken: ${axios.defaults.headers.common['Authorization']}` )
      axios
        .get(`/main-admin/buyers/get-all`, { params: paramsObj })
        .then((res) => {
          console.log(res.data, "@buyers....");
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            console.log(res.data, "@buyers errr....");

            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err, "@buyers errr....");

          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }
}
export default BusinessesController;

export const useBuyers = (
  qryObj = {
    pageNo: 0,
  },
  currentPage
) => {
  const [loading, setLoading] = useState(false);
  const buyers = useSelector((state) => state.BuyersReducer?.buyers);
  const { pageNo } = qryObj;

  const handleGetBuyers = () => {
    console.log("@auction hook runnging.....");
    setLoading(true);
    BusinessesController.getBuyers(qryObj)
      .then((res) => {
        ReduxDispatchController.BUYERS.set(res);
        setLoading(false);
        console.log(res, "@buyers buyers in hook.......");
      })
      .catch((err) => {
        console.log(err, "@buyers buyers error in hook....");
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetBuyers();
  }, [pageNo]);

  return {
    loading: loading,
    buyersData: buyers?.buyers || [],
    totalPages: buyers?.pages,
    currentPage: buyers?.pageNo,
  };
};
