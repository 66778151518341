import React, { useState } from "react";
//    importing components
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { AllBuisnessesTableButton } from "../../../ModuleComponents/AllBuisnessTableButton/allBuisnessTableButton";
import { Modals } from "../../../../Components/AbstractsComponents/Modal/modal";
import { SetUpPaymentAmountModal } from "../../../../Components/CompositeComponents/WalletModal/walletModal";
//    importing images
import CarImage from "../../../../Assests/Images/image 2.png";
//    importing icons
//    imoporting css files
import "./allBuisnessesTable.css";
import BusinessesController from "../../../../Controllers/businessesController";
import { Link } from "react-router-dom";
var products = [
  {
    BuisnessPicture: CarImage,
    FullName: "Example Name",
    email: "buisness@gmail.com",
    actions: "Pending",
    recieveAble: "",
  },
  {
    BuisnessPicture: CarImage,
    FullName: "Example Name",
    email: "buisness@gmail.com",
    actions: "Accept",
    recieveAble: 100,
  },
  {
    BuisnessPicture: CarImage,
    FullName: "Example Name",
    email: "buisness@gmail.com",
    actions: "Pending",
    recieveAble: "",
  },
  {
    BuisnessPicture: CarImage,
    FullName: "Example Name",
    email: "buisness@gmail.com",
    actions: "Pending",
    recieveAble: "",
  },
  {
    BuisnessPicture: CarImage,
    FullName: "Example Name",
    email: "buisness@gmail.com",
    actions: "Accept",
    recieveAble: 600,
  },
];
const imageFormatter = (cell, row) => {
  return (
    <img
      style={{ width: 70, height: 70, borderRadius: 10, objectFit: "cover" }}
      src={cell}
      alt=""
    />
  );
};
const renderShowsTotal = (to, total) => {
  return (
    <p className="show-table-data-text">
      Showing {to} from {total}&nbsp;&nbsp;
    </p>
  );
};
function RecieveAbleFormat(cell, row) {
  return (
    <div style={{ display: "flex" }}>
      {cell === "" ? "----" : <span>{cell}</span>}
    </div>
  );
}
function ActionButton(data, row) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  console.log(`@payload`, data.row);

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {data.cell === BusinessesController.APPROVAL_STATUS.APPROVED ? (
        <>
          <button
            className="bg-primary text-white no-underline py-2 px-6 mr-2 rounded-lg"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_DELEGATE_URL}/?accessKey=${data.row.payload._id}-38e552d965994f65a0abe96310a15915`
              )
            }
          >
            Delegate access
          </button>
          <div>
            <button
              className="all-buisness-recieved-button"
              onClick={(e) => openModal(e)}
            >
              Add Payment
            </button>
            <Modals modalIsOpen={modalIsOpen} closeModal={closeModal}>
              <SetUpPaymentAmountModal
                closeModal={closeModal}
                // recievebleAmount={data.row.recieveAble}
                business={data.row.payload}
              />
            </Modals>
          </div>
        </>
      ) : (
        <button style={{ visibility: "hidden" }}></button>
      )}
      <AllBuisnessesTableButton
        isProcessing={isProcessing}
        onChange={(e) => setIsProcessing(e)}
        selectedValue={data.cell}
        business={data.row}
      />
    </div>
  );
}

export const VehicleTable = ({ businesses }) => {
  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: products.length,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 10, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 4, // the pagination bar size.
    prePage: "<<", // Previous page button text
    nextPage: ">>", // Next page button text
    firstPage: ">", // First page button text
    lastPage: ">>", // Last page button text
    paginationShowsTotal: renderShowsTotal, // Accept bool or function
    hidePageListOnlyOnePage: true,
  };
  return (
    <div style={{ display: "block", height: "70vh" }}>
      <BootstrapTable
        data={businesses}
        options={options}
        bodyContainerClass="my-custom-body"
        headerStyle={{ padding: "0px 0px" }}
        tableStyle={{ borderRadius: "30px", border: "none" }}
        tableContainerClass="my-custom-table-class"
        bodyStyle={{
          overflow: "overlay",
          // height: "90vh",
          // height: "calc(100vh - 350px)",
          background: "#ffffff",
          borderRadius: "30px",
        }}
        maxHeight="500px"
        pagination={true}
      >
        <TableHeaderColumn
          thStyle={{ padding: "20px 40px" }}
          tdStyle={{ padding: "10px 0px 10px 40px", verticalAlign: "middle" }}
          width="160px"
          className="td-header-string-example"
          isKey
          dataField="pictureUrl"
          dataFormat={imageFormatter}
        >
          <div style={{ display: "flex" }}>Picture</div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="fullName"
          tdStyle={{
            color: "#16478E",
            fontWeight: "700",
            verticalAlign: "middle",
          }}
          width="180px"
          className="td-header-string-example"
        >
          <div style={{ display: "flex" }}>Full Name</div>
        </TableHeaderColumn>

        <TableHeaderColumn
          className="td-header-string-example"
          width="250px"
          tdStyle={{
            padding: "10px",
            fontWeight: "700",
            verticalAlign: "middle",
          }}
          dataField="email"
        >
          <div style={{ display: "flex" }}>Email</div>
        </TableHeaderColumn>
        <TableHeaderColumn
          className="td-header-string-example"
          width="130px"
          tdStyle={{
            padding: "10px",
            fontWeight: "700",
            verticalAlign: "middle",
          }}
          dataField="recievable"
          dataFormat={RecieveAbleFormat}
        >
          <div style={{ display: "flex" }}>Payable</div>
        </TableHeaderColumn>
        <TableHeaderColumn
          className="td-header-string-example"
          dataField="action"
        ></TableHeaderColumn>
        <TableHeaderColumn
          className="td-header-string-example"
          tdStyle={{
            padding: "10px 0px 10px 0px",
            borderRadius: "30px",
            fontWeight: "700",
            verticalAlign: "middle",
            position: "sticky",
            right: "20px",
            textAlign: "right",
          }}
          thStyle={{
            position: "sticky",
            right: "30px",
            backgroundColor: "white",
            borderRadius: "30px",
          }}
          dataFormat={(cell, row) => <ActionButton cell={cell} row={row} />}
          dataField="actions"
          width="450px"
        >
          <div style={{ display: "flex" }}>Action</div>
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  );
};
