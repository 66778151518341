import React, { useEffect, useState } from "react";
import SlidePanel from "../../../AbstractsComponents/SlidingPane/slidingPane";
import { Buttons } from "../../../AbstractsComponents/Button/button";
import TransactionsController from "../../../../Controllers/transactionsController";
import { useOffers } from "../../../../Controllers/offersController";

export const TransactionStatusSlidingPane = ({ offer }) => {
  const [visible, setVisible] = useState(false);
  const [processing, setProcessing] = useState(false);
  const isDispute = offer.transactionStatus === "disputed";

  console.log(offer, "@offer id........");

  const handleResolveDispute = () => {
    const transactionId = offer.transactionId;
    setProcessing(1);
    TransactionsController.closeDeal(transactionId, offer._id, "resolved")
      .then(() => {
        setProcessing(false);
        setVisible(false);
      })
      .catch((err) => {
        setProcessing(false);
        setVisible(false);
      });
  };

  const handleCancelDeal = () => {
    const transactionId = offer.transactionId;
    setProcessing(2);
    TransactionsController.closeDeal(transactionId, offer._id, "cancelled")
      .then(() => {
        setProcessing(false);
        setVisible(false);
      })
      .catch((err) => {
        setProcessing(false);
        setVisible(false);
      });
  };

  if (isDispute) {
    return (
      <div>
        <a
          className="slide-open-button"
          style={{ color: "red" }}
          onClick={() => setVisible(true)}
        >
          {offer.transactionStatus}
        </a>
        <SlidePanel
          width={"40%"}
          visible={visible}
          title={offer.transactionStatus}
          onClose={() => setVisible(false)}
        >
          <div>
            <br />
            <h4>Reason:</h4>
            <h6>{offer.disputeReason}</h6>
            <br />
            <Buttons
              onClick={handleResolveDispute}
              disabled={processing === 2}
              processing={processing === 1}
              style={{
                width: "200px",
                height: "40px",
              }}
            >
              Resolve Conflict
            </Buttons>
            <br />
            <Buttons
              onClick={handleCancelDeal}
              processing={processing === 2}
              disabled={processing === 1}
              style={{
                width: "200px",
                height: "40px",
              }}
            >
              Cancel Deal
            </Buttons>
          </div>
        </SlidePanel>
      </div>
    );
  } else {
    return (
      <div>
        <a onClick={() => setVisible(true)}>{offer.transactionStatus}</a>
      </div>
    );
  }
};
