import { useState } from "react";
import GoogleMapPicker from "../../../ModuleComponents/GoogleMapPicker";
import "./textFields.css";
import { BiCurrentLocation } from "react-icons/bi";

export const LocationTextField = ({
  id,
  label,
  value,
  type,
  onChange,
  onBlur,
  name,
  placeholder,
  onLoactionChange,
  onClick,
}) => {
  const [show, setShow] = useState(false);

  const handleOnFocus = () => {
    if (value === "") {
      setShow(true);
    }
  };

  return (
    <>
      <div>
        <label
          className="!text-dark1 !font-bold !text-sm mb-[2px]  add-vehicle-label"
          htmlFor={id}
        >
          {label}
        </label>
        <div className="relative w-full">
          <input
            value={value}
            type={type}
            id={id}
            className="add-vehicle-input"
            onChange={onChange}
            onFocus={handleOnFocus}
            onBlur={onBlur}
            name={name}
            placeholder={placeholder}
          />
          {/* {icon ? ( */}
          <div
            className={"absolute right-2 h-max inset-y-0 my-auto"}
            onClick={() => setShow(true)}
          >
            {/* <img src={icon} alt="" /> */}
            <BiCurrentLocation className="text-3xl text-primary" />
          </div>
          {/* ) : null} */}
        </div>
      </div>

      <GoogleMapPicker
        onChange={onLoactionChange}
        show={show}
        setOpen={setShow}
      />
    </>
  );
};
