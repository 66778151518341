import React, { useState } from 'react';
import SlidePanel from '../../../AbstractsComponents/SlidingPane/slidingPane';
export const BuisnessSlidingPaneBtn = ({business}) =>{
    const [visible,setVisible] = useState(false)
    // console.log("=>Business:", business)
    return(
        <div>
            <a className='slide-open-button' onClick={()=>setVisible(true)}>{business.fullName}</a>
            <SlidePanel width={"40%"} visible={visible} title={business.fullName} onClose={()=>setVisible(false)}>
                <div>
                    <p>Some details of buisness</p>
                </div>
            </SlidePanel>
        </div>
    )
}