import React, { useState } from "react";
import SlidePanel from "../../../AbstractsComponents/SlidingPane/slidingPane";
export const BuyerSlidingPaneBtn = ({ buyer }) => {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <a className="slide-buyer-open-button" onClick={() => setVisible(true)}>
        {buyer?.fullName}
      </a>
      <SlidePanel
        width={"40%"}
        visible={visible}
        title={buyer?.fullName}
        onClose={() => setVisible(false)}
      >
        <div>
          <img
            src={buyer?.profilePicture?.image?.url}
            style={{
              width: 200,
              height: 200,
              objectFit: "cover",
              backgroundColor: "#f0f0f0",
            }}
          />
          <br />
          <span>{`username: ${buyer?.username}`}</span>
          <br />
          <span>{`PhoneNumber: ${buyer?.phoneNumber}`}</span>
        </div>
      </SlidePanel>
    </div>
  );
};
