import React from "react";
//     importing componants
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AuthRouting } from "./authRouting";
import { DashBoardRouting } from "./dashBoardRouting";
import { useSelector } from "react-redux";
//      Auth routing screens
import { SignInScreen } from "../Screens/SignInScreen/signInScreen";
import NotFoundPage from "./notFoundPage";
//      dashboard routing screens
import { AllBusinessesScreen } from "../Screens/AllBuisnessesScreen/allBuisnessesScreen";
import { TransactionsScreen } from "../Screens/TransactionsScreen/transactionsScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auctions from "../Screens/Auction/index";
import SingleAuction from "../Screens/Auction/SingleAuction";
import Vehicles from "../Screens/Vehicles";
import ModifyVehicle from "../Screens/Vehicles/ModifyVehicle/index";
import ModifyAuction from "../Screens/Auction/Modify Auction/index";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import history from "../Hooks/history";
import Buyers from "../Screens/Buyers/index";
import Refundable from "../Screens/Refundable";

export const Routing = () => {
  // const isAuthenticateUser = useSelector(
  //   (state) => state.InfoRiderReducrs.UserAuthenticate
  // );
  // console.log(isAuthenticateUser);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        // theme="colored"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <HistoryRouter history={history}>
        {/* {isAuthenticateUser?
      <DashBoardRouting />
      :
      <AuthRouting />
     } */}

        <Routes>
          <Route path="auth" element={<AuthRouting />}>
            <Route path="" element={<SignInScreen />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>

          <Route path="" element={<DashBoardRouting />}>
            <Route path="" element={<Navigate to="/allBuisnesses" />} />
            <Route path="/allBuisnesses" element={<AllBusinessesScreen />} />
            <Route path="/conflicts" element={<TransactionsScreen />} />
            <Route path="/auctions" element={<Auctions />} />
            <Route path="/auction-info" element={<SingleAuction />} />
            <Route path="/vehicles" element={<Vehicles />} />
            <Route path="/buyers" element={<Buyers />} />
            <Route path="/refundable" element={<Refundable />} />
            <Route path="/modify-auction" element={<ModifyAuction />} />
            <Route
              path="/vehicles/modify-vehicle"
              element={<ModifyVehicle />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </HistoryRouter>
    </>
  );
};
