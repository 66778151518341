import * as Yup from "yup";

//    Login User Schema
export const LogInSignIN = Yup.object().shape({
  email: Yup.string().required("*username required"),
  password: Yup.string()
    .required("*password required.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  // .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
});

// export const AuctionSchema = Yup.object.shape({
//   title: Yup.string().required("Auction title required!"),
//   startDate: Yup.string().required("Start Date Reqquired!"),
//   startTime: Yup.string().required("Start Time Reqquired!"),
//   endDate: Yup.string().required("End Date Reqquired!"),
//   endTime: Yup.string().required("End Time Reqquired!"),
//   location: Yup.object().required("Location required!"),
// });
