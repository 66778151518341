import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./Store";
import axios from "axios";
import { Provider } from "react-redux";
import { BASE_URL } from "./proxy";
axios.defaults.baseURL = BASE_URL;
// axios.defaults.headers.common["Authorization"] =
//   "Bearer Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOnsicGhvbmVOdW1iZXIiOiIrOTIzMTE3MTIxNTk4In0sImlhdCI6MTY0MzAyMjU1MiwiZXhwIjoxNjQzMDI2MTUyfQ.1LIAQP_uBGrMP4ExelSTMAceDPebOzzy77QYXadLw-4";
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
