import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import OutsideClickHandler from "react-outside-click-handler";
import { TiDelete } from "react-icons/ti";
import { useSearchParams } from "react-router-dom";

const Search = ({
  handleChange,
  processing,
  list,
  setSearchList,
  search,
  setSearch,
  handleSubmit,
  searchLoading,
  handleClearFilter,
  disabled,
}) => {
  console.log("list", list);
  const [params, setParams] = useSearchParams();

  const [id, setId] = useState(null);
  console.log("ID", id);

  const handleUpdateQueryParam = (obj) => {
    if (typeof obj === "object") {
      for (const key in obj) {
        params.set(key, obj[key]);
      }
      setParams(params);
    } else {
      alert("you can pass only object in update params");
    }
  };

  return (
    <div className="max-w-[350px] flex flex-col gap-2 w-full mr-2 relative">
      <div className="flex w-full h-10 ">
        <div className="flex items-center w-full px-2 border-2 rounded-md border-primary">
          <input
            type={"text"}
            placeholder="Search By Dealers"
            value={search}
            disabled={disabled}
            required
            onChange={(e) => handleChange(e.target.value)}
            className="w-full h-full bg-transparent border-none outline-none focus:border-none "
          />
          <div className="flex items-center h-full gap-2 ">
            {search.length > 0 && (
              <button onClick={handleClearFilter}>
                <TiDelete className="text-xl text-[#566589]" />
              </button>
            )}
            {processing && <TailSpin width={20} height={20} color="blue" />}
          </div>
        </div>
        {/* <button
          onClick={() => handleSubmit(id)}
          className="h-full px-3 text-sm font-bold text-white bg-primary rounded-r-md"
        >
          {searchLoading ? (
            <TailSpin width={20} height={20} color="white" />
          ) : (
            "Search"
          )}
        </button> */}
      </div>
      <OutsideClickHandler onOutsideClick={() => setSearchList([])}>
        <div className="absolute top-14 left-0   w-full z-[1000]">
          {list.length > 0 && (
            <div className="flex flex-col items-center w-full h-auto py-4 bg-white shadow-md rounded-xl">
              {list.map((i) => (
                <div
                  key={i._id}
                  onClick={() => {
                    setSearch(i.fullName.replaceAll('"', ""));
                    setSearchList([]);
                    setId(i._id);
                    handleSubmit(i._id);
                    handleUpdateQueryParam({
                      pageNumber: 0,
                    });
                  }}
                  className="flex items-center justify-between w-full px-10 pt-1 text-left text-gray-700 capitalize cursor-pointer hover:text-white hover:bg-blue-500"
                >
                  <h3 className="text-lg font-semibold capitalize">
                    {i.fullName.replaceAll('"', "")}
                  </h3>

                  <img
                    src={i?.profilePicture}
                    alt="profile"
                    className="object-cover w-6 h-6 rounded-full"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default Search;
