import React, { useEffect, useState } from "react";
import VehiclController from "../../../Controllers/vehicleController";
import {
  Tables,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from "../../../Components/AbstractsComponents/Tables";
import { formatCurrency } from "../../../Utils/helperFunctions";
import { TailSpin } from "react-loader-spinner";
import Pagination from "../../../Components/AbstractsComponents/Pagination";

const BuyNow = ({ vehicleId }) => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setLoading(true);
    VehiclController.getAllBuyNowOffersOfVehicle(vehicleId)
      .then((res) => {
        setOffers(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log("buynow err....", err);
        setErrMsg(err);
        setLoading(false);
      });
  }, []);

  const TableHead = [
    "Profile Picture",
    "Full Name",
    "Phone Number",
    "Buy Now Amount",
    "Offer Status",
  ];

  console.log("@buynow offers...", offers);
  return !loading ? (
    <div className="pt-4">
      <h3 className="text-left font-semibold text-[#566589] mb-4">
        Buy Now Offers
      </h3>

      <Tables>
        <THead>
          <TR>
            {TableHead.map((ls, index) => (
              <TH
                className={
                  ls === "Actions"
                    ? "sticky right-0 bg-gray-400 z-10 text-white"
                    : ""
                }
                key={index}
              >
                {ls}
              </TH>
            ))}
          </TR>
        </THead>
        {offers?.length > 0 ? (
          <TBody className={`relative table-row-group`}>
            {offers?.map((offer, index) => {
              const { _id, amount, buyer, business, vehicle, status } = offer;

              return (
                <TR key={_id}>
                  <TD>
                    <div className="w-16 h-16">
                      <img
                        src={
                          buyer?.profilePicture?.image?.url
                            ? buyer?.profilePicture?.image?.url
                            : "/Images/vehicle-placeholder.webp"
                        }
                        className="object-cover w-full h-full rounded-lg"
                        alt="vehicle"
                      />
                    </div>
                  </TD>
                  <TD className={`capitalize font-[700]`}>{buyer?.fullName}</TD>
                  <TD>{buyer?.phoneNumber}</TD>
                  <TD>${amount > 0 ? formatCurrency(amount) : 0}</TD>
                  <TD className={`capitalize `}>{status}</TD>
                </TR>
              );
            })}
          </TBody>
        ) : (
          <div className="text-center text-lg font-semibold text-[#566589]">
            No Data Found
          </div>
        )}
      </Tables>
    </div>
  ) : (
    <div className="w-full min-h-[50vh] flex items-center justify-center">
      <TailSpin width={80} height={80} color={`blue`} />
    </div>
  );
};

export default BuyNow;
