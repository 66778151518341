import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import Modal from "../Modal2/Modal";
import AuctionController from "../../../Controllers/auctionController";
import { TextFields } from "../TextFields/textFields";

const ConfirmationModal = ({
  show,
  setShow,
  aId,
  vehicle,
  error,
  setError,
  filterParams,
  // vehicles,
  // setVehicles,
}) => {
  console.log("aId vId........", aId, vehicle);

  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);

  const [input, setInput] = useState({
    initialBidAmount: "",
    bidGap: "100",
    buyNowPrice: "",
  });

  useEffect(() => {
    if (vehicle) {
      setInput({
        ...input,
        buyNowPrice: vehicle?.priceRange?.min,
      });
    }
  }, [vehicle]);

  useEffect(() => {
    return () => console.log("Helloo........");
  }, []);

  const handleOk = () => {
    setSuccess(false);
    setShow(false);
    setError("");
  };

  const handleSubmit = () => {
    if (!input.bidGap || !input.initialBidAmount || !input.buyNowPrice) {
      return;
    } else {
      setProcessing(true);
      AuctionController.addNewVehicle({
        initialBidAmount: parseInt(input.initialBidAmount),
        bidGap: parseInt(input.bidGap),
        buyNowPrice: parseInt(input.buyNowPrice),
        auction: aId,
        vehicle: vehicle._id,
      })
        .then((res) => {
          if (res) {
            setProcessing(false);
            setSuccess(true);
            setInput({
              initialBidAmount: "",
              bidGap: "100",
              buyNowPrice: "",
            });
            setError("");

            // const newVehiclesData = vehicles.filter((v) => v._id !== vId);
            // setVehicles(newVehiclesData);
          }
        })
        .catch((err) => {
          console.log("Err....", err);
          setError(err);
          setSuccess(false);
          setProcessing(false);
          setInput({
            initialBidAmount: "",
            bidGap: "100",
            buyNowPrice: "",
          });
        });
    }
  };

  // const handleChnage = (e) => {
  //   setInput({
  //     ...input,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  return (
    <Modal
      visible={show}
      closeModal={() => setShow(false)}
      wrapperClass={`max-w-[450px] w-full`}
    >
      <div className="w-full p-10 bg-white rounded-md min-h-[150px] ">
        <h3 className="mb-4 font-bold capitalize">
          {success
            ? "Added successfully"
            : error !== ""
            ? error
            : "Add Vehicle"}
        </h3>

        <div className="flex gap-3">
          {success ? (
            <button
              onClick={handleOk}
              className="w-full px-3 py-2 text-center text-white rounded-lg bg-primary "
            >
              ok
            </button>
          ) : (
            <div className="flex flex-col w-full gap-3">
              <div className="relative w-full flex flex-col gap-1">
                <label
                  className="text-xs font-semibold"
                  htmlFor="initialBidAmount"
                >
                  Initial Bid Amount
                </label>
                <input
                  type={`number`}
                  min={100}
                  placeholder="Initial Bid Amount"
                  name="initialBidAmount"
                  value={input.initialBidAmount}
                  className="h-10 pl-3 border border-gray-500 rounded-md"
                  onChange={(e) =>
                    setInput({ ...input, initialBidAmount: e.target.value })
                  }
                />
              </div>

              <div className="relative w-full flex flex-col gap-1">
                <label className="text-xs font-semibold" htmlFor="bidGap">
                  Initial Bid Gap
                </label>
                <input
                  type={`number`}
                  min={1}
                  name="bidGap"
                  value={input.bidGap}
                  placeholder="Initial Bid Gap"
                  className="h-10 pl-3 border border-gray-500 rounded-md"
                  onChange={(e) =>
                    setInput({ ...input, bidGap: e.target.value })
                  }
                />
              </div>

              <div className="relative w-full flex flex-col gap-1">
                <label className="text-xs font-semibold" htmlFor="buyNowPrice">
                  Buy Now Price
                </label>
                <input
                  type={`number`}
                  min={1}
                  name="buyNowPrice"
                  value={input.buyNowPrice}
                  placeholder="Buy Now Price"
                  className="h-10 pl-3 border border-gray-500 rounded-md"
                  onChange={(e) =>
                    setInput({ ...input, buyNowPrice: e.target.value })
                  }
                />
              </div>

              <button
                onClick={handleSubmit}
                className="inline-flex items-center justify-center w-full px-3 py-2 text-center text-white rounded-lg bg-primary "
              >
                {processing ? (
                  <TailSpin
                    height="20px"
                    width="20px"
                    color="white"
                    ariaLabel="loading"
                  />
                ) : error !== "" ? (
                  "retry"
                ) : (
                  "Confirm"
                )}
              </button>

              {/*<button
            //     className="w-full px-3 py-2 text-center bg-gray-300 rounded-lg text-dark1 "
            //     onClick={() => {
            //       setShow(false);
            //       setError("");
            //     }}
            //   >
            //     No
                //   </button>*/}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
