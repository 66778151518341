import { OFFERS } from "../types";
import produce from "immer";
const state = {
  offers: [],
};

const OffersReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case OFFERS.SET_ALL_OFFERS:
      return produce(mState, (draftState) => {
        draftState.offers = action.payload;
      });
    case OFFERS.SET_UPDATE_OFFER:
      return produce(mState, (draftState) => {
        const offerIndex = draftState.offers.findIndex(
          (offer) => offer._id === action.payload._id
        );
        if (offerIndex >= 0) {
          draftState.offers[offerIndex] = action.payload;
        }
      });
    default:
      return { ...mState };
  }
};
export default OffersReducer;
