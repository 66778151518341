import React from "react";
import RefundableTable from "./refundableTable";

const Refundable = () => {
  return (
    <div>
      <RefundableTable />
    </div>
  );
};

export default Refundable;
