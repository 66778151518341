import { createStore,applyMiddleware,compose } from "redux";
import rootReducer from "./Reducers";
import thunk from "redux-thunk"

const initState={
    
};
const middleWares=[thunk];
const store=createStore(
    rootReducer,
    initState,
    compose(
        applyMiddleware(...middleWares),
        window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f           
 )
);

export default store;