import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ERRORS } from "./errorMessages";
import ReduxDispatchController from "./reduxDispatchController";
import ToastController from "./toastController";
class TransactionsController {
  static toast = new ToastController();
  static ADAPTER = {
    transform: (t) => {
      return {
        business: t.business,
        vehicle: t.vehicle,
        buyer: t.buyer,
        dateTime: t.createdAt,
        amount: t.offer?.amount,
        offer: {
          ...t.offer,
          transactionId: t._id,
          transactionStatus: t.status,
          disputeReason: t.disputeReason,
        },
        payload: t,
      };
    },
  };

  static getAll() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/transaction/admin/get-admin-all-transactions`)
        .then((res) => {
          console.log(res.data, "@transactions res...");
          if (res.data.success) {
            resolve({
              transactions: res.data.data,
              totalReceivable: res.data.totalReceivable,
            });
          } else {
            reject([]);
          }
        })
        .catch((err) => {
          console.log(`CATCH Error in loading transactions`);
          console.log(err);
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }
  static handleLoadTransactions() {
    ReduxDispatchController.TRANSACTIONS.setLoading(true);
    TransactionsController.getAll()
      .then(({ transactions, totalReceivable }) => {
        console.log(transactions, "@test 1.......");
        ReduxDispatchController.TRANSACTIONS.set(
          transactions.map((t) => TransactionsController.ADAPTER.transform(t)),
          totalReceivable
        );
        console.log(transactions, "@test 2.......");

        ReduxDispatchController.TRANSACTIONS.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        ReduxDispatchController.TRANSACTIONS.setLoading(false);
      });
  }

  static closeDeal(transactionId, offerId, status) {
    return new Promise((resolve, reject) => {
      console.log({ status, offerId, transactionId }, "@dispute...");

      axios
        .post(`/offer/offer/resolve-dispute/${offerId}`, {
          transactionStatus: status,
        })
        .then((res) => {
          console.log(res.data, "@dispute...");
          if (res.data.success) {
            // TransactionsController.handleLoadTransactions();
            ReduxDispatchController.TRANSACTIONS.setStatus(
              transactionId,
              status
            );
            resolve({ _id: transactionId, status: res.data.data.status });
          } else {
            ToastController.error(res.data.error.message);
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getRefundable() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/offer/offer/get-refundable-refunded-offers`)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  static cancelDeal() {
    return new Promise((resolve, reject) => {});
  }
}

export default TransactionsController;

export const useTransactions = () => {
  useEffect(() => {
    TransactionsController.handleLoadTransactions();
  }, []);
  return useSelector((state) => ({
    transactions: state.TransactionsReducer.transactions,
    totalReceivable: state.TransactionsReducer.totalReceivable,
    loading: state.TransactionsReducer.loading,
  }));
};
