import React, { useState } from "react";
import styles from "./GoogleMapComponent.module.css";
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
} from "@react-google-maps/api";
import { BiCurrentLocation } from "react-icons/bi";
// import { IoNavigate } from "react-icons/io5";
import CustomMarker from "../CustomMarker";
import { TailSpin } from "react-loader-spinner";
import Geocode from "react-geocode";
import { useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

const GoogleMapComponent = ({ setOpen, onChange }) => {
  const containerStyle = {
    width: "100%",
    height: "calc(100vh - 100px)",
  };

  const [liveLocation, setLiveLocation] = useState({
    lat: 40.7129032,
    lng: -74.0063033,
  });
  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [markerLatLng, setMarkerLatLng] = useState(liveLocation);
  const [markerAddress, setMarkerAddress] = useState();
  const [isMarker, setIsMarker] = useState(true);
  const [processing, setProcessing] = useState(false);

  const handleMarkerAddress = () => {
    setProcessing(true);
    Geocode.setApiKey("AIzaSyDYL1lb12inoSM8uVoI5N1W0cZwcbtEM54");
    Geocode.fromLatLng(markerLatLng.lat, markerLatLng.lng).then(
      (response) => {
        console.log("Response geoCode....", response);
        const address = response.results[0];
        const area = response.results[7]
          ? response.results[7]
          : response.results[2]
          ? response.results[2]
          : "";
        console.log(address, "address");

        const formattedAddress = {
          location: address.geometry.location,
          address: address.formatted_address,
          zip: "",
          payload: address,
          markerPosition: markerLatLng,
          area: area?.formatted_address,
        };

        setMarkerAddress(formattedAddress);
        if (onChange) {
          onChange(formattedAddress);
        }
        // setOpen();
        setProcessing(false);
      },
      (error) => {
        console.error(error);
        setProcessing(false);
      }
    );
  };

  // on load auto complete

  const onLoadAutoComplete = (autocompleteinstance) => {
    setAutocomplete(autocompleteinstance);
  };

  // on place changed

  const onPlaceChanged = () => {
    if (autocomplete) {
      let position = {
        lat: autocomplete.getPlace().geometry.location.lat(),
        lng: autocomplete.getPlace().geometry.location.lng(),
        payload: autocomplete,
      };
      setMarkerLatLng(position);
      map.panTo(position);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  useEffect(() => {
    handleMarkerAddress();
    console.log(liveLocation, markerLatLng, "location changed...........");
  }, [liveLocation]);

  const onLiveLocation = () => {
    map.panTo(liveLocation);
    setMarkerLatLng(liveLocation);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDYL1lb12inoSM8uVoI5N1W0cZwcbtEM54",
    libraries: ["places"],
  });

  const onLoad = React.useCallback(
    function callback(map) {
      setMap(map);

      navigator?.geolocation.getCurrentPosition((pos) => {
        if (pos?.coords?.latitude && pos?.coords?.longitude) {
          let currentLocation = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          };
          setMarkerLatLng(currentLocation);
          setLiveLocation(currentLocation);
        }
      });

      setMarkerLatLng(liveLocation);
      setIsMarker(true);
    },
    [liveLocation]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  function onChangeMap() {
    setMarkerLatLng({ lat: map.getCenter().lat(), lng: map.getCenter().lng() });
    setIsMarker(true);
  }

  return (
    <div className={styles.section}>
      <button
        type="button"
        onClick={onLiveLocation}
        className={styles.locationButton}
      >
        <BiCurrentLocation />
      </button>
      {isLoaded ? (
        <>
          <div className={`${styles.modalWrapper} relative`}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={liveLocation}
              // onBoundsChanged={handleChange}
              onDragEnd={onChangeMap}
              zoom={17}
              onLoad={onLoad}
              onDragStart={() => setIsMarker(false)}
              options={{
                draggableCursor: false,
                zoomControl: true,
                // streetView: false,
                // streetViewControl: false,
                mapTypeControl: true,
                mapTypeControlOptions: {
                  style:
                    // eslint-disable-next-line no-undef
                    /*@type **/
                    // eslint-disable-next-line no-undef
                    google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                  // eslint-disable-next-line no-undef
                  position: google.maps.ControlPosition.LEFT_BOTTOM,
                },
                fullscreenControlOptions: {
                  style:
                    // eslint-disable-next-line no-undef
                    /*@type **/
                    // eslint-disable-next-line no-undef
                    google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                  // eslint-disable-next-line no-undef
                  position: google.maps.ControlPosition.RIGHT_BOTTOM,
                },
              }}
              onUnmount={onUnmount}
            >
              <button
                onClick={setOpen}
                className="absolute top-2 right-3 text-[30px]"
              >
                <AiFillCloseCircle className="text-red-600" />
              </button>
              <div className={styles.controlsWrapper}>
                <div className={styles.controlsContent}>
                  <div className={`${styles.inputWrapper}`}>
                    <Autocomplete
                      className={`${styles.suggestions}`}
                      onPlaceChanged={onPlaceChanged}
                      onLoad={onLoadAutoComplete}
                    >
                      <input
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        placeholder="search places"
                        className=""
                        type={`text`}
                      />
                    </Autocomplete>
                  </div>

                  <button
                    type="button"
                    style={{ fontSize: 16, color: "black" }}
                    onClick={() => {
                      handleMarkerAddress();
                      if (!processing) {
                        setOpen();
                      }
                    }}
                    className={`${styles.button} py-2 px-4 bg-blue-500 text-white rounded-md`}
                  >
                    Done
                  </button>
                </div>
              </div>
              <CustomMarker
                isMarker={isMarker}
                position={markerLatLng}
                liveLocation={liveLocation}
              />
            </GoogleMap>
          </div>
        </>
      ) : (
        <div className={styles.spinnerWrapper}>
          <TailSpin
            height="60px"
            width="60px"
            color="blue"
            ariaLabel="loading"
          />
        </div>
      )}
    </div>
  );
};

export default GoogleMapComponent;
