import { METADATA } from "../types";
import produce from "immer";
const state = {
  metaData: null,
};

const MetaDataReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case METADATA.SET_ALL_METADATA:
      return produce(mState, (draftState) => {
        draftState.metaData = action.payload;
      });
    default:
      return { ...mState };
  }
};
export default MetaDataReducer;
