import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import Modal from "../../Components/AbstractsComponents/Modal2/Modal";
import AuctionController from "../../Controllers/auctionController";
// import AuctionManager from "../../modules/Auctions/AuctionManager";

const DeleteAuctionModal = ({ show, setShow, id }) => {
  console.log("SHOWWWWW........", show);

  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleOk = () => {
    setSuccess(false);
    setShow(false);
  };

  const onDeleteAuction = (flag) => {
    setProcessing(true);
    AuctionController.delete(id, flag)
      .then((res) => {
        setProcessing(false);
        setSuccess(true);
        setError("");
      })
      .catch((err) => {
        setError(err);
        setProcessing(false);
      });
  };

  return (
    <Modal
      visible={show}
      closeModal={() => setShow(false)}
      wrapperClass={`max-w-[350px] !px-0 w-full`}
    >
      <div className="w-full p-10 bg-white rounded-md min-h-[150px] ">
        <h3
          className={`mb-4 text-sm font-[800] font-bold capitalize  ${
            error ? "text-red-500" : "text-black"
          }`}
        >
          {success
            ? "deleted successfully"
            : error !== ""
            ? error
            : "Do you want to delete this Auction?"}
        </h3>
        <div className="flex gap-3">
          {success ? (
            <button
              onClick={handleOk}
              className="w-full px-3 py-2 text-center text-white rounded-lg bg-primary "
            >
              Ok
            </button>
          ) : (
            <>
              {!error ? (
                <button
                  className="inline-flex items-center justify-center w-full px-3 py-2 text-center text-white rounded-lg bg-primary "
                  onClick={() => onDeleteAuction(false)}
                >
                  {processing ? (
                    <TailSpin
                      height="20px"
                      width="20px"
                      color="white"
                      ariaLabel="loading"
                    />
                  ) : (
                    "Confirm"
                  )}
                </button>
              ) : (
                <button
                  className="inline-flex items-center capitalize justify-center w-full px-3 py-2 text-center text-white bg-red-500 rounded-lg "
                  onClick={() => onDeleteAuction(true)}
                >
                  {processing ? (
                    <TailSpin
                      height="20px"
                      width="20px"
                      color="white"
                      ariaLabel="loading"
                    />
                  ) : (
                    "Retry"
                  )}
                </button>
              )}
              <button
                className="w-full px-3 py-2 text-center bg-gray-300 rounded-lg text-dark1 "
                onClick={() => {
                  setShow(false);
                  setError("");
                }}
              >
                No
              </button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAuctionModal;
