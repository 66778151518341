import { BUYERS } from "../types";
import produce from "immer";
const state = {
  buyers: [],
};

const BuyersReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case BUYERS.SET_ALL:
      return produce(mState, (draftState) => {
        draftState.buyers = action.payload;
      });
    default:
      return { ...mState };
  }
};
export default BuyersReducer;
