import React from "react";
//    importing images
import Logo from "../../../Assests/Icons/InfoRide-01 1.svg";
//    importing css files
import "./authNavBar.css";
export const AuthNav = () => {
  return (
    <div className="auth-nav-div">
      <img src={Logo} className="auth-nav-bar-img" alt="" />
    </div>
  );
};
